import { DatePicker, Table } from 'antd';
import { topFCColumns } from './topFC.data';
import { TTopFCForTable } from '@/types/request';
import { Dayjs } from 'dayjs';

export type TPropsTopFCTable = {
  fcDataSource: TTopFCForTable[];
  date: Dayjs | null;
  onChangeDate: (value: Dayjs | null, dateString: string | string[]) => void;
};

const TopFCTable = ({ fcDataSource, onChangeDate, date }: TPropsTopFCTable) => {
  return (
    <div className="TopFCTable">
      <div className="TopFCTable_header_root">
        <div>
          <h2>ТОП-10 ФК</h2>
          <p>Рейтинг ФК по выданным кредитам</p>
        </div>
        <DatePicker
          onChange={onChangeDate}
          value={date}
          picker="month"
          format={'MMMM YYYY'}
        />
      </div>

      <div className="table">
        <Table
          size="large"
          dataSource={fcDataSource}
          columns={topFCColumns}
          rowKey={(record) => record?.name || ''}
          pagination={{
            position: ['bottomLeft'],
            pageSize: 5
          }}
        />
      </div>
    </div>
  );
};

export default TopFCTable;
