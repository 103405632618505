import { Navigate, Outlet } from 'react-router-dom';
import { FC } from 'react';

type TProtectedRouteProps = {
  isAllowed?: boolean;
  redirectPath: string;
};

export const ProtectedRoute: FC<TProtectedRouteProps> = ({
  isAllowed,
  redirectPath
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} />;
  }
  return <Outlet />;
};
