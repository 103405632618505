import dayjs from 'dayjs';
import { TModalTSDto, TModalTSValues } from '@/types/financing';

export const modalTsDtoMapper = (modalTs: TModalTSValues): TModalTSDto => {
  return {
    bank: '',
    ext_uuid: '',
    response_id: '',
    request_id: modalTs.requestUid,
    bodyNumber: modalTs.bodyNum,
    carRegistrationIssuer: Number(modalTs.registerCompany),
    colour: modalTs.carColor,
    downpaymentDate: dayjs(modalTs.paymentDate).format('YYYY-MM-DD'),
    engineVolume: Number(modalTs.engineCapacity),
    plateNumber: modalTs.licensePlate,
    ptsCertificateNumber: modalTs.registerNum,
    ptsRegistrationDate: dayjs(modalTs.registerDate).format('YYYY-MM-DD'),
    vehicleIdNumber: modalTs.vin,
    evaluationReportDate: dayjs(new Date()).format('YYYY-MM-DD'),
    evaluationReportNumber: '321'
  };
};
