import React, { useState } from 'react';
import VerifyModalInsurance from './VerifyModalInsuranse';
import { useAppDispatch } from '@/store';
import {
  setVerifyModalIsNewApplication,
  setVerifyModalShowInsuranceType
} from '@/store/app.slice';
import { tokenValidate } from '@/store/auth.slice';
import { useNavigate } from 'react-router-dom';

export default function InsuranceHeader() {
  const [verifyModalInsurance, setVerifyModalInsurance] = useState<string>('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      <VerifyModalInsurance veryfiModalInsurance={verifyModalInsurance} />
      <div className="header">
        <div className="title">
          <h2>КАСКО. Добровольное страхование</h2>
          <p>Список заявок</p>
        </div>
        <div className="pagination">
          {/* <button
          onClick={() => {
           
          }}
        >
        </button> */}

          {/* onClick={() => {
            dispatch(setVerifyModalIsNewApplication(true));
            dispatch(tokenValidate());
            setVerifyModalInsurance('Создание заявки');
            dispatch(
              setVerifyModalShowType({ prevClient: true, smsVerf: false })
            );
          }} */}
          <button
            onClick={() => {
              setVerifyModalInsurance('Создание заявки');
              dispatch(setVerifyModalIsNewApplication(true));
              dispatch(tokenValidate());
              dispatch(
                setVerifyModalShowInsuranceType({
                  prevClientInsurance: true,
                  smsVerfInsurance: false
                })
              );
            }}
          >
            <p>Новая заявка</p>
          </button>
        </div>
      </div>
    </>
  );
}
