import { ColumnsType } from 'antd/es/table';
//@ts-ignore
import dayjs from 'dayjs';
//@ts-ignore
import React, { FC } from 'react';
import CellInner from '../components/App/AutoCred/UI/CellInner';
import { TNewInsuranceResponse } from '../types/insuranceMapper';

const statusStyle = {
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '25px',
  height: '25px',
  borderRadius: '50%'
};

const tooltipTitleStyle = {
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '160%'
};
export const columnsInsurance: ColumnsType<TNewInsuranceResponse> = [
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'clientIin',
    width: 240,
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          {/* <div
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              background: '#F1F2F4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{ fontSize: '10px', fontWeight: '600', color: '#687588' }}
            >
              {record?.clientIin || ''}
            </p>
          </div> */}
          <div style={{ marginLeft: '20px' }}>
            <CellInner
              title={record?.clientIin || ''}
              content={record?.clientPhone || ''}
            />
          </div>
        </div>
      );
    }
  },
  {
    title: 'Марка/модель авто',
    dataIndex: 'model',
    key: 'model',
    width: 200,

    render: (text, record) => {
      return record.model ? (
        <>
          <div>
            <CellInner
              title={`${record?.year}` || ''}
              content={record?.brand || ''}
            />
          </div>
        </>
      ) : (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
      );
    }
  },

  {
    title: 'Создано',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 170,

    render: (text, record) => {
      return (
        <div>
          <CellInner
            title={dayjs(record.createDate).format('DD.MM.YYYY, HH:mm')}
            content={`${record?.user?.first_name || ''} ${
              record?.user?.last_name
            } ${record?.user?.middle_name}`}
          />
        </div>
      );
    }
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    sorter: (a, b) => a.status.code.localeCompare(b.status.code),
    render: (text, record) => {
      let statusColor;
      let statusText;

      switch (record.status.code) {
        case 'waiting_bank_response':
          statusColor = '#ffc01f';
          statusText = 'Полис ожидает оплаты';
          break;
          case 'waiting_of_payment':
          statusColor = '#ffc01f';
          statusText = 'Полис ожидает оплаты';
          break;
        case 'error':
          statusColor = '#ff2e1f';
          statusText = 'Ошибка в процессе';
          break;
        case 'completed':
          statusColor = '#0CAF60';
          statusText = 'Полис выдан';
          break;
        case 'financed':
          statusColor = '#0CAF60';
          statusText = 'Полис выдан';
          break;
        case 'sms_verificating':
          statusColor = '#ff2e1f';
          statusText = 'SMS верификаця';
          break;
        case 'draft':
          statusColor = '#a8a8a8';
          statusText = 'Создан';
          break;
        default:
          statusColor = 'rgb(17 24 39)';
          statusText = 'DRAFT';
      }

      return (
        <div>
          <div
            style={{
              padding: '4px 16px',
              borderRadius: '8px',
              background: statusColor,
              width: '90px'
            }}
          >
            <p
              style={{
                textAlign: 'center',
                fontSize: '10px',
                fontWeight: '700',
                color: 'white'
              }}
            >
              {statusText}
            </p>
          </div>
        </div>
      );
    }
  }
];
