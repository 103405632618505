import { AxiosInstance } from 'axios';
import { TUser, TUserDto } from '@/types/user.type';

export class UsersApi {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllUsers(
    limit: number,
    page: number,
    param?: string
  ): Promise<{ total: number; users: TUser[] }> {
    const res = await this.api.get(
      `admin/users/getAll/?limit=${limit}&page=${page}`
    );
    return res.data;
  }

  public async createAdminUser(data: TUserDto) {
    const res = await this.api.post('admin/users/create', data);
    return res.data;
  }

  public async updateAdminUser(data: TUser) {
    const res = await this.api.patch(`admin/users/${data.uuid}/update`, data);
    return res.data;
  }
}
