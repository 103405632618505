import { TClientServerResponse } from '@/types/clients';
import { TClientStoreFormat } from '@/types/store';
import { clientCalculationsMapper } from '@/services/mappers/clientCalculationsMapper';
import { formatPhoneNumber } from '@/services/formatter';
import { clientsRequestsMapper } from '@/services/mappers/clientsRequestsMapper';
import { getLastRequestDate } from '@/services';

export const clientResponseMapper = (
  serverResponse: TClientServerResponse[]
): TClientStoreFormat[] => {
  return serverResponse.map((response) => {
    const lastRequestCreationDate = getLastRequestDate(response.Requests);
    const clientsCalculationsMapperResults = clientCalculationsMapper(
      response.Client_Calculations
    );
    const formattedData: TClientStoreFormat = {
      id: response?.uuid || '',
      clientIin: response?.client_iin || '',
      clientBirthDate:
        response?.birth_date?.toString() || '',
      clientFirstName: response?.first_name || '',
      clientMiddleName: response?.middle_name || '',
      clientLastName: response?.last_name || '',
      clientPhoneNum: formatPhoneNumber(response?.phone_number || ''),
      clientSex: response?.gender?.name || '',
      clientCreationDate: response?.create_date?.toString() || '',
      clientRequests: clientsRequestsMapper(response.Requests, {
        fullName: `${response?.last_name || ''} ${response?.first_name || ''} ${
          response?.middle_name || ''
        }`,
        iin: response?.client_iin || '',
        phoneNum: response?.phone_number || ''
      }),
      lastRequestCreationDate: lastRequestCreationDate,
      clientCalculations: clientsCalculationsMapperResults
    };
    return formattedData;
  });
};
