import { ColumnsType } from 'antd/es/table';
import { TClientStoreFormat } from '../types/store';
import dayjs from 'dayjs';

export const clientsColumns: ColumnsType<TClientStoreFormat> = [
  {
    title: 'Клиент',
    dataIndex: 'clientFullName',
    key: 'client',
    width: 200,
    sorter: (a, b) => a.clientLastName.localeCompare(b.clientLastName),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              background: '#F1F2F4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{ fontSize: '10px', fontWeight: '600', color: '#687588' }}
            >
              {record?.clientFirstName[0] || ''}
            </p>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <h2
              style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}
            >
              {record?.clientLastName || ''} {record?.clientFirstName || ''}{' '}
              {record?.clientMiddleName || ''}
            </h2>
          </div>
        </div>
      );
    }
  },
  {
    title: 'ИИН',
    dataIndex: 'clientFullName',
    key: 'clientIin',
    width: 130,
    sorter: (a, b) => a.clientIin.localeCompare(b.clientIin),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {record?.clientIin || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Номер телефона',
    dataIndex: 'clientFullName',
    key: 'clientPhoneNum',
    width: 130,
    sorter: (a, b) => a.clientPhoneNum.localeCompare(b.clientPhoneNum),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {record?.clientPhoneNum || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Дата создания клиента',
    dataIndex: 'clientCreationDate',
    key: 'clientCreationDate',
    width: 130,
    sorter: (a, b) => {
      return (
        new Date(a.clientCreationDate).getTime() -
        new Date(b.clientCreationDate).getTime()
      );
    },
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {/*{record?.clientCreationDate}*/}
            {dayjs(record?.clientCreationDate).format('DD.MM.YYYY') || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Дата последней заявки',
    dataIndex: 'lastRequestCreationDate',
    key: 'lastRequestCreationDate',
    width: 130,
    sorter: (a, b) => {
      const aDayjs = dayjs(a.lastRequestCreationDate);
      const bDayjs = dayjs(b.lastRequestCreationDate);
      return bDayjs.isBefore(aDayjs) ? 1 : 0;
    },
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {dayjs(record?.lastRequestCreationDate).format(
              'DD.MM.YYYY, HH:mm'
            ) || ''}
          </p>
        </div>
      );
    }
  }
];
