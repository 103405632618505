import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const ButtonAuth = ({ title, action, onClick, disabled }: any) => {
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: action === 'cancel' ? '#111827' : 'white',
    border: '1px solif #111827',
    backgroundColor: action === 'cancel' ? '#FFFFFF' : '#111827',
    borderRadius: '10px',
    fontWeight: 600,
    fontFamily: 'IBM Plex Sans',
    height: '56px',
    width: '100%',
    lineHeight: '22.4',
    textTransform: 'none',
    padding: '16px 24px',
    fontSize: '14px',
    fontfamily: 'IBM Plex Sans',
    '&:hover': {
      backgroundColor: action === 'cancel' ? '#e5e5e5' : '#282f3c'
    }
  }));
  return (
    <Stack direction="row" spacing={2}>
      <ColorButton variant="contained" onClick={onClick} disabled={disabled}>
        {title}
      </ColorButton>
    </Stack>
  );
};
