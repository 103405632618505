import { TAutoSeletItem, TSelectItem } from '../types/selectsItem';
import { DictTypes } from '../constants';

export const productInfoItems: TSelectItem[] = [
  {
    key: 'dealer',
    label: 'Торговая точка',
    snakeCaseValues: DictTypes.Dealers,
    editable: false,
    type: 'select'
  },
  {
    key: 'autoType',
    label: 'Тип авто',
    snakeCaseValues: DictTypes.AutoTypes,
    editable: false,
    type: 'select'
  },
  {
    key: 'markModelYear',
    label: 'Марка, модель и год выпуска',
    editable: true,
    type: 'select'
  },
  {
    key: 'costCar',
    label: 'Стоимость авто',
    editable: false,
    type: 'input'
  },
  {
    key: 'initFee',
    label: 'Первоначальный взнос',
    editable: false,
    type: 'input'
  },
  {
    key: 'loanTerm',
    label: 'Срок займа',
    snakeCaseValues: DictTypes.LoanTerms,
    editable: false,
    type: 'select',
    option: []
  },
  // {
  //   key: 'autoColor',
  //   label: 'Цвет авто',
  //   snakeCaseValues: DictTypes.Colors,
  //   editable: false,
  //   type: 'select',
  //   option: []
  // },
  {
    key: 'engineType',
    label: 'Выберите двигатель',
    snakeCaseValues: DictTypes.EngineTypes,
    editable: false,
    type: 'select'
  },
  {
    key: 'paymentScheme',
    label: 'Тип графика погашений',
    snakeCaseValues: DictTypes.RepaymentTypes,
    editable: false,
    type: 'select'
  },
  // {
  //   key: 'paymentDate',
  //   label: 'Дата платежа',
  //   snakeCaseValues: DictTypes.PaymentDates,
  //   editable: false,
  //   type: 'input'
  // },
  {
    key: 'proofInCome',
    label: 'С подтверждением дохода',
    editable: true,
    type: 'switch'
  }
  // {
  //   key: 'gracePeriodTerm',
  //   label: 'Срок',
  //   snakeCaseValues: DictTypes.LoanTerms,
  //   editable: false,
  //   type: 'select'
  // }
];

export const autoTypeSelectItems: TAutoSeletItem = {
  markAuto: [
    { value: 'chevrolet', label: 'CHEVROLET' },
    { value: 'kia', label: 'KIA' },
    { value: 'jac', label: 'JAC' },
    { value: 'lada', label: 'LADA' },
    { value: 'jetour', label: 'JETOUR' },
    { value: 'hongqi', label: 'Hongqi' }
  ],
  modelAuto: [
    { value: 'Cobalt', label: 'Cobalt', refersTo: 'CHEVROLET' },
    { value: 'Nexia', label: 'Nexia', refersTo: 'CHEVROLET' },
    { value: 'Tracker', label: 'Tracker', refersTo: 'CHEVROLET' },
    { value: 'Equinox', label: 'Equinox', refersTo: 'CHEVROLET' },
    { value: 'Damas', label: 'Damas', refersTo: 'CHEVROLET' },
    { value: 'Traverse', label: 'Traverse', refersTo: 'CHEVROLET' },
    { value: 'Spark', label: 'Spark', refersTo: 'CHEVROLET' },
    { value: 'Trailblazer', label: 'Trailblazer', refersTo: 'CHEVROLET' },
    { value: 'Tahoe', label: 'Tahoe', refersTo: 'CHEVROLET' },
    { value: 'Damas VAN', label: 'Damas VAN', refersTo: 'CHEVROLET' },
    { value: 'Malibu', label: 'Malibu', refersTo: 'CHEVROLET' },
    { value: 'Labo', label: 'Labo', refersTo: 'CHEVROLET' },
    { value: 'Lacetti', label: 'Lacetti', refersTo: 'CHEVROLET' },
    { value: 'Onix', label: 'Onix', refersTo: 'CHEVROLET' },

    { value: 'Ceed', label: 'Ceed', refersTo: 'KIA' },
    { value: 'Cerato', label: 'Cerato', refersTo: 'KIA' },
    { value: 'K5', label: 'K5', refersTo: 'KIA' },
    { value: 'K9', label: 'K9', refersTo: 'KIA' },
    { value: 'Soul', label: 'Soul', refersTo: 'KIA' },
    { value: 'Sportage', label: 'Sportage', refersTo: 'KIA' },
    { value: 'Sorento', label: 'Sorento', refersTo: 'KIA' },
    { value: 'Rio', label: 'Rio', refersTo: 'KIA' },
    { value: 'Seltos', label: 'Seltos', refersTo: 'KIA' },
    { value: 'Picanto', label: 'Picanto', refersTo: 'KIA' },
    { value: 'Carnival', label: 'Carnival', refersTo: 'KIA' },
    { value: 'Rio X', label: 'Rio X', refersTo: 'KIA' },
    { value: 'Ceed SW', label: 'Ceed SW', refersTo: 'KIA' },
    { value: 'Stinger', label: 'Stinger', refersTo: 'KIA' },
    { value: 'Mohave', label: 'Mohave', refersTo: 'KIA' },
    { value: 'EV6', label: 'EV6', refersTo: 'KIA' },
    { value: 'K8', label: 'K8', refersTo: 'KIA' },
    { value: 'EV9', label: 'EV9', refersTo: 'KIA' },

    { value: 'S3', label: 'S3', refersTo: 'JAC' },
    { value: 'S5', label: 'S5', refersTo: 'JAC' },
    { value: 'J7', label: 'J7', refersTo: 'JAC' },
    { value: 'T6', label: 'T6', refersTo: 'JAC' },
    { value: 'S7', label: 'S7', refersTo: 'JAC' },
    { value: 'S3 pro', label: 'S3 pro', refersTo: 'JAC' },
    { value: 'JS4', label: 'JS4', refersTo: 'JAC' },
    { value: 'S4', label: 'S4', refersTo: 'JAC' },
    { value: 'JS6', label: 'JS6', refersTo: 'JAC' },
    { value: 'iEV7S', label: 'iEV7S', refersTo: 'JAC' },
    { value: 'X4', label: 'X4', refersTo: 'JAC' },
    { value: 'SEI4', label: 'SEI4', refersTo: 'JAC' },
    { value: 'T60', label: 'T60', refersTo: 'JAC' },
    { value: 'S811', label: 'S811', refersTo: 'JAC' },
    { value: 'X6', label: 'X6', refersTo: 'JAC' },
    { value: 'S6', label: 'S6', refersTo: 'JAC' },
    { value: 'SEI6', label: 'SEI6', refersTo: 'JAC' },
    { value: 'N35', label: 'N35', refersTo: 'JAC' },
    { value: 'S5FL', label: 'S5FL', refersTo: 'JAC' },
    { value: '(бизнес)Sunray', label: '(бизнес)Sunray', refersTo: 'JAC' },
    { value: 'T8 Pro', label: 'T8 Pro', refersTo: 'JAC' },

    { value: 'Vesta', label: 'Vesta', refersTo: 'LADA' },
    { value: 'Vesta SW cross', label: 'Vesta SW cross', refersTo: 'LADA' },
    { value: 'Vesta SW', label: 'Vesta SW', refersTo: 'LADA' },
    { value: 'Granta', label: 'Granta', refersTo: 'LADA' },
    { value: 'Granta Liftback', label: 'Granta Liftback', refersTo: 'LADA' },
    { value: 'Niva', label: 'Niva', refersTo: 'LADA' },
    { value: 'Niva Travel', label: 'Niva Travel', refersTo: 'LADA' },
    {
      value: 'Niva Legent 3 door',
      label: 'Niva Legent 3 door',
      refersTo: 'LADA'
    },
    { value: 'Largus', label: 'Largus', refersTo: 'LADA' },
    { value: 'Largus cross', label: 'Largus cross', refersTo: 'LADA' },
    { value: 'XRay', label: 'XRay', refersTo: 'LADA' },
    { value: 'XRay Cross', label: 'XRay Cross', refersTo: 'LADA' },
    { value: 'Vesta SE', label: 'Vesta SE', refersTo: 'LADA' },

    { value: 'X70PLUS', label: 'X70PLUS', refersTo: 'JETOUR' },
    { value: 'X70', label: 'X70', refersTo: 'JETOUR' },
    { value: 'Dashing', label: 'Dashing', refersTo: 'JETOUR' },
    { value: 'X90PLUS', label: 'X90PLUS', refersTo: 'JETOUR' },

    { value: 'H5', label: 'H5', refersTo: 'Hongqi' },
    { value: 'HS5', label: 'HS5', refersTo: 'Hongqi' },
    { value: 'E-HS9', label: 'E-HS9', refersTo: 'Hongqi' },
    { value: 'H9', label: 'H9', refersTo: 'Hongqi' }
  ],
  issueYear: [
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
    { value: '2014', label: '2014' }
  ]
};

export const stocksBondsItems: {
  id: string;
  value: string;
  label: string;
  checked: boolean;
  info: string;
}[] = [
  {
    id: '1',
    value: 'bcc',
    label: 'Субсидии и акции от BCC',
    checked: true,
    info: 'Инструкция к акциям и субсидиями от BCC'
  }
  // { id: "2", value: "stock", label: "Акция 4.4%", checked: false, info: "Инструкция к акции 4.4%" },
  // { id: "3", value: "grays", label: "Грейс период", checked: false, info: "Инструкция к акции Грейс период" },
  // { id: "4", value: "halyk", label: "Акция от Halyk Bank", checked: false, info: "Инструкция к акции от Halyk Bank" },
  // { id: "5", value: "kacd", label: "Акция от Jusan Invest", checked: false,  info: "Инструкция к акции от Jusan Invest" },
  // { id: "6", value: "euras", label: "Акция от Eurasian Bank", checked: false,  info: "Инструкция к акции от Eurasian Bank" },
];
