import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { getProducts } from '@/store/admin/adminProducts.slice';
import Column from 'antd/es/table/Column';
import { TProduct } from '@/types/product.type';
import { CreateOrEditProductModal } from '@/components/AdminPanel/Modals/CreateOrEditProductModal';
import { setShowModalCreateOrEditProduct } from '@/store/admin/admin.slice';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { PlusCircleOutlined } from '@ant-design/icons';

const Products = () => {
  const dispatch = useAppDispatch();
  const { showModalCreateOrEditProduct } = useAppSelector(
    (state) => state.admin
  );
  const { products, total, isLoading } = useAppSelector(
    (state) => state.adminProduct
  );
  const [productForEdit, setProductForEdit] = useState<TProduct | null>(null);
  const [productData, setProductData] = useState<TProduct[]>([]);
  const [searchParam, setSearchParam] = useState<string>('');
  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({
    limit: 10,
    page: 1
  });

  useEffect(() => {
    dispatch(
      getProducts({
        limit: paginationData.limit,
        page: paginationData.page,
        param: searchParam
      })
    );
  }, [paginationData]);

  useEffect(() => {
    setProductData(products);
  }, [products]);

  return (
    <>
      <p>PRODUCTS</p>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          onSearch={(value) => {
            setSearchParam(value.trim());
            setPaginationData((prevState) => ({
              ...prevState,
              page: 1
            }));
            dispatch(
              getProducts({
                param: value,
                limit: paginationData.limit,
                page: paginationData.page
              })
            );
          }}
        />
        {showModalCreateOrEditProduct && (
          <CreateOrEditProductModal
            setShow={() => dispatch(setShowModalCreateOrEditProduct(false))}
            editMode={!!productForEdit}
            productItem={productForEdit}
            paginationData={paginationData}
            searchParam={searchParam}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setProductForEdit(null);
            dispatch(setShowModalCreateOrEditProduct(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowClassName="admin-dealers-table-row"
        dataSource={productData}
        rowKey="uuid"
        size={'small'}
        onRow={(record: TProduct) => ({
          onClick: () => {
            setProductForEdit(record);
            dispatch(setShowModalCreateOrEditProduct(true));
          }
        })}
        loading={isLoading}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        <Column
          key="code"
          title="Код"
          dataIndex="code"
          // sorter={(a: TProduct, b: TProduct) => a.code.localeCompare(b.code)}
        />
        <Column key="name" title="Наименование" dataIndex="name" />
        <Column
          key="product_type"
          title="Вид"
          dataIndex="product_type"
          render={(_, record: TProduct) => {
            return record.product_type && record.product_type.name
              ? record.product_type.name
              : 'No data';
          }}
        />
        <Column
          key="bank_uuid"
          title="Банк"
          dataIndex="bank"
          render={(_, record: TProduct) => {
            return record.bank && record.bank.fullname
              ? record.bank.fullname
              : 'No data';
          }}
        />
        <Column
          width="300px"
          key="model"
          dataIndex="promo_product_models"
          title="Модели"
          render={(_, record: TProduct) => {
            return (
              record.Promo_Products_Models?.map(
                (model) => model.model?.name
              ).join(', ') || <span style={{ color: 'red' }}>Нет данных</span>
            );
          }}
        />

        <Column
          key="is_active"
          title="Услуга активна"
          dataIndex="is_active"
          render={(_, record: TProduct) => {
            return record.is_active ? (
              <span style={{ color: 'green' }}>Активно</span>
            ) : (
              <span style={{ color: 'red' }}>Не активно</span>
            );
          }}
        />
      </Table>
    </>
  );
};

export default Products;
