import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Menu, Table } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateOrEditDealerModal } from '../Modals/CreateOrEditDealerModal/CreateOrEditDealerModal';
import { useAppDispatch, useAppSelector } from '@/store';
import { TDealer } from '@/types/dealer.type';
import { getAllDealers } from '@/store/admin/adminDealers.slice';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { setShowModalCreateOrEditDealer } from '@/store/admin/admin.slice';
import '@/styles/AdminModal.scss';

const DealersPoints = () => {
  const dispatch = useAppDispatch();
  const { isLoading, total, dealers } = useAppSelector(
    (state) => state.adminDealers
  );
  const { showModalCreateOrEditDealer } = useAppSelector(
    (state) => state.admin
  );
  const [dealerForEdit, setDealerForEdit] = useState<TDealer | null>(null);
  const [dealersPointsData, setDealersPointsData] = useState<TDealer[]>([]);
  const [searchParam, setSearchParam] = useState<string>('');
  const [filterParam, setFilterParam] = useState<string>('');
  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({
    limit: 10,
    page: 1
  });

  const fetchDealers = useCallback(() => {
    dispatch(
      getAllDealers({
        limit: paginationData.limit,
        page: paginationData.page,
        param: searchParam,
        filterParam: filterParam
      })
    );
  }, [
    dispatch,
    paginationData.limit,
    paginationData.page,
    searchParam,
    filterParam
  ]);

  useEffect(() => {
    fetchDealers();
  }, [fetchDealers]);

  useEffect(() => {
    if (dealers && dealers.length > 0) {
      setDealersPointsData(dealers);
    }
  }, [dealers]);

  const handleStatusFilterChange = (value: string | null) => {
    setFilterParam(value ? value.toString() : '');
    setPaginationData((prevState) => ({
      ...prevState,
      page: 1
    }));
  };

  const handleTableChange = () => {
    fetchDealers();
  };

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          placeholder="Введите наименование или код дилера"
          onSearch={(value) => {
            setSearchParam(value.trim());
            setPaginationData((prevState) => ({
              ...prevState,
              page: 1
            }));
          }}
        />
        {showModalCreateOrEditDealer && (
          <CreateOrEditDealerModal
            setShow={() => dispatch(setShowModalCreateOrEditDealer(false))}
            searchParam={searchParam}
            paginationData={paginationData}
            editMode={!!dealerForEdit}
            dealerItem={dealerForEdit}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setDealerForEdit(null);
            dispatch(setShowModalCreateOrEditDealer(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowClassName="admin-dealers-table-row"
        dataSource={dealersPointsData}
        rowKey="uuid"
        size={'small'}
        onRow={(record: TDealer) => ({
          onClick: () => {
            setDealerForEdit(record);
            dispatch(setShowModalCreateOrEditDealer(true));
          }
        })}
        loading={isLoading}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
        onChange={handleTableChange}
      >
        <Column
          key="name"
          title="Наименование"
          dataIndex="name"
          sorter={(a: TDealer, b: TDealer) => a.name.localeCompare(b.name)}
          sortDirections={['ascend', 'descend']}
        />
        <Column
          key="sold_brands"
          title="Тип бренда"
          dataIndex="sold_brands"
          render={(_, record: TDealer) => {
            return record.sold_brands ? 'Профильный' : 'Не профильный';
          }}
        />
        <Column
          key="city"
          title="Город"
          dataIndex="city"
          render={(_, record: TDealer) => {
            return record.city?.name;
          }}
          sorter={(a: TDealer, b: TDealer) =>
            a.city!.name.localeCompare(b.city!.name)
          }
          sortDirections={['ascend', 'descend']}
        />
        <Column
          key="is_active"
          title="Статус"
          dataIndex="is_active"
          render={(_, record: TDealer) => {
            return record.is_active ? (
              <span style={{ color: 'green' }}>Активно</span>
            ) : (
              <span style={{ color: 'red' }}>Не активно</span>
            );
          }}
          filterDropdown={() => (
            <div style={{ padding: 8 }}>
              <Menu
                onClick={({ key }) => {
                  handleStatusFilterChange(key === 'all' ? null : key);
                }}
              >
                <Menu.Item key="all">Все</Menu.Item>
                <Menu.Item key="true">Активно</Menu.Item>
                <Menu.Item key="false">Не активно</Menu.Item>
              </Menu>
            </div>
          )}
          // filterIcon={(filtered) => <Button>Фильтр</Button>}
          filterMultiple={false}
        />
        <Column
          key="code"
          title="Код"
          dataIndex="code"
          sorter={(a: TDealer, b: TDealer) => Number(a.code) - Number(b.code)}
        />
      </Table>
    </>
  );
};

export default DealersPoints;
