import React, { useEffect, useState } from 'react';
import { TNewOrder } from '@/types/newOrder';
import { findUniqueValuesOfArray } from '@/services';

export type TAdditionalProductsProps = {
  setOpen: React.Dispatch<React.SetStateAction<string>>;
  products: { switch: boolean; title: string; id: string }[];
  orderApp: TNewOrder | null;
};

export const AdditionalProducts = ({
  setOpen,
  products,
  orderApp
}: TAdditionalProductsProps) => {
  const [selectedUniqueItems, setSelectedUniqueItems] = useState<string[]>([]);

  useEffect(() => {
    const itemResults = products
      .filter(
        (prod) =>
          prod.switch &&
          !prod.title.includes('SAPA') &&
          !prod.title.includes('KMF')
      )
      .map((prod) => {
        if (prod.title.includes('Каско ')) {
          return 'КАСКО';
        } else if (prod.title.includes('Страхование от НС')) {
          return 'Страхование от НС';
        } else if (prod.title.includes('Регистратор от НС')) {
          return 'Регистратор от НС';
        } else {
          return prod.title;
        }
      })
      .filter(findUniqueValuesOfArray);
    setSelectedUniqueItems(itemResults);
  }, [products]);

  return (
    <div className="add_products">
      <div className="title">
        <p>Доп. продукты</p>
        <p
          onClick={() =>
            orderApp?.status === 'draft' || orderApp === null
              ? setOpen('open')
              : null
          }
        >
          Изменить
        </p>
      </div>
      <div className="products">
        {selectedUniqueItems.length &&
          selectedUniqueItems.map((item) => (
            <div key={item} className="product">
              <p>{item}</p>
              <p>Включен</p>
            </div>
          ))}
        {/*{products.map(*/}
        {/*  (item) =>*/}
        {/*    item.switch && (*/}
        {/*      <div key={item.id} className="product">*/}
        {/*        <p>{item.title}</p>*/}
        {/*        <p>Включен</p>*/}
        {/*      </div>*/}
        {/*    )*/}
        {/*)}*/}
      </div>
    </div>
  );
};
