import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TTemplatesStore } from '../types/store';
import { apiConnector } from '../integrations/api.connector';
import { TTemplate, TTemplateDto } from '../types/templates';

const initialState: TTemplatesStore = {
  isLoading: false,
  templates: []
};

export const getTemplates = createAsyncThunk(
  'templates/getTemplates',
  async (): Promise<any> => {
    try {
      return await apiConnector.getTemplates();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const redactTemplate = createAsyncThunk(
  'templates/redactTemplate',
  async (
    data: {
      templateId: string;
      template: TTemplateDto;
    },
    { dispatch }
  ): Promise<any> => {
    try {
      await apiConnector.redactTemplate(data);
      await dispatch(getTemplates());
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplates.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.templates = action.payload;
      })
      .addCase(redactTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(redactTemplate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(redactTemplate.fulfilled, (state) => {
        state.isLoading = false;
      });
  }
});
