import { useAppDispatch } from '@/store';
import { createLeasingDraft } from '@/store/leasings.slice';
import { useNavigate } from 'react-router-dom';

const LeasingHeader = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  return (
    <div className="header">
      <div className="title">
        <h2>Лизинги</h2>
        <p>Созданные лизинги</p>
      </div>
      <div className="pagination">
        <button
          onClick={async () => {
            await dispatch(createLeasingDraft());
            navigate('/newLeasing');
          }}
        >
          <p>Новая Заявка</p>
        </button>
      </div>
    </div>
  );
};

export default LeasingHeader;
