import { DatePicker, Select } from 'antd';
import React from 'react';
import { TRequestsAmountForChart } from '@/types/request';
import { Dayjs } from 'dayjs';
import DoughnutChart from '@/components/Shared/Charts/DoughnutChart';
import { BanksColors } from '@/constants';

export type TFinancedDataProps = {
  selectDealerValue: string;
  dealersOptions: { label: string; value: string }[];
  setSelectedDealer: React.Dispatch<React.SetStateAction<string>>;
  doughnutChartData: {
    labels: string[];
    backgroundColors: string[];
    data: number[];
  };
  overallAmount: number;
  bankFinancedRequests: TRequestsAmountForChart[];
  datesValue: [startDate: Dayjs | null, endDate: Dayjs | null];
  handleRangePickerChange: (value: any) => void;
};

const { RangePicker } = DatePicker;

const FORMAT = 'DD.MM.YYYY';
const FinancedData = ({
  dealersOptions,
  setSelectedDealer,
  selectDealerValue,
  doughnutChartData,
  overallAmount,
  bankFinancedRequests,
  handleRangePickerChange,
  datesValue
}: TFinancedDataProps) => {
  return (
    <div className="TopFCTable">
      <div className="TopFCTable_header_root">
        <div>
          <h2>Профинансировано</h2>
          <p>Мои созданные заявки</p>
        </div>
        <RangePicker
          value={datesValue}
          format={FORMAT}
          onChange={handleRangePickerChange}
          placeholder={['Начало', 'Конец']}
        />
      </div>
      <Select
        showSearch
        optionFilterProp={'label'}
        value={selectDealerValue}
        size="large"
        style={{ width: '100%' }}
        options={dealersOptions}
        onChange={(e) => setSelectedDealer(e)}
      />
      <div className="Chart_container">
        <div
          style={{
            width: '290px',
            height: '290px',
            margin: '24px 0',
            position: 'relative'
          }}
        >
          <DoughnutChart
            data={doughnutChartData.data}
            backgroundColors={doughnutChartData.backgroundColors}
            labels={doughnutChartData.labels}
          />

          <div className="Chart_amount">
            <h3>{overallAmount}</h3>
            <p>Общ. кол-во</p>
          </div>
        </div>
      </div>

      <div>
        {bankFinancedRequests.length > 0 &&
          bankFinancedRequests.map((req) => {
            let circleColor = '';
            switch (req.bank_name) {
              case 'bcc':
                circleColor = BanksColors.BccBank;
                break;
              default:
                break;
            }
            return (
              <div className="FinancedRequests_root">
                <div>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: circleColor
                    }}
                  ></div>
                  <h2>{req.bank_name}</h2>
                </div>
                <p>{req.totalcount}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FinancedData;
