import { AxiosInstance } from 'axios';

export class AuthAdminApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public refreshToken = async () => {
    return await this.api.get('/auth/refresh');
  };
}
