import { TSelectItem } from '../types/selectsItem';
import { DictTypes } from '../constants';

export const modalTSItems: TSelectItem[] = [
  {
    key: 'licensePlate',
    label: 'Государственный номерной знак',
    editable: false,
    type: 'input'
  },
  {
    key: 'vin',
    label: 'Идентификационный номер машины (VIN)',
    editable: false,
    type: 'input'
  },
  {
    key: 'bodyNum',
    label: 'Номер кузова',
    editable: false,
    type: 'input'
  },
  {
    key: 'paymentDate',
    label: 'Дата первоначального взноса',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'registerCompany',
    label: 'Компания регистратор автомобиля',
    editable: false,
    type: 'select',
    snakeCaseValues: DictTypes.VehicleRegistrationCompanies
    // option: [{ value: 'Pristia Candra', label: 'Pristia Candra' }]
  },
  {
    key: 'engineCapacity',
    label: 'Объем двигателя',
    editable: false,
    type: 'input'
  },
  {
    key: 'carColor',
    label: 'Цвет авто',
    editable: false,
    type: 'input'
    // snakeCaseValues: DictTypes.Colors
    // option: [
    //   { value: 'Белый', label: 'Белый' },
    //   { value: 'Черный', label: 'Черный' },
    //   { value: 'Серый', label: 'Серый' },
    //   { value: 'Красный', label: 'Красный' },
    //   { value: 'Синий', label: 'Синий' },
    //   { value: 'Зеленый', label: 'Зеленый' },
    //   { value: 'Желтый', label: 'Желтый' },
    //   { value: 'Коричневый', label: 'Коричневый' }
    // ]
  },
  {
    key: 'registerDate',
    label: 'Дата свидетельства о регистрации ТС',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'registerNum',
    label: 'Номер свидетельства о регистрации ТС',
    editable: false,
    type: 'input'
  }
];
