import { Table } from 'antd';
import { TLitroResponse } from '@/types/litro.type';
import { FC } from 'react';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { formatPhoneNumber } from '@/services/formatter';
import CellInner from '@/components/App/AutoCred/UI/CellInner';
import { StatusCell } from '@/components/App/LitroPnd/StatusCell';
import { useAppSelector } from '@/store';

type TLitroTableProps = {
  litroReq: TLitroResponse[];
  isLoading: boolean;
  getItemById?: (uuid: string) => void;
  pagination: { limit: number; page: number };
  setPagination: (pagination: { limit: number; page: number }) => void;
};

export const LitroTable: FC<TLitroTableProps> = ({
  litroReq,
  isLoading,
  getItemById,
  pagination,
  setPagination
}) => {
  const { total } = useAppSelector((state) => state.litro);
  return (
    <>
      <Table
        rowClassName="admin-banks-table-row"
        rowKey="uuid"
        size="small"
        dataSource={litroReq}
        loading={isLoading}
        onRow={(record: TLitroResponse) => ({
          onClick: () => {
            if (getItemById) {
              getItemById(record.uuid);
            }
          }
        })}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: pagination.limit,
          current: pagination.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPagination({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        <Column
          title="Клиент"
          dataIndex="client"
          render={(_, record: TLitroResponse) => {
            return (
              record.client && (
                <CellInner
                  title={`${record.client.last_name || ''} ${
                    record.client.first_name || ''
                  } ${record.client.middle_name || ''}`}
                  content={formatPhoneNumber(record.client.phone_number)}
                />
              )
            );
          }}
        />
        <Column
          title="Авто"
          dataIndex="brand_uuid"
          render={(_, record: TLitroResponse) => {
            return record.brand_uuid && record.model_uuid && record.car_year ? (
              <CellInner
                title={`${record.brand ? record.brand.name : ''} ${
                  record.model ? record.model.name : ''
                } ${record.car_year ? record.car_year : ''}`}
                content={record.car_registration ? record.car_registration : ''}
              />
            ) : (
              <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
            );
          }}
        />
        <Column
          title="Создано"
          dataIndex="create_date"
          render={(_, record: TLitroResponse) => {
            return (
              record.create_date &&
              record.user && (
                <CellInner
                  title={`${record.user.last_name} ${record.user.first_name} ${record.user.middle_name}`}
                  content={dayjs(record.create_date).format(
                    'YYYY-MM-DD. HH:mm'
                  )}
                />
              )
            );
          }}
        />
        <Column
          title="Cтатус"
          dataIndex="status_code"
          render={(_, record: TLitroResponse) => {
            return (
              record.status_code &&
              (record.status_code === 'sms_verificating' ? (
                <StatusCell className="sms-verificating" text="Валидация ОТП" />
              ) : record.status_code === 'draft' ? (
                <StatusCell className="draft" text="ЧЕРНОВИК" />
              ) : record.status_code === 'waiting_of_financing' ? (
                <StatusCell
                  className="waiting_finance"
                  text="Ожидается оплата"
                />
              ) : record.status_code === 'financed' ? (
                <StatusCell className="financed" text="Оплачено" />
              ) : record.status_code === 'error' ? (
                <StatusCell className="error" text="Ошибка" />
              ) : (
                record.status?.code === 'Draft'
              ))
            );
          }}
        />
      </Table>
    </>
  );
};
