import { Input, Modal } from 'antd';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { TSimpleDict } from '@/types/dicts';
import { useAppDispatch } from '@/store';
import {
  createNewSimpleDictItem,
  updateDict
} from '@/store/admin/adminDict.slice';
import { useParams } from 'react-router-dom';
import { DictTypes } from '@/constants';

type TAddSimpleDictModalProps = {
  setShow: () => void;
  editMode?: boolean;
  dictItem: TSimpleDict | null;
};

const CreateOrEditSimpleDictModal: FC<TAddSimpleDictModalProps> = ({
  setShow,
  editMode = false,
  dictItem
}) => {
  const dispatch = useAppDispatch();
  const param = useParams();
  const [inputsValues, setInputsValues] = useState<TSimpleDict>({
    uuid: '',
    name: '',
    code: ''
  });

  useEffect(() => {
    if (editMode && dictItem) {
      setInputsValues({
        uuid: dictItem.uuid,
        name: dictItem.name,
        code: dictItem.code
      });
    }
  }, []);

  const onOk = async () => {
    if (!isNaN(Number(inputsValues.code))) {
      setInputsValues((prevState) => ({
        ...prevState,
        code: Number(inputsValues.code)
      }));
    }
    if (editMode) {
      dispatch(
        updateDict({
          data: {
            uuid: inputsValues.uuid,
            name: inputsValues.name,
            code: !isNaN(Number(inputsValues.code))
              ? Number(inputsValues.code)
              : inputsValues.code
          },
          dictType: param.dictType as string
        })
      );
      return setShow();
    }
    dispatch(
      createNewSimpleDictItem({
        dictItemDto: {
          name: inputsValues.name as string, // Добавляем свойство name
          code: !isNaN(Number(inputsValues.code))
            ? Number(inputsValues.code)
            : inputsValues.code // Добавляем свойство code
        },
        dictType: param.dictType as DictTypes
      })
    );
    setShow();
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputsValues((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return (
    <Modal
      title="Добавить новый элемент"
      open={true}
      onOk={onOk}
      onCancel={() => setShow()}
    >
      <div>
        <Input
          placeholder="Название"
          value={inputsValues.name}
          onChange={onInputChange}
          style={{ marginBottom: '10px' }}
          name="name"
        />
        <Input
          placeholder="Код"
          value={inputsValues.code}
          name="code"
          type="text"
          onChange={onInputChange}
        />
      </div>
    </Modal>
  );
};

export default CreateOrEditSimpleDictModal;
