export type TAddProductsLoanPeriodOptions = {
  value: string;
  label: string;
};

export const addProductsLoanPeriodOptions: TAddProductsLoanPeriodOptions[] = [
  {
    value: '12',
    label: '12 месяцев'
  },
  {
    value: '24',
    label: '24 месяца'
  },
  {
    value: '36',
    label: '36 месяцев'
  },
  {
    value: '48',
    label: '48 месяцев'
  },
  {
    value: '60',
    label: '60 месяцев'
  },
  {
    value: '72',
    label: '72 месяца'
  },
  {
    value: '84',
    label: '84 месяца'
  }
];
