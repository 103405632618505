import { AxiosInstance } from 'axios';
import { TClientInfoOtpDto, TValidateOtp } from '@/types/otp.type';
import { TCreateLitroDto, TLitroResponse } from '@/types/litro.type';

export class LitroApi {
  constructor(private readonly api: AxiosInstance) {}

  public async create(
    data: TClientInfoOtpDto
  ): Promise<{ request_uuid: string }> {
    const res = await this.api.post(`litro/create`, data);
    return res.data;
  }

  public async validateOtp(data: TValidateOtp) {
    const res = await this.api.post(`litro/validateOTP`, data);
    return res.data;
  }
  public async getLitroById(uuid: string) {
    const res = await this.api.get(`litro/getLitroById/${uuid}`);
    return res.data;
  }

  public async updateLitro(data: TCreateLitroDto) {
    const res = await this.api.patch(`litro/update/${data.uuid}`, data);
    return res.data;
  }

  public async getLitro(
    limit?: number,
    page?: number,
    param?: string,
    userRequests?: string
    // query?: string
  ): Promise<{
    data: TLitroResponse[];
    total: number;
  }> {
    let path = `litro/getLitroRequests/${param ? param : ''}?`;

    if (limit) path = path + `&limit=${limit}`;
    if (page) path = path + `&page=${page}`;
    if (userRequests) path = path + `&user_requests=${userRequests}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async getPaymentType() {
    const res = await this.api.get('dictionaries/getPaymentTypes/litro');
    return res.data;
  }
}
