import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFinancingStepStore } from '../types/store';
import { TBankOffers, TBankOffersAlternative } from '../types/offers';
import { apiConnector } from '../integrations/api.connector';

const initialState: TFinancingStepStore = {
  selectedBank: null,
  attachedFiles: [],
  uploadedFiles: {},
  isFilesUpdated: true
};

export const getAttachedFilesByReqId = createAsyncThunk(
  'financingStep/getAttachedFilesByReqId',
  async (reqId: string): Promise<any> => {
    try {
      return await apiConnector.getAttachedFilesByReqId(reqId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);
export const financingStepSlice = createSlice({
  name: 'financingStep',
  initialState,
  reducers: {
    onSetSelectedBank: (
      state,
      action: PayloadAction<TBankOffersAlternative | TBankOffers>
    ) => {
      state.selectedBank = action.payload;
    },
    onSetFinancingStoreToInitialState: () => {
      return initialState;
    },
    onSetUploadedFiles: (state, action: PayloadAction<any>) => {
      state.uploadedFiles = action.payload;
    },
    onSetSelectedBankToInitial: (state) => {
      state.selectedBank = null;
    },
    setIsFilesUpdated: (state, action: PayloadAction<boolean>) => {
      state.isFilesUpdated = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getAttachedFilesByReqId.fulfilled, (state, action) => {
      state.attachedFiles = action.payload;
    });
  }
});

export const {
  onSetSelectedBank,
  onSetFinancingStoreToInitialState,
  onSetUploadedFiles,
  onSetSelectedBankToInitial,
  setIsFilesUpdated
} = financingStepSlice.actions;
