import { TSelectItem } from '../types/selectsItem';
import { DictTypes } from '../constants';

export const calculatorDataItem: TSelectItem[] = [
  {
    key: 'dealer',
    label: 'Торговая точка',
    snakeCaseValues: DictTypes.Dealers,
    editable: false,
    type: 'select'
  },
  {
    key: 'autoType',
    label: 'Тип авто',
    snakeCaseValues: DictTypes.AutoTypes,
    editable: false,
    type: 'select'
  },
  {
    key: 'markModelYear',
    label: 'Марка, модель и год выпуска',
    editable: true,
    type: 'select'
  },
  { key: 'costCar', label: 'Стоймость авто', editable: false, type: 'input' },
  {
    key: 'initFee',
    label: 'Первоначальный взнос',
    editable: false,
    type: 'input'
  },
  {
    key: 'loanTerm',
    label: 'Срок займа',
    snakeCaseValues: DictTypes.LoanTerms,
    editable: false,
    type: 'select',
    option: []
  },
  {
    key: 'proofInCome',
    label: 'С подтверждением дохода',
    editable: true,
    type: 'switch'
  }
];
