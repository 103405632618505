import { Button, Collapse } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { infoIcon, printerLogo, REGISTRATION_DUTY_COST } from '@/constants';
import { validateNumberInputValue } from '@/services';

export type TCalculationDifferenceResultItemProps = {
  calculationData: {
    rate: number;
    kaskoPerYear: number;
    loanAmount: number;
    totalLoanAmount: number;
    monthlyPaymentAnnuity: number;
    debtBalanceAnnuity: number;
    loanOverpaymentAnnuity: number;
    monthlyPaymentDiffArray: { month: number; amount: number }[];
    loanOverpaymentDiff: number;
  } | null;
};

const { Panel } = Collapse;

const CalculationDifferenceResultItem = ({
  calculationData
}: TCalculationDifferenceResultItemProps) => {
  return (
    <Collapse
      defaultActiveKey={1}
      expandIconPosition="right"
      expandIcon={({ isActive }) =>
        isActive ? (
          <MinusOutlined
            style={{
              width: '20px',
              height: '20px'
            }}
          />
        ) : (
          <PlusOutlined
            style={{
              width: '20px',
              height: '20px'
            }}
          />
        )
      }
    >
      <Panel
        key={1}
        header={
          <div className="Collapse_header">
            <div className="image_wrapper">
              <img src={infoIcon} alt="bank_logo" />
            </div>
            <p>Средний расчет кредита</p>
          </div>
        }
      >
        <div className="main_info">
          <div>
            <h2>Первый платеж</h2>
            <p>
              {validateNumberInputValue(
                calculationData?.monthlyPaymentDiffArray[0]?.amount?.toString() ||
                  '',
                10,
                true
              ) || ''}{' '}
              ₸
            </p>
          </div>
          <div>
            <h2>Ставка</h2>
            <p>
              <span>{calculationData?.rate || ''}%</span>
            </p>
          </div>
        </div>
        <div className="other_info_container">
          <div>
            <p>Стоимость КАСКО/год</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                calculationData?.kaskoPerYear?.toString() || '',
                10,
                true
              ) || ''}{' '}
              ₸
            </h2>
          </div>
          <div>
            <p>Сумма займа</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                calculationData?.loanAmount?.toString() || '',
                10,
                true
              ) || ''}{' '}
              ₸
            </h2>
          </div>
          <div>
            <p>Стоимость регистрации ТС</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                REGISTRATION_DUTY_COST.toString(),
                10,
                true
              )}{' '}
              ₸
            </h2>
          </div>
          <div>
            <p>Переплата за дифференцированный платеж</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                (
                  (calculationData?.loanOverpaymentDiff
                    ? calculationData?.loanOverpaymentDiff
                    : 0) +
                  (calculationData?.kaskoPerYear
                    ? calculationData?.kaskoPerYear
                    : 0)
                )?.toString() || '',
                10,
                true
              )}{' '}
              ₸
            </h2>
          </div>
        </div>
        <Button>
          <img src={printerLogo} alt="print" />
          Распечатать
        </Button>
      </Panel>
    </Collapse>
  );
};

export default CalculationDifferenceResultItem;
