import React, { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ConfigProvider, Form, Input, theme, ThemeConfig } from 'antd';
import { CustomModal } from '../Shared/Modal/CustomModal';
import '@/styles/ResetPassword.scss';
import { pathLogo } from '@/constants';
import { apiConnector } from '@/integrations/api.connector';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const customTheme: ThemeConfig | undefined = {
  algorithm: theme.darkAlgorithm,
  components: { Button: { algorithm: true, colorPrimary: '#8ecccc' } }
};

export const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState('password');
  const [confpassword, setConfpassword] = useState('confpassword');
  const [resetPasModal, setResetPasModal] = useState<boolean>(false);
  const [validateLogin, setValidateLogin] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    if (password !== confpassword) {
      setValidateLogin(true);
    } else {
      setResetPasModal(true);
    }
  };

  const redir = async () =>
    await apiConnector
      .resetPassword(password, token || '')
      .then(() => navigate('/login'));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    setValidateLogin(false);
  };

  const handleConfChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfpassword(e.currentTarget.value);
    setValidateLogin(false);
  };

  return (
    <>
      <CustomModal
        isModalOpen={resetPasModal}
        handleOk={() => setResetPasModal(false)}
        handleCancel={() => setResetPasModal(false)}
        title={'Сброс пароля'}
        editClass={'resetPasModal'}
        footer={[
          <Button
            style={{ backgroundColor: '#111827' }}
            key="submit"
            type="primary"
            onClick={redir}
          >
            Ок
          </Button>
        ]}
      >
        <div>
          <h2>
            Пароль успешно изменен и может быть использован для входа в систему
            «Брокер».
          </h2>
        </div>
      </CustomModal>
      <div className="resetPassword">
        <div className="bg_image">
          <img src={pathLogo} alt="logo" />
        </div>
        <ConfigProvider theme={customTheme}>
          <Form name="basic" autoComplete="off" className="ressetForm">
            <h2>Сброс пароля</h2>
            <Form.Item<FieldType>
              name="password"
              rules={[
                { required: true, message: 'Пожалуйста введите пароль!' }
              ]}
            >
              <Input.Password
                onChange={handleChange}
                value={password}
                placeholder="Введите пароль"
              />
            </Form.Item>

            <Form.Item<FieldType>
              name="remember"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста повторно введите пароль!'
                }
              ]}
            >
              <Input.Password
                onChange={handleConfChange}
                value={confpassword}
                placeholder="Повторно введите пароль"
              />
            </Form.Item>
            {validateLogin ? <p>Введенные пароли не совпадают</p> : ''}
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={onFinish}>
                Сбросить
              </Button>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </>
  );
};
