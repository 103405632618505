import React, { useState } from 'react';
import { notification, Steps } from 'antd';
import { OrderStep } from './OrderStep/OrderStep';
import { OfferStep } from './OfferStep/OfferStep';
import { FinancingStep } from './FinancingStep/FinancingStep';
import '@/styles/NewOrder.scss';
import { useAppDispatch, useAppSelector } from '@/store';
import { TClientDataState, TClientDataStateBool } from '@/types/state';
import { formatPhoneNumber } from '@/services/formatter';
import { setCurrent } from '@/store/newOrder.slice';
import { validateNumberInputValue } from '@/services';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomInput } from '@/components/Shared/Input/CustomInput';

const items = [
  {
    key: 'order',
    title: 'Заявка'
  },
  {
    key: 'offer',
    title: 'Предложения'
  },
  {
    key: 'financing',
    title: 'Финансирование'
  }
];

export const NewOrder = () => {
  const { clientInfo, current, loan, orderApp } = useAppSelector(
    (state) => state.newOrder
  );
  const { selectedBank } = useAppSelector((state) => state.financingStep);
  const dispatch = useAppDispatch();

  const [editModal, showEditModal] = useState<boolean>(false);
  const [reportModal, showReportModal] = useState<boolean>(false);
  const [clientData, setClientData] = useState<TClientDataState>({
    firstName: clientInfo?.fullName[1] || '',
    middleName: clientInfo?.fullName[2] || '',
    lastName: clientInfo?.fullName[0] || '',
    iin: clientInfo?.iin || '',
    telNum: formatPhoneNumber(clientInfo?.telNum || ''),
    id: clientInfo?.id || '',
    errorTitle: '',
    comments: ''
  });

  const [isFocused, setIsFocused] = useState<TClientDataStateBool>({
    firstName: true,
    middleName: true,
    lastName: true,
    iin: true,
    id: false,
    errorTitle: false,
    comments: false
  });

  const handleNextStep = () => {
    dispatch(setCurrent(current + 1));
  };

  const handleEditModal = () => {
    showEditModal(false);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    if (label === 'iin') val = validateNumberInputValue(val, 12);
    setIsFocused((prevState) => ({
      ...prevState,
      [label]: val !== ''
    }));
    setClientData((prevState) => ({ ...prevState, [label]: val }));
  };

  const openEditModal = () => {
    if (orderApp === null || orderApp?.status === 'draft') {
      if (current !== 0) return;
      setIsFocused((prevState) => ({
        ...prevState,
        firstName: true,
        middleName: true,
        lastName: true
      }));
      showEditModal(true);
    }
  };

  const openReportModal = () => {
    setIsFocused((prevState) => ({ ...prevState, id: true }));
    showReportModal(true);
  };

  const handleStepCurrentChange = (val: number) => {
    if (!selectedBank && orderApp && orderApp?.status && val === 2) {
      notification.warning({
        message: 'выберите предложение банка',
        description:
          'Чтобы перейти на данный этап, вам необходимо выбрать предложение банка',
        duration: 5
      });
    } else if (orderApp && orderApp?.status !== 'draft') {
      dispatch(setCurrent(val));
    } else if (orderApp?.status === 'draft' && val === 0) {
      dispatch(setCurrent(val));
    }
  };

  return (
    <div className="NewOrder">
      <CustomModal
        isModalOpen={editModal}
        handleOk={handleEditModal}
        handleCancel={() => showEditModal(false)}
        title={'Введите ФИО'}
        editClass={'editFullName'}
      >
        <>
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.lastName}
            value={clientData.lastName}
            label={'lastName'}
            type={''}
            labelTitle={'Фамилия'}
          />

          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.firstName}
            value={clientData.firstName}
            label={'firstName'}
            type={''}
            labelTitle={'Имя'}
          />
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.middleName}
            value={clientData.middleName}
            label={'middleName'}
            type={''}
            labelTitle={'Отчество'}
          />
        </>
      </CustomModal>
      <CustomModal
        isModalOpen={reportModal}
        handleOk={() => showReportModal(false)}
        handleCancel={() => showReportModal(false)}
        title={'Сообщить о проблеме'}
        editClass={'reportError'}
      >
        <>
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.id}
            value={clientData.id}
            label={'id'}
            type={''}
            labelTitle={'ID заявки'}
          />
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.errorTitle}
            value={clientData.errorTitle}
            label={'errorTitle'}
            type={''}
            labelTitle={'Тема'}
          />
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.comments}
            value={clientData.comments}
            label={'comments'}
            type={''}
            labelTitle={'Добавьте комментарии'}
          />
        </>
      </CustomModal>
      <div className="wrapper">
        <Steps
          className="order_steps"
          current={current}
          items={items}
          onChange={(val) => handleStepCurrentChange(val)}
        />
      </div>
      <div className="container">
        <div
          className="client_info"
          style={{
            maxWidth: `${current === 0 ? '1120px' : ''}`
          }}
        >
          <div>
            <h2
              className={`${current === 0 ? 'editable' : ''} `}
              onClick={openEditModal}
            >
              {`${clientData.lastName} ${clientData.firstName} ${clientData.middleName}`}
            </h2>
            <p>ИИН: {clientData.iin}</p>
          </div>
          <div>
            <h2>{clientData.telNum}</h2>
            <p>Номер телефона</p>
          </div>
          {current !== 0 && (
            <div>
              <h2>{loan.auto}</h2>
              <p>{validateNumberInputValue(loan.amount, 9, true)} ₸</p>
            </div>
          )}
          <div>
            <h2>ID #{clientData.id}</h2>
            <p onClick={openReportModal}>Сообщить о проблеме</p>
          </div>
        </div>
        <div className="content">
          {current === 0 && (
            <OrderStep createRequest={handleNextStep} clientInfo={clientData} />
          )}
          {current === 1 && <OfferStep handleAcceptOffer={handleNextStep} />}
          {current === 2 && <FinancingStep />}
        </div>
      </div>
    </div>
  );
};
