import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Switch } from 'antd';
import dayjs from 'dayjs';
import { TFocusState, TStockBondsState } from '@/types/state';
import { TProductInfo } from '@/types/productInfo';
import { TDict } from '@/types/store';
import { useAppSelector } from '@/store';
import { isFocusData } from '@/data/orderStep.data';
import { useMarkModel } from '@/hooks/useMarkModel';
import {
  findUniqueValuesOfArray,
  setDictionaryOptions,
  validateNumberInputValue
} from '@/services';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { productInfoItems } from '@/data/productInfo.data';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import {
  DictTypes,
  greyInfoIcon,
  infoIcon,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathHalykLogo
} from '@/constants';
import { CustomSwitch } from '@/components/Shared/Switch/CustomSwitch';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { checkObjectValues } from '@/services/validators/checkObjectValues';
import { useDispatch } from 'react-redux';
import { onResetProductInfo } from '@/store/orderStep.slice';

const defaultInfo = {
  label: 'Доступные субсидии/акции банков',
  info: 'KIA (модели: picanto, ceed, ceed sw, cerato, k5) KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)KIA (модели: picanto, ceed, ceed sw, cerato, k5)'
};

export type TFirstStepProps = {
  productInfo: TProductInfo;
  setProductInfo: React.Dispatch<React.SetStateAction<TProductInfo>>;
  dicts: TDict[] | null;
  stocksBonds: TStockBondsState;
  fieldsReadOnly: boolean;
  handleStockBoundChange: (id: string) => void;
  selectedDealerPoint: string;
};

export const FirstStep = ({
  productInfo,
  setProductInfo,
  dicts,
  stocksBonds,
  fieldsReadOnly,
  handleStockBoundChange,
  selectedDealerPoint
}: TFirstStepProps) => {
  const { orderApp } = useAppSelector((state) => state.newOrder);
  const { dealer } = useAppSelector((state) => state.userInfo);
  // const [isGraceAppeared, setIsGraceAppeared] = useState<boolean>(false);
  const [brandModelYearValueHandler, setBrandModelYearValueHandler] =
    useState<string>('');
  const [saveMarkModelYearDisabled, setSaveMarkModelYearDisabled] =
    useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<TFocusState>(isFocusData);
  const {
    possibleModels,
    handleModalSelectChange,
    modalSelectState,
    possibleMarks
  } = useMarkModel(productInfo);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInstructionModal, setShowInstructionModal] =
    useState<boolean>(false);
  const [infoModalData, setInfoModalData] = useState<{
    label: string;
    info: string;
  }>({ label: '', info: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onResetProductInfo());
  }, []);

  useEffect(() => {
    const isDisabled = !checkObjectValues(modalSelectState);
    setSaveMarkModelYearDisabled(isDisabled);
  }, [
    modalSelectState.markAuto,
    modalSelectState.modelAuto,
    modalSelectState.issueYear
  ]);

  const toggleInstructionModal = (dataObject: {
    label: string;
    info: string;
  }) => {
    setInfoModalData(dataObject);
    setShowInstructionModal(!showInstructionModal);
  };

  const onChangeSelectHandler = (val: string, label: string) => {
    // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
    setProductInfo((prevState) => ({ ...prevState, [label]: val.toString() }));
  };

  const modalSaveHandler = () => {
    // setIsFocused(prevState => ({...prevState, ["markModelYear"]: true}));
    setProductInfo({
      ...productInfo,
      markModelYear: {
        brand: modalSelectState.markAuto,
        model: modalSelectState.modelAuto,
        year: modalSelectState.issueYear
      }
    });
    setShowModal(false);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    val = validateNumberInputValue(val, 8, true);
    setProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const onChangeCheckedHandler = (
    val: boolean,
    label: string,
    type: string
  ) => {
    setProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const onChangeDatePickerHandler = (val: any, label: string) => {
    // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
    setProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  useEffect(() => {
    if (!selectedDealerPoint) {
      setProductInfo({
        ...productInfo,
        markModelYear: {
          brand: null,
          model: null,
          year: null
        }
      });
    } else if (orderApp?.status !== 'draft') {
      const { brand, model, year } = productInfo.markModelYear;
      const brandName = dicts
        ?.find((dict) => dict.dictType === 'Brands')
        ?.content.find((item) => item.code === parseInt(brand as string))?.name;
      const modelName = dicts
        ?.find((dict) => dict.dictType === 'Models')
        ?.content.find((item) => item.code === parseInt(model as string))?.name;
      const brandValue = brandName ? brandName : '-';
      const modelValue = modelName ? modelName : '-';
      const yearValue = year ? year : '-';
      const val = `${brandValue}, ${modelValue}, ${yearValue}`;
      setBrandModelYearValueHandler(val);
    } else {
      const { markAuto, modelAuto, issueYear } = modalSelectState;
      const brandName = dicts
        ?.find((dict) => dict.dictType === 'Brands')
        ?.content.find(
          (item) => item.code === parseInt(markAuto as string)
        )?.name;
      const modelName = dicts
        ?.find((dict) => dict.dictType === 'Models')
        ?.content.find(
          (item) => item.code === parseInt(modelAuto as string)
        )?.name;
      const brandValue = brandName ? brandName : '-';
      const modelValue = modelName ? modelName : '-';
      const yearValue = issueYear ? issueYear : '-';
      const val = `${brandValue}, ${modelValue}, ${yearValue}`;
      setBrandModelYearValueHandler(val);
    }
  }, [productInfo]);

  return (
    <div className="first_step">
      <CustomModal
        maskClosable={false}
        editClass="markModelYear"
        title="Марка, модель и год выпуска"
        isModalOpen={showModal}
        handleOk={modalSaveHandler}
        handleCancel={() => setShowModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowModal(false)}>
            Отмена
          </Button>,
          <Button
            disabled={saveMarkModelYearDisabled}
            key="submit"
            type="primary"
            onClick={modalSaveHandler}
          >
            Сохранить
          </Button>
        ]}
      >
        <div className="auto_selects">
          <Select
            showSearch
            optionFilterProp={'label'}
            value={modalSelectState.markAuto}
            placeholder="Марка авто"
            options={possibleMarks}
            onChange={(val) => handleModalSelectChange(val, 'markAuto')}
            disabled={fieldsReadOnly}
          />
          <Select
            showSearch
            optionFilterProp={'label'}
            value={modalSelectState.modelAuto}
            placeholder="Модель авто"
            options={possibleModels}
            onChange={(val) => handleModalSelectChange(val, 'modelAuto')}
            disabled={fieldsReadOnly}
          />
          <Input
            value={modalSelectState.issueYear || new Date().getFullYear()}
            placeholder="Год выпуска"
            onChange={(e) =>
              handleModalSelectChange(e.target.value, 'issueYear')
            }
            disabled={fieldsReadOnly}
          />
        </div>
      </CustomModal>

      <CustomModal
        isModalOpen={showInstructionModal}
        editClass="instruction_modal"
        handleOk={() => setShowInstructionModal(false)}
        handleCancel={() => setShowInstructionModal(false)}
        title={infoModalData.label}
        footer={false}
      >
        <div className="instruction_modal_content">
          {infoModalData.label === defaultInfo.label ? (
            <div key={defaultInfo.label}>
              <h2>Руководство</h2>
              <p>{infoModalData.info}</p>
            </div>
          ) : (
            <>
              <h2>{infoModalData.info}</h2>
            </>
          )}
        </div>
      </CustomModal>

      <h2>Информация о продукте</h2>

      <div className="selects_box">
        {productInfoItems.map((item, index) => {
          if (item.type === 'input') {
            return (
              <CustomInput
                key={item.key + index}
                value={productInfo[item.key] as string}
                label={item.key}
                type={'firstStep'}
                labelTitle={item.label}
                onChangeInputHandler={onChangeInputHandler}
                fieldsReadOnly={fieldsReadOnly}
              />
            );
          } else if (item.type === 'select') {
            return (
              <CustomSelect
                key={item.key + index}
                isFocused={isFocused[item.key]}
                value={
                  item.editable
                    ? brandModelYearValueHandler
                    : (productInfo[item.key] as string)
                }
                option={setDictionaryOptions(
                  item.snakeCaseValues as DictTypes,
                  dicts
                )?.filter((option) => {
                  if (!dealer?.has_commercial && item.key === 'autoType') {
                    return option.label !== 'Коммерческое';
                  } else {
                    return option;
                  }
                })}
                label={item.key}
                type={'firstStep'}
                labelTitle={item.label}
                onChangeSelectHandler={onChangeSelectHandler}
                setShowModal={
                  item.editable
                    ? !fieldsReadOnly
                      ? setShowModal
                      : undefined
                    : undefined
                }
                fieldsReadOnly={item.key === 'dealer' ? true : fieldsReadOnly}
                keyType="autocred"
              />
            );
          } else if (item.type === 'switch') {
            return (
              <CustomSwitch
                key={item.key + index}
                value={productInfo[item.key] as string}
                label={item.key}
                type={'firstStep'}
                labelTitle={item.label}
                onchangeCheckedHandler={onChangeCheckedHandler}
                fieldsReadOnly={false}
              />
            );
          } else if (item.type === 'datepicker') {
            return (
              <CustomDatePicker
                key={item.key + index}
                isFocused={isFocused[item.key]}
                value={
                  productInfo[item.key]
                    ? dayjs(productInfo[item.key] as Date)
                    : null
                }
                label={item.key}
                type={'firstStep'}
                labelTitle={item.label}
                onChangeDatePickerHandler={onChangeDatePickerHandler}
                fieldsReadOnly={fieldsReadOnly}
                keyType="autocred"
              />
            );
          }
        })}
      </div>

      <div className="stocks_bonds">
        <div className="stocks_bonds_root">
          <div>
            <h2>Доступные субсидии/акции банков</h2>
            <img
              onClick={() => {
                toggleInstructionModal(defaultInfo);
              }}
              className="info_icon"
              src={infoIcon}
              alt="info"
            />
          </div>

          <div className="stocks_bonds_banks">
            {stocksBonds
              .map((stock) => stock.bank_name)
              .filter(findUniqueValuesOfArray)
              .map((unique) => {
                let pathToLogo;
                switch (unique) {
                  case 'bcc':
                    pathToLogo = pathBckLogo;
                    break;
                  case 'bereke':
                    pathToLogo = pathBerekeLogo;
                    break;
                  case 'halyk':
                    pathToLogo = pathHalykLogo;
                    break;
                  case 'eu_bank':
                    pathToLogo = pathEurasLogo;
                    break;
                }
                return (
                  <div key={unique}>
                    <img src={pathToLogo} alt={unique} />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="switches">
          {stocksBonds?.map((item) => (
            <div className="switch_block" key={item.id}>
              <div className="switch_block_checkbox">
                <Switch
                  disabled={fieldsReadOnly}
                  checked={item.checked}
                  onChange={() => handleStockBoundChange(item.id)}
                />
                <p>{item.label}</p>
              </div>
              <img
                src={greyInfoIcon}
                alt="logo"
                onClick={() => {
                  toggleInstructionModal({
                    label: item.label,
                    info: item.info
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
