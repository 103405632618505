import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TCreateDictDto, TDict, TSimpleDict } from '@/types/dicts';
import { DictTypes } from '@/constants';
import { apiConnector } from '@/integrations/api.connector';
import { getOptimizedDictionaries } from '@/store/dict.slice';

type TDictsSlice = {
  isDictsLoading: boolean;
  isUpdated: boolean;
  dicts: TDict[];
};

const initialState: TDictsSlice = {
  dicts: [],
  isDictsLoading: false,
  isUpdated: false
};

export const createNewSimpleDictItem = createAsyncThunk(
  'admin/dict/createNewSimpleDictItem',
  async (
    data: { dictType: DictTypes; dictItemDto: TCreateDictDto },
    { dispatch }
  ) => {
    try {
      const { dictType, dictItemDto } = data;
      await apiConnector.createNewSimpleDictItem(
        dictType,
        dictItemDto as TSimpleDict
      );
      dispatch(getOptimizedDictionaries());
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const updateDict = createAsyncThunk(
  'admin/dict/updateDict',
  async (
    { data, dictType }: { data: TSimpleDict; dictType: string },
    { dispatch }
  ) => {
    await apiConnector.updateDict(dictType, data);
    dispatch(getOptimizedDictionaries());
  }
);
export const dictsSlice = createSlice({
  name: 'adminDict',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewSimpleDictItem.fulfilled, (state) => {
      state.isUpdated = true;
    });
    builder.addCase(updateDict.fulfilled, (state) => {
      state.isUpdated = true;
    });
  }
});

export const {} = dictsSlice.actions;
