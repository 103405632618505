import '@/styles/CalculatorForm.scss';
import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { TCalculatorProductInfo } from '@/types/productInfo';
import { useAppSelector } from '@/store';
import { useMarkModel } from '@/hooks/useMarkModel';
import { setDictionaryOptions, validateNumberInputValue } from '@/services';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { calculatorDataItem } from '@/data/calculator.data';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { DictTypes } from '@/constants';
import { CustomSwitch } from '@/components/Shared/Switch/CustomSwitch';
import { checkObjectValues } from '@/services/validators/checkObjectValues';

export type TCalculatorFormProps = {
  calculatorProductInfo: TCalculatorProductInfo;
  errorFields: {
    dealer: boolean;
    markModelYear: boolean;
    costCar: boolean;
    initFee: boolean;
    loanTerm: boolean;
    [key: string]: boolean;
  };
  selectedDealerPoint: string;
  setCalculatorProductInfo: React.Dispatch<
    React.SetStateAction<TCalculatorProductInfo>
  >;
  handleGetCalculations: () => void;
  isFormValid: boolean;
  isDataSend: boolean;
};

const CalculatorForm = ({
  setCalculatorProductInfo,
  calculatorProductInfo,
  handleGetCalculations,
  isFormValid,
  isDataSend,
  errorFields,
  selectedDealerPoint
}: TCalculatorFormProps) => {
  const { dicts } = useAppSelector((state) => state.dict);

  const {
    possibleModels,
    handleModalSelectChange,
    modalSelectState,
    possibleMarks
  } = useMarkModel(calculatorProductInfo);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveMarkModelYearDisabled, setSaveMarkModelYearDisabled] =
    useState<boolean>(true);

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    val = validateNumberInputValue(val, 8, true);
    setCalculatorProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const onChangeSelectHandler = (
    val: string,
    label: string,
    type: string,
    code?: string | undefined
  ) => {
    setCalculatorProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const modalSaveHandler = () => {
    // setIsFocused(prevState => ({...prevState, ["markModelYear"]: true}));
    setCalculatorProductInfo((prevState) => {
      return {
        ...prevState,
        markModelYear: {
          brand: Number(modalSelectState.markAuto),
          model: Number(modalSelectState.modelAuto),
          year: Number(modalSelectState.issueYear)
        }
      };
    });
    setShowModal(false);
  };

  useEffect(() => {
    setCalculatorProductInfo((prevState) => {
      return {
        ...prevState,
        markModelYear: {
          brand: null,
          model: null,
          year: null
        }
      };
    });
  }, [selectedDealerPoint]);

  const onChangeCheckedHandler = (
    val: boolean,
    label: string,
    type: string
  ) => {
    setCalculatorProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const [brandModelYearValueHandler, setBrandModelYearValueHandler] =
    useState<string>('');

  useEffect(() => {
    const isDisabled = !checkObjectValues(modalSelectState);
    setSaveMarkModelYearDisabled(isDisabled);
  }, [
    modalSelectState.markAuto,
    modalSelectState.modelAuto,
    modalSelectState.issueYear
  ]);

  useEffect(() => {
    if (!selectedDealerPoint) {
      setCalculatorProductInfo((prevState) => {
        return {
          ...prevState,
          markModelYear: {
            brand: null,
            model: null,
            year: null
          }
        };
      });
    } else {
      const { markAuto, modelAuto, issueYear } = modalSelectState;
      const brandName = dicts
        ?.find((dict) => dict.dictType === 'Brands')
        ?.content.find(
          (item) => item.code === parseInt(markAuto as string)
        )?.name;
      const modelName = dicts
        ?.find((dict) => dict.dictType === 'Models')
        ?.content.find(
          (item) => item.code === parseInt(modelAuto as string)
        )?.name;
      const brandValue = brandName ? brandName : '-';
      const modelValue = modelName ? modelName : '-';
      const yearValue = issueYear ? issueYear : '-';
      const val = `${brandValue}, ${modelValue}, ${yearValue}`;
      setBrandModelYearValueHandler(val);
    }
  }, [modalSelectState]);

  return (
    <div className="CalculatorForm">
      <CustomModal
        editClass="markModelYear"
        title="Марка, модель и год выпуска"
        isModalOpen={showModal}
        handleOk={modalSaveHandler}
        handleCancel={() => setShowModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowModal(false)}>
            Отмена
          </Button>,
          <Button
            disabled={saveMarkModelYearDisabled}
            key="submit"
            type="primary"
            onClick={modalSaveHandler}
          >
            Сохранить
          </Button>
        ]}
      >
        <div className="auto_selects">
          <Select
            showSearch
            optionFilterProp={'label'}
            value={modalSelectState.markAuto}
            placeholder="Марка авто"
            options={possibleMarks}
            onChange={(val) => handleModalSelectChange(val, 'markAuto')}
          />
          <Select
            showSearch
            optionFilterProp={'label'}
            value={modalSelectState.modelAuto}
            placeholder="Модель авто"
            options={possibleModels}
            onChange={(val) => handleModalSelectChange(val, 'modelAuto')}
          />
          <Input
            value={modalSelectState.issueYear || ''}
            placeholder="Год выпуска"
            onChange={(e) =>
              handleModalSelectChange(e.target.value, 'issueYear')
            }
          />
        </div>
      </CustomModal>
      <h2>Рассчитать автокредитование</h2>
      <div className="selects_box">
        {calculatorDataItem.map((item, index) => {
          if (item.type === 'input') {
            return (
              <CustomInput
                key={item.key + index}
                value={calculatorProductInfo[item.key] as string}
                label={item.key}
                type={'calculatorForm'}
                labelTitle={item.label}
                onChangeInputHandler={onChangeInputHandler}
              />
            );
          } else if (item.type === 'select') {
            return (
              <div
                key={item.key + index}
                style={
                  item.key === 'markModelYear'
                    ? {
                        gridArea: '2 / 1 / 2 / 3'
                      }
                    : {}
                }
              >
                <CustomSelect
                  key={item.key + index}
                  isFocused={false}
                  isError={errorFields[item.key]}
                  value={
                    item.editable
                      ? brandModelYearValueHandler
                      : (calculatorProductInfo[item.key] as string)
                  }
                  option={setDictionaryOptions(
                    item.snakeCaseValues as DictTypes,
                    dicts
                  )}
                  label={item.key}
                  type={'calculatorForm'}
                  labelTitle={item.label}
                  onChangeSelectHandler={onChangeSelectHandler}
                  setShowModal={item.editable ? setShowModal : undefined}
                  fieldsReadOnly={item.key === 'dealer' ? true : item.editable}
                  keyType="autocred"
                />
              </div>
            );
          } else if (item.type === 'switch') {
            return (
              <CustomSwitch
                key={item.key + index}
                value={calculatorProductInfo[item.key] as string}
                label={item.key}
                type={'calculatorForm'}
                labelTitle={item.label}
                onchangeCheckedHandler={onChangeCheckedHandler}
                fieldsReadOnly={false}
              />
            );
          }
        })}
      </div>

      <Button disabled={!isFormValid} onClick={handleGetCalculations}>
        Рассчитать
      </Button>
    </div>
  );
};

export default CalculatorForm;
