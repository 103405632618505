import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { useNavigate } from 'react-router-dom';
import {
  setShowVerifyFirstStep,
  setShowVerifySecondStep
} from '@/store/app.slice';
import { VerifyOTP } from '@/components/Shared/Modal/VerifyOTP/VerifyOTP';
import { TClientInfoOtpDto } from '@/types/otp.type';
import { createLitro, getLitro, validateOtpLitro } from '@/store/litro.slice';

export const LitroHeader: FC = () => {
  const navigate = useNavigate();
  const { litroUuid, litroRequest } = useAppSelector((state) => state.litro);
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.litro);

  const sendClientHandler = (client: TClientInfoOtpDto) => {
    dispatch(createLitro(client)).then(() => {
      dispatch(getLitro({}));
      dispatch(setShowVerifySecondStep(true));
      dispatch(setShowVerifyFirstStep(false));
    });
  };

  const sendOtpHandler = (value: string) => {
    dispatch(validateOtpLitro({ uuid: litroUuid!, otp: value })).then(() => {
      // dispatch(getLitro({}));
      dispatch(setShowVerifySecondStep(false));
      navigate('/pnd-form');
    });
  };

  return (
    <>
      <VerifyOTP
        fioInputShow={true}
        sendClient={(value) => sendClientHandler(value)}
        isLoading={isLoading}
        sendVerifyOtp={(value) => sendOtpHandler(value)}
        headerTitle="Новая заявка"
      />
      <div className="header">
        <div className="title">
          <h2>Дополнительная услуга LITRO (ПНД)</h2>
          <p>Здесь небольшое описание</p>
        </div>
        <div className="pagination">
          <button
            onClick={() => {
              dispatch(setShowVerifyFirstStep(true));
            }}
          >
            <p>Новая заявка</p>
          </button>
        </div>
      </div>
    </>
  );
};
