import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TModel } from '@/types/model.type';
import { modelsApi } from '@/integrations/index.api';

type TModelsSlice = {
  isLoading: boolean;
  models: TModel[];
  total: number;
};

const initialState: TModelsSlice = {
  isLoading: false,
  models: [],
  total: 0
};

export const getModels = createAsyncThunk(
  'admin/models/getAll',
  async ({
    limit,
    page,
    param,
    brandUuid
  }: {
    limit?: number;
    page?: number;
    param?: string;
    brandUuid?: string;
  }) => {
    try {
      const res = await modelsApi.getModels(limit, page, param, brandUuid);
    
      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const createModel = createAsyncThunk(
  'admin/models/create',
  async (model: TModel, { dispatch }) => {
    try {
      const data = {
        name: model.name,
        code: Number(model.code),
        brand_uuid: model.brand_uuid,
        capacity: model.capacity,
        year: model.year
      };
      const res = await modelsApi.createAdminModel(data);
      // dispatch(getModels());
      return res;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateModel = createAsyncThunk(
  'admin/models/update',
  async (model: TModel, { dispatch }) => {
    try {
      const res = await modelsApi.updateAdminModel(model);
      // dispatch(getModels());
    } catch (err) {
      console.log(err);
    }
  }
);

export const adminModelsSlice = createSlice({
  name: 'admin/models',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getModels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getModels.fulfilled, (state, action) => {
        state.models = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getModels.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {} = adminModelsSlice.actions;
