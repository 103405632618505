import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { litroApi } from '@/integrations/index.api';
import { TClientInfoOtpDto, TValidateOtp } from '@/types/otp.type';
import { TCreateLitroDto, TLitroResponse } from '@/types/litro.type';

type TLitroSliceProps = {
  litroResponses: TLitroResponse[];
  total: number;
  isLoading: boolean;
  isError: boolean;
  litroUuid: string | null;
  litroRequest: TLitroResponse | null;
  paymentType: { code: string | number; name: string }[];
  error: unknown; // Change to string to store error message
};

const initialState: TLitroSliceProps = {
  litroResponses: [],
  total: 0,
  isLoading: false,
  isError: false,
  error: null,
  litroUuid: null,
  paymentType: [],
  litroRequest: null
};

export const createLitro = createAsyncThunk(
  'litro/create',
  async (data: TClientInfoOtpDto, { rejectWithValue }) => {
    try {
      const response = await litroApi.create(data);
      return response;
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const validateOtpLitro = createAsyncThunk(
  'litro/validateOtp',
  async (data: TValidateOtp) => {
    return await litroApi.validateOtp(data);
  }
);

export const getLitroById = createAsyncThunk(
  'litro/getLitroById',
  async (uuid: string) => {
    return await litroApi.getLitroById(uuid);
  }
);

export const updateLitro = createAsyncThunk(
  'litro/update',
  async (data: TCreateLitroDto) => {
    return await litroApi.updateLitro(data);
  }
);

export const getLitro = createAsyncThunk(
  'litro/getLitro',
  async ({
    limit,
    page,
    param,
    userRequests
  }: {
    limit?: number;
    page?: number;
    param?: string;
    userRequests?: string;
  }) => {
    return await litroApi.getLitro(limit, page, param, userRequests);
  }
);

export const getPaymentType = createAsyncThunk(
  'litro/paymentType',
  async () => {
    return await litroApi.getPaymentType();
  }
);

export const litroSlice = createSlice({
  name: 'litro',
  initialState,
  reducers: {
    setLitroRequest: (state, action: PayloadAction<TLitroResponse>) => {
      state.litroRequest = action.payload;
    },
    setLitroUuid: (state, action) => {
      state.litroUuid = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLitro.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLitro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.litroUuid = action.payload?.request_uuid || null;
      })
      .addCase(createLitro.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(validateOtpLitro.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateOtpLitro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.litroRequest = action.payload;
      })
      .addCase(validateOtpLitro.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLitro.pending, (state) => {
        state.isLoading = true;
        // state.litroRequest = null;
      })
      .addCase(getLitro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.litroResponses = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(getLitro.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLitroById.pending, (state) => {
        state.isLoading = true;
        // state.litroRequest = null;
      })
      .addCase(getLitroById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.litroRequest = action.payload;
        state.litroUuid = action.payload?.uuid;
      })
      .addCase(getLitroById.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPaymentType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paymentType = action.payload;
      });
  }
});

export const { setLitroRequest, setLitroUuid } = litroSlice.actions;
