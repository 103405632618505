import { FC } from 'react';
import { Modal } from 'antd';
import { TFlashLog } from '@/types/flashLog.type';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowModalFlashLog } from '@/store/admin/admin.slice';

type TFlashLogModal = {
  flashLog: TFlashLog | null;
};
export const FlashLogModal: FC<TFlashLogModal> = ({ flashLog }) => {
  const dispatch = useAppDispatch();
  const { showFlashLogModal } = useAppSelector((state) => state.admin);
  return (
    <Modal
      style={{ maxHeight: '500px' }}
      width={'50%'}
      open={showFlashLogModal}
      onOk={() => dispatch(setShowModalFlashLog(false))}
      onCancel={() => dispatch(setShowModalFlashLog(false))}
    >
      <p
        style={{
          maxHeight: '450px',
          overflow: 'auto',
          margin: '10px',
          width: '95%'
        }}
      >
        {flashLog && flashLog.description}
      </p>
    </Modal>
  );
};
