import { TPersonAddressInfo } from '../types/personInfo';

/**
 * @const IN - Format Integer to the thousand groups
 */
const IN = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 });
/**
 * @const INF - Format Float to the thousand groups and 2 decimals
 */
const INF = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 });
const INF4 = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 4 });
const splitThousands = (num: string): string => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
export const parsePrice = (num: string): number => {
  return parseFloat(num.replace(/\$\s?|( *)/g, '').replace(',', '.'));
};
export const formatPrice = (num: number | string, d: number = 2): string => {
  if (!num) return '';
  let numStr: string = typeof num === 'number' ? num.toString(10) : num;
  const [integer, fraction] = numStr.split('.');
  let fractionPart: string;
  if (!fraction) {
    fractionPart = '';
  } else if (fraction && fraction.length <= d) {
    fractionPart = `,${fraction}`;
  } else {
    fractionPart = `,${parseFloat(numStr).toFixed(d).split('.')[1]}`;
  }
  return splitThousands(integer) + fractionPart;
};
export const finNum = (num: number): string => {
  return INF.format(num);
};
export const finNum4 = (num: number): string => {
  return INF4.format(num);
};
export const countNum = (num: number): string => {
  return IN.format(num);
};
export const parseNum = (num: string, d: number = 0): number => {
  const cleanNum = num.replace(/\s/gim, '').replace(',', '.');
  if (d === 0) {
    return parseInt(cleanNum, 10);
  } else {
    return parseFloat(parseFloat(cleanNum).toFixed(d));
  }
};
//
const setZeroPrefix = (numString: string): string => {
  if (numString.length < 2) {
    return '0' + numString;
  } else {
    return numString;
  }
};
export const parseDate = (dateString: string): Date => {
  const [date, time] = dateString.split(' ');
  let [dd, mm, yyyy] = date.split('.');
  const datePart = `${yyyy}-${setZeroPrefix(mm)}-${setZeroPrefix(dd)}`;
  const timePart = !time ? '' : `T${time}+06:00`;
  return new Date(`${datePart}${timePart}`);
};

export const formatAddress = ({
  personalAddressInfo,
  type
}: {
  personalAddressInfo: TPersonAddressInfo;
  type: string;
}): string => {
  if (type === 'residence') {
    return personalAddressInfo.regionResidence !== ''
      ? `${personalAddressInfo.regionResidence}, ${
          personalAddressInfo.cityDistrictResidence
        }, ${
          personalAddressInfo.villageSettlementResidence &&
          `село ${personalAddressInfo.villageSettlementResidence},`
        } улица ${personalAddressInfo.streetNameResidence}, дом ${
          personalAddressInfo.houseNumResidence
        }${
          personalAddressInfo.appartmentNumResidence
            ? `, квартира ${personalAddressInfo.appartmentNumResidence}`
            : ''
        }`
      : '';
  } else if (type === 'personal') {
    return personalAddressInfo.regionPersonal !== ''
      ? `${personalAddressInfo.regionPersonal}, ${
          personalAddressInfo.cityDistrictPersonal
        }, ${
          personalAddressInfo.villageSettlementPersonal &&
          `село ${personalAddressInfo.villageSettlementPersonal},`
        } улица ${personalAddressInfo.streetNamePersonal}, дом ${
          personalAddressInfo.houseNumPersonal
        }${
          personalAddressInfo.appartmentNumPersonal
            ? `, квартира ${personalAddressInfo.appartmentNumPersonal}`
            : ''
        }`
      : '';
  } else if (type === 'job') {
    return personalAddressInfo.regionJob !== ''
      ? `${personalAddressInfo.regionJob}, ${
          personalAddressInfo.cityDistrictJob
        }, ${
          personalAddressInfo.villageSettlementJob &&
          `село ${personalAddressInfo.villageSettlementJob},`
        } улица ${personalAddressInfo.streetNameJob}, дом ${
          personalAddressInfo.houseNumJob
        }, офис ${personalAddressInfo.appartmentNumJob}`
      : '';
  } else {
    return '';
  }
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const cleanedNumber = value.replace(/\D/g, '');
  const formattedNumber = `+7(${cleanedNumber.slice(
    1,
    4
  )})-${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7, 11)}`;
  return formattedNumber;
};

export const cleanPhoneNumber = (value: string) => {
  const cleanedNumber = value.replace(/\D/g, '');
  return cleanedNumber;
};

export const cleanNameValue = (value: string) => {
  const cleanedNameValue = value.replace(/\d+/g, '').trim().toLowerCase();
  return cleanedNameValue;
};


export const cleanFullNameValue = (value: string) => {
  const cleanedNameValue = value
    .replace(/[^а-яё\s]/gi, '')  
    .replace(/\s{2,}/g, ' ')   
    .trimStart();           
  const capitalizedValue = cleanedNameValue
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return capitalizedValue;
};


export const deformatPhoneNumber = (phoneNumber: string) => {
  const cleanedNumber = phoneNumber.replace(/\D/g, '');
  const formattedNumber = `+${cleanedNumber.slice(1, 5)}-${cleanedNumber.slice(
    5,
    8
  )}-${cleanedNumber.slice(8)}`;
  return formattedNumber;
};

export const validateDocumentNumber = (value: string, docType: string) => {
  let valueToValidate = value.replace(/\s/g, '');
  if (docType === 'Паспорт') {
    const letters = valueToValidate.replace(/[^a-zA-Z]/g, '');
    const firstLetter = letters.length ? letters[0].toUpperCase() : '';
    if (!/^[a-zA-Z]+/.test(letters)) return '';
    const numbers = valueToValidate.replace(/[^0-9]/g, '').slice(0, 8);
    valueToValidate = firstLetter + numbers;
  } else {
    valueToValidate = valueToValidate.replace(/\D/g, '').slice(0, 9);
  }
  return valueToValidate;
};

export const extractNumericValues = (value: string) => {
  const numericString = value.replace(/\D/g, '');
  return numericString;
};

// export const convertPersonInfoData = (personInfo: TPersonInfo) => {
//   const convertedData = {
//     dateBirth: personInfo.dateBirth,
//     docAuth: personInfo.docAuth,
//     docIssueDate: personInfo.docIssueDate,
//     docNum: personInfo.docNum,
//     docType: personInfo.docType,
//     docValidPeriod: personInfo.docValidPeriod,
//     familyStatus: personInfo.familyStatus,
//     isLiveRegistred: personInfo.isLiveRegistred,
//     placeBirth: personInfo.placeBirth,
//     residentStatus: personInfo.residentStatus,
//     sex: personInfo.sex,
//     spouseInfo: personInfo.spouseInfo,
//     address: personInfo.address
//   };
//   return convertedData;
// };

export const removeSpacesFromValue = (value: string) => {
  const stringWithoutSpaces = value.replace(/\s/g, '');
  return stringWithoutSpaces;
};

// export const formatPhoneNumber = (value: string) => {
//     if (!value) return value
//     let phoneNumber = value.replace(/[^\d]/g, '');

//     if ( phoneNumber.length > 4){

//         phoneNumber = phoneNumber.slice(1);
//     }
//     const phoneNumberLength = phoneNumber.length

//     if (phoneNumberLength < 4) return phoneNumber
//     if (phoneNumberLength < 7) {
//         return `+7(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3)}`
//     }
//     return `+7(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
// }
