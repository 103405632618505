import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export type TDoughnutChartProps = {
  labels: string[];
  backgroundColors: string[];
  data: number[];
};

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      align: 'start' // or "start", "end", or undefined
    },
    title: {
      display: false
      // text: 'Chart.js Bar Chart'
    },
    tooltip: {
      backgroundColor: '#111827'
    }
  }
};

const DoughnutChart = ({
  backgroundColors,
  data,
  labels
}: TDoughnutChartProps) => {
  return (
    <Doughnut
      //@ts-ignore
      options={options}
      data={{
        labels: labels,
        datasets: [
          {
            label: 'Профинансировано',
            data: data,
            backgroundColor: backgroundColors,
            borderRadius: 7
          }
        ]
      }}
    />
  );
};

export default DoughnutChart;
