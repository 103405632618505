// Import necessary modules
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TAppStore = {
  wsIsConnected: boolean;
  showSelectCarModal: boolean;
  showBiometricVerificating: boolean;
  showVerifyFirstStep: boolean;
  showVerifySecondStep: boolean;
  verifyModalState: {
    showVerifyModal: {
      prevClient: boolean;
      smsVerf: boolean;
    };
    isNewApplication: boolean;
  };
  verifyModalInsurance: {
    showVerifyModalInsurance: {
      prevClientInsurance: boolean;
      smsVerfInsurance: boolean;
    };
    isNewApplicationInsurance: boolean;
  };
  verifyModalLitro: {
    showVerifyModalLitro: {
      prevClientLitro: boolean;
      smsVerifyLitro: boolean;
    };
    // isNewLitro: boolean;
  };
};
// Define the initial state for the combined store
const initialState: TAppStore = {
  wsIsConnected: false,
  showBiometricVerificating: false,
  showSelectCarModal: false,
  showVerifyFirstStep: false,
  showVerifySecondStep: false,
  verifyModalState: {
    showVerifyModal: {
      prevClient: false,
      smsVerf: false
    },
    isNewApplication: false
  },
  verifyModalInsurance: {
    showVerifyModalInsurance: {
      prevClientInsurance: false,
      smsVerfInsurance: false
    },
    isNewApplicationInsurance: false
  },
  verifyModalLitro: {
    showVerifyModalLitro: {
      prevClientLitro: false,
      smsVerifyLitro: false
    }
    // isNewLitro: false
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShowSelectCarModal: (state, action: PayloadAction<boolean>) => {
      state.showSelectCarModal = action.payload;
    },
    setShowVerifyFirstStep: (state, action: PayloadAction<boolean>) => {
      state.showVerifyFirstStep = action.payload;
    },
    setShowVerifySecondStep: (state, action: PayloadAction<boolean>) => {
      state.showVerifySecondStep = action.payload;
    },
    setVerifyModalShowType: (
      state,
      action: PayloadAction<{ prevClient: boolean; smsVerf: boolean }>
    ) => {
      state.verifyModalState.showVerifyModal = action.payload;
    },
    setVerifyModalIsNewApplication: (state, action: PayloadAction<boolean>) => {
      state.verifyModalState.isNewApplication = action.payload;
    },
    setVerifyModalShowInsuranceType: (
      state,
      action: PayloadAction<{
        prevClientInsurance: boolean;
        smsVerfInsurance: boolean;
      }>
    ) => {
      state.verifyModalInsurance.showVerifyModalInsurance = action.payload;
    },
    // setVerifyLitro: (state, action: PayloadAction<boolean>) => {
    //   state.verifyModalLitro.isNewLitro = action.payload;
    // },
    setVerifyModalLitroType: (
      state,
      action: PayloadAction<{
        prevClientLitro: boolean;
        smsVerifyLitro: boolean;
      }>
    ) => {
      state.verifyModalLitro.showVerifyModalLitro = action.payload;
    },
    setShowBiometricVerificating(state, action: PayloadAction<boolean>) {
      state.showBiometricVerificating = action.payload;
    },
    setWsIsConnected(state, action: PayloadAction<boolean>) {
      state.wsIsConnected = action.payload;
    }
  }
});

// export const appInsuranceSlice = createSlice({
//   name: 'admin-insurance',
//   initialState,
//   reducers: {
//     setVerifyModalShowInsuranceType: (
//       state,
//       action: PayloadAction<{
//         prevClientInsurance: boolean;
//         smsVerfInsurance: boolean;
//       }>`
//     ) => {
//       state.verifyModalInsurance.showVerifyModalInsurance = action.payload;
//     },
//     setVerifyModalIsNewApplication: (state, action: PayloadAction<boolean>) => {
//       state.verifyModalInsurance.isNewApplicationInsurance = action.payload;
//     }
//   }
// });

export const {
  setShowSelectCarModal,
  setShowVerifyFirstStep,
  setShowVerifySecondStep,
  setVerifyModalShowType,
  setVerifyModalIsNewApplication,
  setVerifyModalShowInsuranceType,
  setShowBiometricVerificating,
  // setVerifyLitro,
  setVerifyModalLitroType,
  setWsIsConnected
} = appSlice.actions;

// export const {
//   setVerifyModalShowInsuranceType,
//   setVerifyModalIsNewApplication: setVerifyModalIsNewApplicationInsurance
// } = appInsuranceSlice.actions;
