import { FC, useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select
} from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowUserModal } from '@/store/admin/admin.slice';
import { TUser, TUserDto } from '@/types/user.type';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import {
  createUser,
  getAdminAllUsers,
  getAllPositions,
  getSalePoints,
  updateUser
} from '@/store/admin/adminUsers.slice';
import { getAllDealers } from '@/store/admin/adminDealers.slice';
import dayjs from 'dayjs';

type TUserModalProps = {
  editMode?: boolean;
  userItem: TUser | null;
  paginationData: { limit: number; page: number };
  param: string;
};

export const CreateUserModal: FC<TUserModalProps> = ({
  editMode,
  userItem,
  paginationData,
  param
}) => {
  const dispatch = useAppDispatch();
  const { showUserModal } = useAppSelector((state) => state.admin);
  const { sale_points, positions } = useAppSelector(
    (state) => state.adminUsers
  );
  const { dealers } = useAppSelector((state) => state.adminDealers);
  const [userData, setUserData] = useState<TUserDto>({
    birth_date: '',
    email: '',
    first_name: '',
    is_active: false,
    last_name: '',
    login: '',
    middle_name: '',
    password: '',
    phone_number: '',
    position_uuid: '',
    user_iin: '',
    sale_point_uuid: '',
    Users_Dealers: [],
    work_date_start: new Date().toISOString()
  });

  const [salePointOptions, setSalePointOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [dealerOptions, setDealerOptions] = useState<
    { value: string; label: string }[]
  >([]);
  // const [roleActionOptions, setRoleActionOptions] = useState<
  //   { value: string; label: string }[]
  // >([]);
  const [positionOptions, setPositionOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    dispatch(getAllPositions());
    dispatch(getSalePoints());
    dispatch(getAllDealers({ limit: 0, page: 0, param: '' }));
    // dispatch(getRolesActions()).then(() => {
    // const rolesActions = roles_actions.map((action) => ({
    //       value: action.uuid,
    //       label: action.name
    //     }));
    //     setRoleActionOptions(
    //       roles_actions.map((role) => ({ value: role.uuid!, label: role.name }))
    //     );
    // });

    if (editMode && userItem) {
      setUserData(userItem);
    }
  }, []);
  useEffect(() => {
    if (positions) {
      const data = positions.map((position) => ({
        value: position.uuid!,
        label: position.name
      }));

      setPositionOptions(data);
    }
  }, [positions]);

  useEffect(() => {
    if (sale_points) {
      const points = sale_points.map((point) => ({
        value: point.uuid,
        label: point.name
      }));
      setSalePointOptions(points);
    }
  }, [sale_points]);

  useEffect(() => {
    if (userData.sale_point_uuid && sale_points) {
      const selectedSalePoint = sale_points.find(
        (point) => point.uuid === userData.sale_point_uuid
      );
      const dealersData = dealers
        .filter((deal) => deal.city_uuid === selectedSalePoint!.city_uuid)
        .filter((dealer) => dealer.is_active)
        .map((d) => ({
          value: d.uuid,
          label: d.name
        }));

      setDealerOptions(dealersData);
    }
  }, [userData.sale_point_uuid]);

  const onOk = () => {
    if (editMode) {
      dispatch(updateUser(userData as TUser)).then(() => {
        dispatch(
          getAdminAllUsers({
            limit: paginationData.limit,
            page: paginationData.page
          })
        );
      });
      return dispatch(setShowUserModal(false));
    }
    dispatch(createUser(userData)).then(() => {
      dispatch(
        getAdminAllUsers({
          limit: paginationData.limit,
          page: paginationData.page
        })
      );
    });
    dispatch(setShowUserModal(false));
  };

  const usersDealersHandle = (value: string[]) => {
    const dealerUuids = value;
    setUserData((prevState) => ({
      ...prevState,
      Users_Dealers: dealerUuids.map((dealer_uuid) => ({
        dealer_uuid,
        is_active: true
      }))
    }));
  };

  return (
    <Modal
      width="50%"
      className="modal"
      open={showUserModal}
      okButtonProps={{
        disabled:
          !!!userData.email ||
          // !!!userData.password ||
          !!!userData.sale_point_uuid ||
          !!!userData.position_uuid ||
          !!!userData.user_iin ||
          !!!userData.birth_date ||
          !!!userData.phone_number
      }}
      onOk={onOk}
      onCancel={() => dispatch(setShowUserModal(false))}
    >
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Row>
              <Col span={11}>
                <Form.Item label="Email/login" required>
                  <Input
                    value={userData.email}
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        email: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Пароль" required>
                  <Input
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        password: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label="Фамилия">
                  <Input
                    value={userData.last_name}
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        last_name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label="Имя">
                  <Input
                    value={userData.first_name}
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        first_name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label="Отчество">
                  <Input
                    value={userData.middle_name}
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        middle_name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label="Дата рождения" required>
                  <DatePicker
                    value={
                      userData.birth_date ? dayjs(userData.birth_date) : null
                    }
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      setUserData((prevState) => ({
                        ...prevState,
                        birth_date: value ? value.toISOString() : '' // Convert to ISO 8601 format
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2} offset={2}>
                <Form.Item label="Статус">
                  <Checkbox
                    checked={userData.is_active}
                    onChange={() =>
                      setUserData((prevState) => ({
                        ...prevState,
                        is_active: !userData.is_active
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={1}>
                <Form.Item label="Телефон" required>
                  <PhoneInput
                    value={userData.phone_number}
                    defaultCountry="kz"
                    onChange={(value) => {
                      setUserData((prevState) => ({
                        ...prevState,
                        phone_number: value.slice(1, 12)
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label="ИИН" required>
                  <InputNumber
                    style={{ width: '100%' }}
                    value={userData.user_iin}
                    controls={false}
                    maxLength={12}
                    minLength={12}
                    formatter={(value) =>
                      value ? (/\d*/.test(value) ? value : '') : ''
                    }
                    onChange={(value) => {
                      const numericValue = parseInt(value || '', 10);
                      if (!isNaN(numericValue)) {
                        setUserData({
                          ...userData,
                          user_iin: numericValue.toString()
                        });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (
                        !(
                          e.key === 'Backspace' ||
                          e.key === 'Delete' ||
                          (e.key >= '0' && e.key <= '9')
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col span={11}>
                <Form.Item label="Выбор ТТ по региону" required>
                  <Select
                    value={userData.sale_point_uuid}
                    options={salePointOptions}
                    onChange={(value) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        sale_point_uuid: value,
                        Users_Dealers: []
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Все торговые точки">
                  <Select
                    disabled={!!!userData.sale_point_uuid}
                    maxTagCount={1}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? '').includes(
                        input.toLowerCase()
                      )
                    }
                    mode="multiple"
                    value={userData.Users_Dealers.map(
                      (item) => item.dealer_uuid
                    )}
                    options={dealerOptions}
                    onChange={(value) => {
                      usersDealersHandle(value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label="Роль" required>
                  <Select
                    value={userData.position_uuid}
                    options={positionOptions}
                    onChange={(value) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        position_uuid: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
