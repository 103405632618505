import { Switch } from 'antd';
import '@/styles/CustomSwitch.scss';

export type TCustomSwitchProps = {
  value: string | boolean | Date | null | undefined;
  label: string;
  type: string;
  labelTitle: string;
  fieldsReadOnly?: boolean;
  onchangeCheckedHandler: (val: boolean, label: string, type: string) => void;
};

export const CustomSwitch = ({
  value,
  label,
  type,
  labelTitle,
  onchangeCheckedHandler,
  fieldsReadOnly
}: TCustomSwitchProps) => {
  return (
    <div className="select_switch">
      <Switch
        checked={value as boolean}
        defaultChecked
        disabled={fieldsReadOnly !== undefined ? fieldsReadOnly : false}
        onChange={(val) => onchangeCheckedHandler(val, label, type)}
      />
      <p>{labelTitle}</p>
    </div>
  );
};
