import { Col, Form, Input, Modal, Row, Select } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { TDealer, TDealersBanks, TDealersProducts } from '@/types/dealer.type';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import '@/styles/AdminModal.scss';
import {
  createDealer,
  getAllDealers,
  updateDealer
} from '@/store/admin/adminDealers.slice';
import { getProducts } from '@/store/admin/adminProducts.slice';
import { DealersBanksTable } from '@/components/AdminPanel/Modals/CreateOrEditDealerModal/DealersBanksTable';
import { DealersProductsTable } from '@/components/AdminPanel/Modals/CreateOrEditDealerModal/DealersProductsTable';
import { setShowModalCreateOrEditDealer } from '@/store/admin/admin.slice';

type TCreateOrEditDealerProps = {
  paginationData: { limit: number; page: number };
  searchParam: string;
  editMode: boolean;
  dealerItem: TDealer | null;
  setShow: () => void;
};

export const CreateOrEditDealerModal: FC<TCreateOrEditDealerProps> = ({
  editMode,
  dealerItem,
  paginationData,
  searchParam,
  setShow
}) => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const { products } = useAppSelector((state) => state.adminProduct);
  const [dealer, setDealer] = useState<TDealer>({
    uuid: '',
    name: '',
    code: '',
    city_uuid: '',
    halyk_dealer_id: '',
    name_eurasian: '',
    forte_id: '',
    is_active: true,
    sold_brands: '',
    Dealers_Banks: [],
    Dealers_Products: [],
    legal_address: '',
    physical_address: '',
    workpoint_name: '',
    has_commercial: false
  });
  const [dealersBanks, setDealersBanks] = useState<TDealersBanks[]>([]);
  const [dealersProducts, setDealersProducts] = useState<TDealersProducts[]>(
    []
  );

  const [brandOptions, setBrandOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [cityOptions, setCityOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [bankOptions, setBankOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [productOptions, setProductOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (editMode && dealerItem) {
      setDealer(dealerItem);
    }

    if (dicts && dicts.length) {
      const brands = findDictByTitle(DictTypes.Brands, dicts).content.map(
        (brand) => {
          return { value: brand.uuid, label: brand.name };
        }
      );
      setBrandOptions(brands);
      const cities = findDictByTitle(DictTypes.Cities, dicts)
        .content.filter((c) => c.is_city)
        .map((city) => {
          return { value: city.uuid, label: city.name };
        });
      setCityOptions(cities);

      const banks = findDictByTitle(DictTypes.Banks, dicts).content.map(
        (bank) => {
          return {
            value: bank.uuid,
            label: bank.fullname ? bank.fullname : bank.name
          };
        }
      );
      banks.unshift({ value: 'all', label: 'Все' });
      setBankOptions(banks);
    }

    dispatch(getProducts({}));
  }, []);

  useEffect(() => {
    if (products) {
      const data = products
        .filter((prod) => prod.is_active === true)
        .map((product) => ({
          value: product.uuid ? product.uuid : '',
          label: product.name
        }));
      data.unshift({ value: 'all', label: 'Все' });
      setProductOptions(data);
    }
  }, [products]);

  useEffect(() => {
    if (dealer.Dealers_Banks) {
      setDealersBanks(dealer.Dealers_Banks);
    }
    if (dealer.Dealers_Products) {
      setDealersProducts(dealer.Dealers_Products);
    }

    const banksContent = findDictByTitle(DictTypes.Banks, dicts).content;
    const filteredBanks = banksContent
      .filter((bank) =>
        dealer.Dealers_Banks.some(
          (dealerBank) => dealerBank.bank_uuid === bank.uuid
        )
      )
      .map((bank, index) => ({
        name: bank.fullname,
        is_active: dealer.Dealers_Banks[index].is_active,
        bank_uuid: dealer.Dealers_Banks[index].bank_uuid,
        custom_timeout: dealer.Dealers_Banks[index].custom_timeout,
        is_priority_timeout: dealer.Dealers_Banks[index].is_priority_timeout
      }));
    setDealersBanks(filteredBanks);
  }, [dealer.Dealers_Banks]);

  useEffect(() => {
    const productData = products
      .filter((product) =>
        dealer.Dealers_Products.some(
          (dealerProduct) => dealerProduct.product_uuid === product.uuid
        )
      )
      .map((product, index) => ({
        name: product.name,
        bank:
          product.bank && product.bank.fullname
            ? product.bank.fullname
            : 'No data',
        product_uuid: dealer.Dealers_Products[index].product_uuid,
        is_active: dealer.Dealers_Products[index].is_active
      }));
    setDealersProducts(productData);
  }, [dealer.Dealers_Products]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDealer((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  const onOk = () => {
    delete dealer.city;
    delete dealer.brand;
    if (editMode) {
      dispatch(
        updateDealer({
          ...dealer,
          Dealers_Banks: dealersBanks,
          Dealers_Products: dealersProducts
        })
      ).then(() => {
        dispatch(
          getAllDealers({
            limit: paginationData.limit,
            page: paginationData.page,
            param: searchParam
          })
        );
      });

      return setShow(); //dispatch(setShowModalCreateOrEditDealer(false));
    }

    dispatch(
      createDealer({
        ...dealer,
        Dealers_Banks: dealersBanks,
        Dealers_Products: dealersProducts
      })
    ).then(() => {
      dispatch(
        getAllDealers({
          limit: paginationData.limit,
          page: paginationData.page,
          param: searchParam
        })
      );
    });
    return setShow(); //dispatch(setShowModalCreateOrEditDealer(false));
  };
  return (
    <Modal
      open={true}
      onOk={onOk}
      onCancel={() => dispatch(setShowModalCreateOrEditDealer(false))}
      width={'60%'}
      className="modal"
    >
      <Form
        layout="vertical"
        style={{
          margin: '30px 0'
        }}
      >
        <Row>
          <Col span={24}>
            <Row className="admin-modal-line">
              <Col span={17}>
                <Form.Item label="Наименование" required>
                  <Input
                    onChange={onInputChange}
                    name="name"
                    value={dealer.name}
                  />
                </Form.Item>
              </Col>
              <Col span={5} offset={2}>
                <Form.Item label="Код" required>
                  <Input
                    onChange={onInputChange}
                    name="code"
                    value={dealer.code}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="admin-modal-line">
              <Col span={14}>
                <Form.Item label="ТОО">
                  <Input
                    onChange={onInputChange}
                    name="workpoint_name"
                    value={dealer.workpoint_name}
                  />
                </Form.Item>
              </Col>
              <Col span={8} offset={2}>
                <Form.Item label="Город" required>
                  <Select
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? '').includes(
                        input.toLowerCase()
                      )
                    }
                    showSearch
                    options={cityOptions}
                    value={dealer.city_uuid}
                    onChange={(value) =>
                      setDealer((prevState) => ({
                        ...prevState,
                        city_uuid: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="admin-modal-line">
              <Col span={11}>
                <Form.Item label="Юридический адрес">
                  <Input.TextArea
                    onChange={(e) =>
                      setDealer((prevState) => ({
                        ...prevState,
                        legal_address: e.target.value
                      }))
                    }
                    // name="legal_address"
                    value={dealer.legal_address}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Фактический адрес">
                  <Input.TextArea
                    onChange={(e) =>
                      setDealer((prevState) => ({
                        ...prevState,
                        physical_address: e.target.value
                      }))
                    }
                    name="physical_address"
                    value={dealer.physical_address}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="admin-modal-line">
              <Col span={8}>
                <Form.Item label="Продаваемый бренд" required>
                  <Select
                    mode="multiple"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? '').includes(
                        input.toLowerCase()
                      )
                    }
                    showSearch
                    options={brandOptions}
                    value={dealer.sold_brands}
                    onChange={(value) =>
                      setDealer((prevState) => ({
                        ...prevState,
                        sold_brands: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6} offset={1}>
                <Form.Item label="Продажа коммерческой техники" required>
                  <Checkbox
                    checked={dealer.has_commercial}
                    onChange={() =>
                      setDealer((prevState) => ({
                        ...prevState,
                        has_commercial: !prevState.has_commercial
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={3} offset={1}>
                <Form.Item label="Активно" required>
                  <Checkbox
                    checked={dealer.is_active}
                    onChange={() =>
                      setDealer((prevState) => ({
                        ...prevState,
                        is_active: !prevState.is_active
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="admin-modal-line">
              <Col span={11}>
                <Form.Item label="Halyk dealer ID">
                  <Input
                    name="halyk_dealer_id"
                    value={dealer.halyk_dealer_id ? dealer.halyk_dealer_id : ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Eurasian name">
                  <Input
                    name="name_eurasian"
                    value={dealer.name_eurasian ? dealer.name_eurasian : ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="admin-modal-line">
              <Col span={11}>
                <Form.Item label="Forte ID">
                  <Input
                    name="forte_id"
                    value={dealer.forte_id ? dealer.forte_id : ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item label="Банки">
              <Select
                key="select"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                showSearch
                style={{ width: '100%' }}
                value={dealersBanks.map((dealer) => dealer.bank_uuid)}
                options={bankOptions}
                mode="multiple"
                maxTagCount={1}
                onChange={(selectedValues: string[]) => {
                  if (selectedValues.includes('all')) {
                    const allBanks = bankOptions
                      .map((bank) => bank.value)
                      .filter((bank) => bank !== 'all');
                    setDealer((prevState) => ({
                      ...prevState,
                      Dealers_Banks: allBanks.map((value) => ({
                        bank_uuid: value,
                        is_active: false,
                        custom_timeout: '0',
                        is_priority_timeout: false
                      }))
                    }));
                  } else {
                    setDealer((prevState) => ({
                      ...prevState,
                      Dealers_Banks: selectedValues.map((value) => ({
                        bank_uuid: value,
                        is_active: false,
                        custom_timeout: '0',
                        is_priority_timeout: false
                      }))
                    }));
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="admin-modal-line">
          <Col span={24}>
            <DealersBanksTable
              dealersBanks={dealersBanks}
              setDealersBanks={setDealersBanks}
            />
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item label="Продукты">
              <Select
                key="select"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                showSearch
                style={{ width: '100%' }}
                value={dealersProducts.map((dealer) => dealer.product_uuid)}
                options={productOptions}
                mode="multiple"
                maxTagCount={1}
                onChange={(selectedValues: string[]) => {
                  if (selectedValues.includes('all')) {
                    const allProducts = productOptions
                      .map((product) => product.value)
                      .filter((product) => product !== 'all');
                    setDealer((prevState) => ({
                      ...prevState,
                      Dealers_Products: allProducts.map((value) => ({
                        product_uuid: value,
                        is_active: false
                      }))
                    }));
                  } else {
                    setDealer((prevState) => ({
                      ...prevState,
                      Dealers_Products: selectedValues.map((value) => ({
                        product_uuid: value,
                        is_active: false
                      }))
                    }));
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="admin-modal-line">
          <Col span={24}>
            <DealersProductsTable
              dealersProducts={dealersProducts}
              setDealersProducts={setDealersProducts}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
