import { DictTypes } from '../constants';

export const dictsNavigateMapper = (dictType: DictTypes) => {
  let label = '';
  switch (dictType) {
    case DictTypes.AutoTypes:
      label = 'Типы автомобиля';
      break;
    case DictTypes.DocumentTypes:
      label = 'Типы документов';
      break;
    case DictTypes.OrganizationTypes:
      label = 'Виды организаций';
      break;
    case DictTypes.Brands:
      label = 'Бренды';
      break;
    case DictTypes.Dealers:
      label = 'Дилерские центры';
      break;
    case DictTypes.AddContactsIsRelated:
      label = 'Кем приходится';
      break;
    // case DictTypes.DocumentInsuringAuthorities:
    //   label = 'Организации выдачи документов';
    //   break;
    case DictTypes.ResidentStatuses:
      label = 'Статус резиндента';
      break;
    case DictTypes.SocialStatuses:
      label = 'Социальные статусы';
      break;
    // case DICT_TYPES.Models:
    //   label = 'Модели';
    //   break;
    case DictTypes.Statuses:
      label = 'Статус заявки';
      break;
    case DictTypes.Banks:
      label = 'Банки';
      break;
    case DictTypes.LoanTerms:
      label = 'Сроки займа';
      break;
    case DictTypes.EngineTypes:
      label = 'Виды движка';
      break;
  }
  return {
    path: `adminPage/dictionaries/${dictType}`,
    key: `adminPage/dictionaries/${dictType}`,
    icon: null,
    label: label
  };
};
