/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Button, Input, notification, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useMarkModel } from 'hooks/useMarkModel';
import {
  setDictionaryOptions,
  validateNumberInputValue
} from 'services/helpers';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { CustomSwitch } from '@/components/Shared/Switch/CustomSwitch';
import { isFocusData } from '@/data/orderStep.data';
import { TClientDataState, TFocusState } from '@/types/state';
import '@/styles/_slices.scss';
import '@/styles/StatusContentBox.scss';
import '@/styles/InsuranceStepOrder.scss';
import { dispatch, useAppSelector } from '@/store';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import {
  modalTSItemsInsuramce,
  personalClientInsurance,
  productInfoItemsInsurance
} from '@/data/productInsurance.data';
import { DictTypes } from '@/constants';
import { TNewInsuranceDto } from '@/types/newInsurance';
import { TProductInsuranceInfo } from '@/types/productInfo';
import { TPersonInsuranceInfo } from '@/types/personInfo';
import { cleanPhoneNumber } from '@/services/formatter';
import { TAutoInsuranceInfo } from '@/types/avtoInsuranceInfo';
import { TInsuranceInfo } from '@/types/insuranceInfo';
import { TClientInsuranceInfo } from '@/types/clientInsurance';
import { createInsuranceOrder } from '@/store/orderInsurance.slice';
import { apiConnector } from '@/integrations/api.connector';
import { TFile } from '@/types/request';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import duration from 'dayjs/plugin/duration';
import { checkObjectValues } from '@/services/validators/checkObjectValues';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  validateAutoInsuranceInfo,
  validatePersonalInsuranceInfo
} from '@/services/validators/insuranceValidator';

dayjs.extend(duration);

export type TOrderInsuranceProps = {
  createRequest: () => void;
  clientInfo: TClientDataState;
};

export const InsuranceFirstStep = () => {
  const {
    productInsuranceInfo: storeProductInfo,
    personalInsuranceInfo: storePersonalInfo,
    autoInsuranceInfo: storeAutoInsuranceInfo,
    insurance: storeInsuranceInfo,
    clientInsuranceInfo: storeClientInsuranceInfo,
    otpCode,
    uuid,
    requestId
  } = useAppSelector((state) => state.orderInsurance);

  const { userInfo } = useAppSelector((state) => state.userInfo);
  const dealer = useAppSelector((state) => state.userInfo.dealer);
  const { dicts } = useAppSelector((state) => state.dict);
  const priseAvto = useAppSelector(
    (state) => state.orderInsurance.autoInsuranceInfo
  );

  const { clientInsuranceInfo: clientInsuranceInfoStore } = useAppSelector(
    (state) => state.orderInsurance
  );

  const {
    newOrder: { clientInfo }
  } = useAppSelector((state) => state);

  const [productInsuranceInfo, setProductInsuranceInfo] =
    useState<TProductInsuranceInfo>(storeProductInfo);
  const [personalInsuranceInfo, setPersonalInsuranceInfo] =
    useState<TPersonInsuranceInfo>(storePersonalInfo);

  const [insurance, setInsurance] =
    useState<TInsuranceInfo>(storeInsuranceInfo);

  const [autoInsuranceInfo, setAutoInsuranceInfo] =
    useState<TAutoInsuranceInfo>(storeAutoInsuranceInfo);

  const [clientInsuranceInfo, setClientInsuranceInfo] =
    useState<TClientInsuranceInfo>(storeClientInsuranceInfo);

  const [files, setFiles] = useState<TFile[]>([]);

  const [brandModelYearValueHandler, setBrandModelYearValueHandler] =
    useState<string>('');
  const [saveMarkModelYearDisabled, setSaveMarkModelYearDisabled] =
    useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<TFocusState>(isFocusData);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [summInsurance, setSummInsurance] = useState<string>('');
  const [premiumInsurance, setPremiumInsurance] = useState<string>('');

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showInstallmentInput, setShowInstallmentInput] = useState(false);
  const [installmentValue, setInstallmentValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filesDownloaded, setFilesDownloaded] = useState(false); // Добавляем переменную состояния
  const [loadingFiles, setLoadingFiles] = useState(false); // Новое состояние для загрузки файлов
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const {
    possibleModels,
    handleModalSelectChange,
    modalSelectState,
    possibleMarks
  } = useMarkModel(autoInsuranceInfo);
  const [stateButton, setStateButton] = useState(true);
  const disabledFields = ['clientPhone', 'clientIin'];

  useEffect(() => {
    const isInsuranseValid = validatePersonalInsuranceInfo(
      personalInsuranceInfo
    );
    const idAutoValid = validateAutoInsuranceInfo(autoInsuranceInfo);
    if (isInsuranseValid && idAutoValid) {
      setStateButton(false);
    } else setStateButton(true);
  }, [
    personalInsuranceInfo,
    autoInsuranceInfo,
    productInsuranceInfo,
    autoInsuranceInfo
  ]);

  const getAttachedFilesById = async (insuranceId: string) => {
    setLoadingFiles(true); // Устанавливаем состояние загрузки файлов в true
    try {
      const attachedFiles: TFile[] = await apiConnector.findByInsuranceId(
        insuranceId
      );
      const uniqueFilesArray = attachedFiles.filter(
        (record, index, self) =>
          index === self.findIndex((file) => record.name === file.name)
      );
      setFiles(uniqueFilesArray);
      setFilesDownloaded(true); // Обновляем состояние только после успешной загрузки файлов
    } catch (error) {
      console.error('Ошибка при загрузке файлов:', error);
    } finally {
      setLoadingFiles(false); // Устанавливаем состояние загрузки файлов в false
    }
  };

  useEffect(() => {
    getAttachedFilesById(requestId);
    // SocketConnector.insuranceFilesUpdated = async (data) => {
    //   if (data.payload.insurance_RequestsUuid !== requestId) return;
    //   await getAttachedFilesById(data.payload.insurance_RequestsUuid);
    // };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const [showInstructionModal, setShowInstructionModal] =
    useState<boolean>(false);
  const [infoModalData, setInfoModalData] = useState<{
    label: string;
    info: string;
  }>({ label: '', info: '' });

  useEffect(() => {
    const isDisabled = !checkObjectValues(modalSelectState);
    setSaveMarkModelYearDisabled(isDisabled);
  }, [
    modalSelectState.markAuto,
    modalSelectState.modelAuto,
    modalSelectState.issueYear
  ]);

  useEffect(() => {
    setProductInsuranceInfo(storeProductInfo);
    setPersonalInsuranceInfo(storePersonalInfo);
    setAutoInsuranceInfo(storeAutoInsuranceInfo);
    setClientInsuranceInfo(storeClientInsuranceInfo);
    setInsurance(storeInsuranceInfo);
  }, [
    storeProductInfo,
    storePersonalInfo,
    storeAutoInsuranceInfo,
    storeClientInsuranceInfo,
    storeInsuranceInfo
  ]);

  const onChangeSelectHandler = (val: string, label: string, type: string) => {
    setProductInsuranceInfo((prevState) => ({
      ...prevState,
      [label]: val.toString()
    }));
    setPersonalInsuranceInfo((prevState) => ({
      ...prevState,
      [label]: val.toString()
    }));
    setAutoInsuranceInfo((prevState) => ({
      ...prevState,
      [label]: val.toString()
    }));
  };

  useEffect(() => {
    if (productInsuranceInfo.paymentType === '2') {
      setShowInstallmentInput(true);
    } else {
      setShowInstallmentInput(false);
    }
  }, [productInsuranceInfo.paymentType]);

  const modalSaveHandler = () => {
    setAutoInsuranceInfo({
      ...autoInsuranceInfo,
      markModelYear: {
        brand: modalSelectState.markAuto,
        model: modalSelectState.modelAuto,
        year: modalSelectState.issueYear
      }
    });
    setShowModal(false);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    val = validateNumberInputValue(val, 8, true);
    setPersonalInsuranceInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const handleInputChange = (value: string, key: string) => {
    setProductInsuranceInfo((prevInfo) => ({ ...prevInfo, [key]: value }));
  };

  const handlePersonalInputChange = (value: string, key: string) => {
    // setPersonalInsuranceInfo((prevInfo) => {
    let transformedValue = value;

    if (key === 'clientPhone' || key === 'clientIin') {
      return;
    }

    if (key === 'firstName' || key === 'lastName' || key === 'middleName') {
      const capitalize = (str: string) => {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };
      transformedValue = capitalize(value);
    } else if (key === 'numberdocument') {
      if (value.length > 9) {
        return;
      } else {
        transformedValue = transformedValue.replace(/\D/g, '');
      }
    }
    setPersonalInsuranceInfo((prevInfo) => ({
      ...prevInfo,
      [key]: transformedValue
    }));
  };

  const handleAutoInputChange = (value: string, key: string) => {
    let autotransformValue = value;

    if (key === 'tehNum') {
      autotransformValue = value.toUpperCase();

      if (autotransformValue.length > 10) {
        return;
      }
    } else if (key === 'vinCode') {
      autotransformValue = value.toUpperCase();

      if (autotransformValue.length > 17) {
        return;
      }
    } else if (key === 'numberGos') {
      autotransformValue = value.toUpperCase();
      if (autotransformValue.length > 8) {
        return;
      }
    }

    setAutoInsuranceInfo((prevInfo) => ({
      ...prevInfo,
      [key]: autotransformValue
    }));
  };

  const handleInsuranceInputChange = (value: string, key: string) => {
    if (key === 'insuranseSumm') {
      if (Number(autoInsuranceInfo.priseAvto) < Number(value)) {
        return;
      }
    }
    setInsurance((prevInfo) => ({ ...prevInfo, [key]: value }));
  };

  useEffect(() => {
    const currentDate = dayjs();
    const nextDay = currentDate.add(1, 'day');
    //@ts-ignore
    setProductInsuranceInfo((prevState) => ({
      ...prevState,
      dateForm: currentDate,
      datepolis: nextDay
    }));
  }, []);

  useEffect(() => {
    if (productInsuranceInfo?.datepolis) {
      const updatedDate = dayjs(productInsuranceInfo?.datepolis).add(
        dayjs.duration({ days: -1, years: 1 })
      );
      //@ts-ignore
      setProductInsuranceInfo((prevState) => ({
        ...prevState,
        dateEndpolis: updatedDate
      }));
    }
  }, [productInsuranceInfo.datepolis, productInsuranceInfo.dateForm]);

  const calculatePremiumInsuranceValues = (priseAvto: number) => {
    const insuranseSummValue = priseAvto.toString();
    handleInsuranceInputChange(insuranseSummValue, 'insuranseSumm');

    const insuransePremiumValue = Math.round(priseAvto * 0.035).toString();
    handleInsuranceInputChange(insuransePremiumValue, 'insuransePremium');
  };

  const calculateAutoServiceInsuranceValues = (priseAvto: number) => {
    const insuranseSummValue = Math.round(priseAvto * 0.8).toString();
    handleInsuranceInputChange(insuranseSummValue, 'insuranseSumm');

    const insuransePremiumValue = Math.round(
      Number(insuranseSummValue) * 0.02
    ).toString();
    handleInsuranceInputChange(insuransePremiumValue, 'insuransePremium');
  };

  useEffect(() => {
    if (!autoInsuranceInfo.priseAvto) return;

    const priseAvto = Number(autoInsuranceInfo.priseAvto);

    if (productInsuranceInfo.productType == '78') {
      calculatePremiumInsuranceValues(priseAvto);
    } else if (productInsuranceInfo.productType == '79') {
      calculateAutoServiceInsuranceValues(priseAvto);
    }
  }, [autoInsuranceInfo.priseAvto]);

  const onChangeCheckedHandler = (
    val: boolean,
    label: string,
    type: string
  ) => {
    //setProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const onChangeDatePickerHandler = (
    val: any,
    label: string,
    keyType: string
  ) => {
    switch (keyType) {
      case 'personal':
        setPersonalInsuranceInfo((prevState) => ({
          ...prevState,
          [label]: val
        }));
        break;
      case 'product':
        setProductInsuranceInfo((prevState) => ({
          ...prevState,
          [label]: val
        }));
        break;
      case 'modal':
        setAutoInsuranceInfo((prevState) => ({ ...prevState, [label]: val }));
        break;
      default:
        break;
    }
  };

  const onContinueHandler = async () => {
    setLoading(true);
    const newInsuranceDto: TNewInsuranceDto = {
      createDate: new Date().toISOString(),
      uuid: uuid || requestId,
      status: 'draft',
      otp: otpCode || '',
      otpDate: new Date().toISOString(),
      code: productInsuranceInfo?.id?.toString() || '',
      productInsuranceInfo: {
        product: {
          ...productInsuranceInfo,
          productTypeDate: productInsuranceInfo?.productTypeDate
            ? Number(productInsuranceInfo?.productTypeDate)
            : null,
          productType: productInsuranceInfo?.productType,
          paymentType: Number(productInsuranceInfo?.paymentType),
          dateForm: productInsuranceInfo.dateForm,
          payment: Number(productInsuranceInfo?.payment),
          datepolis: productInsuranceInfo.datepolis,
          dateEndpolis: productInsuranceInfo.dateEndpolis
        }
      },
      personalInsuranceInfo: {
        adress: personalInsuranceInfo?.adress,
        docType: Number(personalInsuranceInfo?.docType),
        issuebdy: Number(personalInsuranceInfo?.issuebdy),
        resident: Number(personalInsuranceInfo?.resident),

        numberdocument: personalInsuranceInfo?.numberdocument,
        dateDocument: personalInsuranceInfo.dateDocument,
        sex: Number(personalInsuranceInfo?.sex),
        placeBirth: personalInsuranceInfo?.placeBirth,
        validity: personalInsuranceInfo.validity,
        dateBirth: personalInsuranceInfo.dateBirth,
        firstName: personalInsuranceInfo?.firstName,
        // middleName: personalInsuranceInfo?.middleName,
        lastName: personalInsuranceInfo?.lastName,
        clientIin: personalInsuranceInfo?.clientIin,
        clientPhone: cleanPhoneNumber(personalInsuranceInfo?.clientPhone),
        ...(personalInsuranceInfo?.middleName &&
          personalInsuranceInfo?.email && {
            middleName: personalInsuranceInfo?.middleName || '',
            email: personalInsuranceInfo?.email || ''
          })
      },
      autoInsuranceInfo: {
        dealer: autoInsuranceInfo?.dealer,
        priseAvto: autoInsuranceInfo?.priseAvto,
        markModelYear: {
          model: Number(autoInsuranceInfo?.markModelYear?.model),
          brand: Number(autoInsuranceInfo?.markModelYear?.brand),
          year:
            autoInsuranceInfo?.markModelYear?.year ||
            new Date().getFullYear().toString()
        },
        tehNum: autoInsuranceInfo?.tehNum,
        dateOfTechPasspord: autoInsuranceInfo.dateOfTechPasspord,
        numberGos: autoInsuranceInfo?.numberGos,
        vinCode: autoInsuranceInfo?.vinCode
      },
      insurance: {
        insuranseSumm: insurance?.insuranseSumm,
        insuransePremium: insurance?.insuransePremium
      },
      clientInsuranceInfo: {
        innInsurance: clientInsuranceInfo?.innInsurance,
        numberInsurance: clientInsuranceInfo?.numberInsurance
      }
    };
    try {
      const tst = await dispatch(createInsuranceOrder(newInsuranceDto)).then(
        async () => {
          await getAttachedFilesById(requestId); // Загружаем файлы после создания заявки)
          setIsButtonVisible(false);
        }
      );

      // Показать уведомление
      notification.success({
        message: 'Успех',
        description: 'Заявка успешно создана',
        placement: 'topRight'
      });
    } catch (error) {
      // Обработка ошибок
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось создать заявку. Попробуйте снова.',
        placement: 'topRight'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientInsuranceInfoStore) {
      setPersonalInsuranceInfo((prevState) => ({
        ...prevState,
        clientIin: clientInsuranceInfoStore.iin as string,
        clientPhone: clientInsuranceInfoStore.clientPhone as string
      }));
    }
  }, [clientInsuranceInfoStore]);

  useEffect(() => {
    if (dealer) {
      setAutoInsuranceInfo((prevState) => ({
        ...prevState,
        dealer: dealer.code as string,
        productType: '1',
        clientInfo: clientInfo?.iin as string,
        userInfo: dealer.code as string
      }));
    }
  }, [dealer, clientInfo, setAutoInsuranceInfo]);

  useEffect(() => {
    const { brand, model, year } = autoInsuranceInfo.markModelYear;
    const brandName = dicts
      ?.find((dict: { dictType: string }) => dict.dictType === 'Brands')
      ?.content.find((item) => item.code === parseInt(brand as string))?.name;
    const modelName = dicts
      ?.find((dict) => dict.dictType === 'Models')
      ?.content.find((item) => item.code === parseInt(model as string))?.name;
    const brandValue = brandName ? brandName : '-';
    const modelValue = modelName ? modelName : '-';
    const yearValue = year ? year : '-';
    const val = `${brandValue}, ${modelValue}, ${yearValue}`;
    setBrandModelYearValueHandler(val);
  }, [autoInsuranceInfo, dicts]);

  return (
    <div className="Insurance_Step_Order">
      <div className="product_info">
        <div className="auto_selects">
          <h2 style={{ marginLeft: '20px' }}>
            Информация о продукте и данные об авто
          </h2>

          <div className="selects_box">
            {productInfoItemsInsurance.map((item, index) => {
              if (item.type === 'input') {
                return (
                  <CustomInput
                    key={item.key + index}
                    value={productInsuranceInfo[item.key] as string}
                    label={item.key}
                    type={'product'}
                    labelTitle={item.label}
                    handleInputChange={handleInputChange}
                    //fieldsReadOnly={fieldsReadOnly}
                  />
                );
              } else if (item.type === 'select') {
                if (item.key !== 'productTypeDate') {
                  return (
                    <>
                      <CustomSelect
                        key={item.key + index}
                        isFocused={isFocused[item.key]}
                        value={productInsuranceInfo[item.key] as string}
                        option={setDictionaryOptions(
                          item.snakeCaseValues as DictTypes,
                          dicts
                        )}
                        label={item.key}
                        type={'product'}
                        labelTitle={item.label}
                        onChangeSelectHandler={onChangeSelectHandler}
                        setShowModal={item.editable ? setShowModal : undefined}
                        keyType="product"
                      />
                    </>
                  );
                } else if (
                  item.key === 'productTypeDate' &&
                  showInstallmentInput
                )
                  return (
                    <>
                      {showInstallmentInput && (
                        <CustomSelect
                          type="text"
                          label={item.key}
                          isFocused={isFocused[item.key]}
                          value={productInsuranceInfo[item.key] as string}
                          labelTitle={item.label}
                          option={setDictionaryOptions(
                            item.snakeCaseValues as DictTypes,
                            dicts
                          )}
                          onChangeSelectHandler={onChangeSelectHandler}
                          keyType="product"
                        />
                      )}
                    </>
                  );
              } else if (item.type === 'switch') {
                return (
                  <CustomSwitch
                    key={item.key + index}
                    value={productInsuranceInfo[item.key] as string}
                    label={item.key}
                    type={'product'}
                    labelTitle={item.label}
                    onchangeCheckedHandler={onChangeCheckedHandler}
                    fieldsReadOnly={true}
                  />
                );
              } else if (item.type === 'datepicker') {
                return (
                  <CustomDatePicker
                    key={item.key + index}
                    isFocused={isFocused[item.key]}
                    value={
                      productInsuranceInfo[item.key]
                        ? dayjs(productInsuranceInfo[item.key] as Date)
                        : null
                    }
                    label={item.key}
                    type={'product'}
                    labelTitle={item.label}
                    onChangeDatePickerHandler={onChangeDatePickerHandler}
                    keyType="product"
                  />
                );
              }
            })}
          </div>
          <div className="first_step">
            <CustomModal
              editClass="markModelYear"
              title="Марка, модель и год выпуска"
              isModalOpen={showModal}
              handleOk={modalSaveHandler}
              handleCancel={() => setShowModal(false)}
              footer={[
                <Button key="back" onClick={() => setShowModal(false)}>
                  Отмена
                </Button>,
                <Button
                  disabled={saveMarkModelYearDisabled}
                  key="submit"
                  type="primary"
                  onClick={modalSaveHandler}
                >
                  Сохранить
                </Button>
              ]}
            >
              <div className="auto_selects">
                <Select
                  showSearch
                  optionFilterProp={'label'}
                  value={modalSelectState.markAuto}
                  placeholder="Марка авто"
                  options={possibleMarks}
                  onChange={(val) => handleModalSelectChange(val, 'markAuto')}
                />
                <Select
                  showSearch
                  optionFilterProp={'label'}
                  value={modalSelectState.modelAuto}
                  placeholder="Модель авто"
                  options={possibleModels}
                  onChange={(val) => handleModalSelectChange(val, 'modelAuto')}
                />
                <Input
                  value={modalSelectState.issueYear || new Date().getFullYear()}
                  placeholder="Год выпуска"
                  onChange={(e) =>
                    handleModalSelectChange(e.target.value, 'issueYear')
                  }
                />
              </div>
            </CustomModal>

            <div className="selects_box">
              {modalTSItemsInsuramce.map((item, index) => {
                if (item.type === 'input') {
                  return (
                    <CustomInput
                      key={item.key}
                      isFocused={isFocused[item.key]}
                      value={autoInsuranceInfo[item.key] as string}
                      label={item.key}
                      type={'modal'}
                      labelTitle={item.label}
                      handleAutoInputChange={handleAutoInputChange}
                    />
                  );
                } else if (item.type === 'select') {
                  return (
                    <CustomSelect
                      key={item.key}
                      isFocused={isFocused[item.key]}
                      value={
                        item.editable
                          ? brandModelYearValueHandler
                          : (autoInsuranceInfo[item.key] as string)
                      }
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.key}
                      type={'modal'}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeSelectHandler}
                      setShowModal={item.editable ? setShowModal : undefined}
                      keyType="modal"
                      fieldsReadOnly={item.key === 'dealer'}
                    />
                  );
                } else if (item.type === 'datepicker') {
                  return (
                    <CustomDatePicker
                      key={item.key}
                      isFocused={isFocused[item.key]}
                      value={
                        autoInsuranceInfo[item.key]
                          ? dayjs(autoInsuranceInfo[item.key] as Date)
                          : null
                      }
                      label={item.key}
                      type={'modal'}
                      labelTitle={item.label}
                      onChangeDatePickerHandler={onChangeDatePickerHandler}
                      keyType="modal"
                    />
                  );
                }
              })}
              <input
                style={{
                  borderRadius: '5px',
                  border: '1px solid #111827',
                  borderColor: '#c4c4c4',
                  height: '55px',
                  transition: 'border-color 0.1s ease'
                }}
                className="inputContainer"
                type="text"
                value={brandModelYearValueHandler}
                onClick={openModal}
                placeholder="Марка модель и год выпуска"
              />
            </div>
          </div>

          <h2 style={{ marginTop: '40px', marginLeft: '20px' }}>
            Данные о клиенте
          </h2>
          <div className="personal_info">
            {personalClientInsurance.map((item, index) => {
              if (item.type === 'select') {
                return (
                  <CustomSelect
                    key={item.key + index}
                    isFocused={isFocused[item.key]}
                    value={personalInsuranceInfo[item.key] as string}
                    option={setDictionaryOptions(
                      item.snakeCaseValues as DictTypes,
                      dicts
                    )}
                    label={item.key}
                    type={'personal'}
                    labelTitle={item.label}
                    onChangeSelectHandler={onChangeSelectHandler}
                    keyType="personal"
                    //fieldsReadOnly={fieldsReadOnly}
                  />
                );
              } else if (
                item.type === 'input' &&
                item.key !== 'workTelNumber'
              ) {
                return (
                  // item.key !== 'jobAddress' &&
                  // item.key !== 'workTelNumber' &&
                  item.key !== 'personalAddress' &&
                  item.key !== 'residentAddress' && (
                    <CustomInput
                      key={item.key}
                      isFocused={isFocused[item.key]}
                      value={personalInsuranceInfo[item.key] as string}
                      label={item.key}
                      type={'personal'}
                      labelTitle={item.label}
                      handlePersonalInputChange={handlePersonalInputChange}
                      fieldsReadOnly={item.key === 'clientIin'}
                      // readOnly={item.key === 'clientPhone' || item.key === 'clientIin'}
                      // disabled={item.key === 'clientPhone' || item.key === 'clientIin'}
                    />
                  )
                );
              } else if (item.type === 'datepicker') {
                return (
                  <CustomDatePicker
                    key={item.key}
                    isFocused={isFocused[item.key]}
                    value={
                      personalInsuranceInfo[item.key]
                        ? dayjs(personalInsuranceInfo[item.key] as Date)
                        : null
                    }
                    label={item.key}
                    type={'personal'}
                    labelTitle={item.label}
                    onChangeDatePickerHandler={onChangeDatePickerHandler}
                    keyType="personal"
                    //fieldsReadOnly={fieldsReadOnly}
                  />
                );
              } else if (item.type === 'phoneNumber') {
                return (
                  <CustomPhoneInput
                    key={item.key}
                    value={personalInsuranceInfo[item.key] as string}
                    label={item.key}
                    type={'personal'}
                    onChangeInputHandler={onChangeInputHandler}
                    readOnly={item.key === 'clientPhone'}
                    // fieldsReadOnly={fieldsReadOnly}
                  />
                );
              }
            })}
          </div>
          <div>
            {loading ? (
              <Spin
                size="large"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh' // Если хотите центрировать по вертикали
                }}
              />
            ) : (
              isButtonVisible && (
                <Button
                  style={{ height: '50px' }}
                  className="continue_btn"
                  onClick={onContinueHandler}
                  disabled={stateButton}
                >
                  Создать заявку
                </Button>
              )
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="status_content_box">
        <div className="info">
          <div className="status_text">
            {productInsuranceInfo.status === 'waiting_bank_response' ? (
              <p className="request_waiting">Полис ожидает оплаты</p>
            ) : null}
            {productInsuranceInfo.status === 'waiting_of_payment' ? (
              <p className="request_waiting">Полис ожидает оплаты</p>
            ) : null}
            {productInsuranceInfo.status === 'error' ? (
              <p className="request_canceled">Ошибка в процессе</p>
            ) : null}
            {productInsuranceInfo.status === 'completed' ? (
              <p className="request_financed">Полис выдан</p>
            ) : null}
            {productInsuranceInfo.status === 'financed ' ? (
              <p className="request_canceled">Полис выдан</p>
            ) : null}
            {productInsuranceInfo.status === 'SMS верификаця' ? (
              <p className="request_financed">SMS верификация</p>
            ) : null}
            {productInsuranceInfo.status === 'draft' ? (
              <p className="request_draft">Создан</p>
            ) : null}
          </div>
          <div className="info-text">
            <div className="insurance_values" style={{ marginTop: '20px' }}>
              <p>
                Сумма страхования:{' '}
                <p style={{ fontSize: '13px' }}>
                  {' '}
                  {insurance.insuranseSumm ? (
                    <strong>{insurance.insuranseSumm}</strong>
                  ) : (
                    'Нет данных'
                  )}
                </p>
              </p>
            </div>
            <div className="insurance_values" style={{ marginTop: '20px' }}>
              <p>
                Премия страхования:{' '}
                <p style={{ fontSize: '13px' }}>
                  {' '}
                  {insurance.insuransePremium ? (
                    <strong>{insurance.insuransePremium}</strong>
                  ) : (
                    'Нет данных'
                  )}
                </p>
              </p>
            </div>
          </div>
          <div className="btn-container">
            {loading ? (
              <div className="loader-container">
                <div className="loader-container">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                </div>
              </div>
            ) : (
              <Button
                className="load-btn"
                icon={<ReloadOutlined />}
                style={{
                  color: 'white',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row-reverse',
                  padding: '15px',
                  border: 'none',
                  borderRadius: '8px',
                  height: '40px',
                  textAlign: 'right',
                  fontSize: '13px',
                  fontStyle: 'normal'
                }}
                onClick={() => getAttachedFilesById(requestId)}
              >
                <span style={{ marginRight: '10px' }}>Запросить полис</span>
                <span
                  style={{
                    marginRight: '10px',
                    marginBottom: '5px'
                  }}
                ></span>
              </Button>
            )}
          </div>
          <>
            {files.length > 0 &&
              files.map((file) => (
                <FileDownload
                  key={file.uuid}
                  fileLink={file.uuid}
                  prevStateTitle={
                    file.name.includes('application')
                      ? 'Анкета'
                      : file.name.includes('policy')
                      ? 'Полис'
                      : ''
                  }
                />
              ))}
          </>
        </div>
      </div>
    </div>
  );
};
