import { TUploadFiles } from '../types/uploadFiles';

export const uploadFilesData: TUploadFiles = {
  noticeActivity: null,
  docScanSocialStatus: null,
  clientPhoto: null,
  docPhoto: null,
  incomeCert: null,
  otherDoc: null
  // docPhotoBack: null,
  // docPhotoFront: null
};
