import React from 'react';
import '@/styles/BankSubPrintItem.scss';
import { TBankOffers } from '@/types/offers';
import CustomCheckbox from '@/components/Shared/Checkbox/CustomCheckbox';

export type TBankSubPrintItemProps = {
  bank: TBankOffers;
  onChangePrint: (bankId: string) => void;
};
export const BankSubPrintItem = ({
  bank,
  onChangePrint
}: TBankSubPrintItemProps) => {
  return (
    <div className="BankSubPrintItem">
      <p>{bank.name} alternative</p>
      <CustomCheckbox
        checked={true}
        onChange={() => {
          onChangePrint(bank.id);
        }}
        editClass="SubPrint_checkbox"
      />
    </div>
  );
};
