import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '@/styles/Layout.scss';
import { useAppDispatch, useAppSelector } from '@/store';
import { setDealer } from '@/store/userInfo.slice';
import SelectDealerModal from '@/components/App/SelectDealer/SelectDealerModal';
import { Header } from '@/components/Layout/Header/Header';
import { Navbar } from '@/components/Layout/Navbar/Navbar';

export const Layout = () => {
  const location = useLocation();
  const dealers = useAppSelector((state) => state.dict.activeDealersOption);
  const { isUserAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [showSelectDealerModal, setSelectDealerModal] = useState<boolean>(true);

  useEffect(() => {
    const dealerCode = localStorage.getItem('dealer');

    if (dealerCode) {
      const selectedDealer:
        | null
        | {
            value: string;
            label: string;
            code: string;
          }
        | undefined =
        dealers &&
        dealers.find((_dealer) => _dealer.code.toString() == dealerCode);
      setSelectDealerModal(false);

      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
  }, [dealers]);

  const showSelectDealerModalToggle = () => {
    setSelectDealerModal(!showSelectDealerModal);
  };

  return (
    <div className="Layout">
      {isUserAuth && showSelectDealerModal && (
        <SelectDealerModal show={showSelectDealerModalToggle} />
      )}
      <Navbar />
      <div className="wrapper">
        <Header />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};
