import { FC, useEffect, useState } from 'react';
import '@/styles/BerekeBank.scss';
import { TFileAttached } from '@/types/financing';
import { useAppDispatch } from '@/store';
import { berekeItems } from '@/data/financing.data';
import { onSetUploadedFiles } from '@/store/financingStep.slice';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import { checkArrayValues } from '@/services/validators/checkArrayValues';

type TBerekeBankProps = {
  infoTS: boolean;
  // changeBerekeValidStatus: (isValid: boolean) => void;
  attachedFiles: TFileAttached[];
  isOfferFinanced: boolean;
  handleSendDocs: (uploadedFiles: any) => void;
};

export const BerekeBank: FC<TBerekeBankProps> = ({
  infoTS,
  handleSendDocs,
  attachedFiles
}: // changeBerekeValidStatus
TBerekeBankProps) => {
  const [uploadFiles, setUploadFiles] = useState({});
  const [berekeLetterOfGuarantee, setBerekeLetterOfGuarantee] =
    useState<string>('');
  const dispatch = useAppDispatch();
  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({ ...prevState, [type]: fileBase64 }));
  };

  useEffect(() => {
    const isValid =
      Object.values(uploadFiles).length === berekeItems.length
        ? checkArrayValues(Object.values(uploadFiles))
        : false;
    isValid && dispatch(onSetUploadedFiles(uploadFiles));
    // changeBerekeValidStatus(isValid);
  }, [uploadFiles]);

  useEffect(() => {
    const guaranteeLetterLink = attachedFiles.find(
      // Тип файла
      (file) => file.type === 'Гарантийное письмо.pdf'
    )?.link;
    guaranteeLetterLink && setBerekeLetterOfGuarantee(guaranteeLetterLink);
  }, [attachedFiles]);

  return (
    <div className="berekeBank">
      <FileDownload prevStateTitle={'Письмо в Автосалон'} fileLink={'#'} />

      {infoTS && (
        <div className="cols">
          <div className="col">
            <FileDownload
              prevStateTitle={'Гарантийное письмо'}
              fileLink={berekeLetterOfGuarantee}
            />
            {/*{berekeItems.map((item) => (*/}
            {/*  <FileDownload*/}
            {/*    key={item.key}*/}
            {/*    prevStateTitle={item.label}*/}
            {/*    fileLink={'#'}*/}
            {/*  />*/}
            {/*))}*/}
          </div>
          <div className="col">
            <FileUpload
              onFileChange={handleFileChange}
              title={'Согласие супруга(и) / Отсутствие супруга(и)'}
              type={'spouseConsent'}
              preventActions={false}
            />
            {/*{berekeItems.map((item) => (*/}
            {/*  <FileUpload*/}
            {/*    key={item.key}*/}
            {/*    customeStyles={{ margin: '15px 0' }}*/}
            {/*    positionLeft={'50px'}*/}
            {/*    marginLeft={'35px'}*/}
            {/*    selectedmargin={'10%'}*/}
            {/*    onFileChange={handleFileChange}*/}
            {/*    title={item.label}*/}
            {/*    type={item.key}*/}
            {/*    preventActions={false}*/}
            {/*  />*/}
            {/*))}*/}
          </div>
        </div>
      )}
    </div>
  );
};
