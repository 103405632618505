import '@/styles/TemplateBlock.scss';
import { Button } from 'antd';
import { TTemplate } from '@/types/templates';
import { pathFileText } from '@/constants';
import { useLocation } from 'react-router-dom';

type TTemplateBlockProps = {
  template: TTemplate;
  handleRedactTemplate: (template: TTemplate) => void;
  handleRedactTemplateValues: (template: TTemplate) => void;
  handleDownloadTemplate: () => void;
};
const TemplateBlock = ({
  template,
  handleRedactTemplate,
  handleRedactTemplateValues,
  handleDownloadTemplate
}: TTemplateBlockProps) => {
 
  const location = useLocation();
  return (
    <div className="TemplateBlock">
      <div className="icon_wrapper">
        <img src={pathFileText} alt="text_editor" />
      </div>
      <h2>{template.name}</h2>
      <p>Автокредитование</p>
      {location.pathname.includes('adminPage') && (
        <>
          <Button
            onClick={() => {
              handleRedactTemplateValues(template);
            }}
          >
            Редактировать значения полей
          </Button>
          <Button
            onClick={() => {
              handleRedactTemplate(template);
            }}
          >
            Редактировать
          </Button>{' '}
        </>
      )}
      <Button onClick={handleDownloadTemplate}>Скачать</Button>
    </div>
  );
};

export default TemplateBlock;
