import { apiConnector } from '@/integrations/api.connector';
import { formatPhoneNumber } from '@/services/formatter';
import { TPostCredStore } from '@/types/store';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const postcredGenerateOTP = createAsyncThunk(
  'postcredGenerateOTP',
  async (clientData: { iin: string; fullName: string; telNum: string }) => {
    try {
      return await apiConnector.generatePostcredOTP(clientData);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TPostCredStore = {
  clientInfo: {
    fullName: '',
    iin: '',
    telNum: ''
  },
  postcredReq: null,
  step: 'authorization',
  isLoading: false
};

export const postCredSlice = createSlice({
  name: 'postcred',
  initialState,
  reducers: {
    setAuthorizationStep: (
      state,
      action: PayloadAction<'authorization' | 'otp_validation'>
    ) => {
      state.step = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postcredGenerateOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postcredGenerateOTP.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        postcredGenerateOTP.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.clientInfo = {
            fullName: `${action?.payload?.client?.last_name || ''} ${
              action?.payload?.client?.first_name || ''
            }${action?.payload?.client?.middle_name || ''}`,
            iin: action?.payload?.client?.client_iin || '',
            telNum: formatPhoneNumber(
              action?.payload?.client?.phone_number || ''
            )
          };
          state.step = 'otp_validation';
        }
      );
  }
});

export const { setAuthorizationStep } = postCredSlice.actions;
