import {
  TRequestForChart,
  TRequestForChartServerResponse
} from '@/types/request';

export const requestsForChartMapper = (
  serverResponse: TRequestForChartServerResponse[]
): TRequestForChart[] => {
  const formattedRequests = serverResponse.map((req) => {
    const transformed: TRequestForChart = {
      uuid: req.uuid,
      create_date: req.create_date,
      dealer: req.dealer.name
    };
    return transformed;
  });
  return formattedRequests;
};
