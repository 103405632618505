import React, { useEffect, useState } from 'react';
import CalculatorForm from './CalculatorForm';
import '@/styles/Calculator.scss';
import CalculationResultsItem from './CalculationResultItem/CalculationResultsItem';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { CALCULATION_TYPES } from '@/constants';
import {
  TCalculationSendBody,
  TCalculatorProductInfo,
  TProductInfo
} from '@/types/productInfo';
import { TClientDataState } from '@/types/state';
import { formatPhoneNumber } from '@/services/formatter';
import { apiConnector } from '@/integrations/api.connector';
import { setCurrent, setNewOrderToInitial } from '@/store/newOrder.slice';
import { createDraftFromCalc, onSetProductInfo } from '@/store/orderStep.slice';
import { checkObjectValues } from '@/services/validators/checkObjectValues';

const Calculator = () => {
  const {
    newOrder: { clientInfo },
    orderStep: { clientCalcId },
    dict: { additionalProductsDict }
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();
  const dealer = useAppSelector((state) => state.userInfo.dealer);
  const navigate = useNavigate();

  const [calcType, setCalcType] = useState<CALCULATION_TYPES>(
    CALCULATION_TYPES.STANDART_ANUETET
  );
  const [errorFields, setErrorFields] = useState<{
    dealer: boolean;
    markModelYear: boolean;
    costCar: boolean;
    initFee: boolean;
    loanTerm: boolean;
  }>({
    dealer: false,
    costCar: false,
    initFee: false,
    loanTerm: false,
    markModelYear: false
  });

  const [calculatorValues, setCalculatorValues] =
    useState<TCalculatorProductInfo>({
      dealer: null,
      costCar: '',
      initFee: '',
      autoType: 1,
      loanTerm: null,
      markModelYear: {
        brand: null,
        model: null,
        year: null
      },
      proofInCome: false
    });
  const [calculatorFormValid, setCalculatorFormValid] =
    useState<boolean>(false);
  const [clientData, setClientData] = useState<TClientDataState>({
    firstName: clientInfo?.fullName[1] || '',
    middleName: clientInfo?.fullName[2] || '',
    lastName: clientInfo?.fullName[0] || '',
    iin: clientInfo?.iin || '',
    telNum: formatPhoneNumber(clientInfo?.telNum || ''),
    id: clientInfo?.id || '',
    errorTitle: '',
    comments: ''
  });

  const [calculationResults, setCalculationResults] = useState<{
    rate: number;
    kaskoPerYear: number;
    loanAmount: number;
    totalLoanAmount: number;
    monthlyPaymentAnnuity: number;
    debtBalanceAnnuity: number;
    loanOverpaymentAnnuity: number;
    monthlyPaymentDiffArray: { month: number; amount: number }[];
    loanOverpaymentDiff: number;
  } | null>(null);

  const handleGetCalculations = async () => {
    const {
      dealer,
      proofInCome,
      loanTerm,
      initFee,
      costCar,
      autoType,
      markModelYear
    } = calculatorValues;
    try {
      const objectToSend: TCalculationSendBody = {
        dealer: Number(dealer),
        proofInCome: proofInCome,
        loanTerm: Number(loanTerm),
        initFee: Number(initFee.replace(/\s/g, '')),
        costCar: Number(costCar.replace(/\s/g, '')),
        autoType: Number(autoType),
        markModelYear: markModelYear,
        client_calculation_uuid: clientCalcId
      };
      const data = await apiConnector.getApproxLoanAmount(objectToSend);
      setCalculationResults(data);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  useEffect(() => {
    setCalculatorValues((prevState) => {
      return {
        ...prevState,
        dealer: Number(dealer?.code)
      };
    });
  }, [dealer]);

  useEffect(() => {
    const dataToCheck = { ...calculatorValues };
    delete dataToCheck.proofInCome;
    const isValid = checkObjectValues(dataToCheck);
    setCalculatorFormValid(isValid);
    let iMarkModelEmpty = false;
    Object.values(calculatorValues.markModelYear).forEach((val) => {
      if (!val) iMarkModelEmpty = true;
    });
    setErrorFields((prevState) => ({
      ...prevState,
      markModelYear: iMarkModelEmpty
    }));
  }, [calculatorValues]);

  const createNewRequest = async () => {
    dispatch(setNewOrderToInitial());
    await dispatch(createDraftFromCalc(clientCalcId));
    const productInfo: TProductInfo = {
      loanTerm: calculatorValues.loanTerm,
      carYear: calculatorValues.markModelYear.year?.toString() || '',
      proofInCome: calculatorValues.proofInCome || false,
      autoType: calculatorValues.autoType,
      initFee: calculatorValues.initFee,
      markModelYear: {
        brand: calculatorValues.markModelYear.brand,
        model: calculatorValues.markModelYear.model,
        year: calculatorValues.markModelYear.year?.toString() || ''
      },
      costCar: calculatorValues.costCar,
      dealer: calculatorValues.dealer,
      // autoColor: '',
      engineType: '',
      // paymentDate: '20',
      paymentScheme: '',
      stocksBonds:
        additionalProductsDict?.map((elem) => {
          return {
            id: elem.uuid,
            value: elem.bank,
            label: elem.name,
            checked: elem.name !== 'СМС-информирование',
            info: elem.description,
            code: elem.code,
            grace: elem.grace,
            bank_name: elem.bank_name,
            selected_insurance_uuid: null,
            insurance_uuid: null,
            loan_period: elem?.loan_period?.toString() || '12',
            loan_amount: elem.loan_amount,
            partner_code: elem.partner_code,
            product_code: elem.product_code
          };
        }) || []
    };
    dispatch(onSetProductInfo(productInfo));
    dispatch(setCurrent(0));
    navigate('/newApp');
  };

  return (
    <div className="Calculator">
      <div className="container">
        <div className="client_info">
          <div>
            <h2 className="editable">
              {`${clientData.lastName} ${clientData.firstName} ${clientData.middleName}`}
            </h2>
            <p>ИИН: {clientData.iin}</p>
          </div>
          <div>
            <h2>{clientData.telNum}</h2>
            <p>Номер телефона</p>
          </div>
        </div>

        <div className="content">
          <div className="product_info">
            <CalculatorForm
              errorFields={errorFields}
              isDataSend={!!calculationResults}
              isFormValid={calculatorFormValid}
              handleGetCalculations={handleGetCalculations}
              calculatorProductInfo={calculatorValues}
              setCalculatorProductInfo={setCalculatorValues}
              selectedDealerPoint={
                calculatorValues?.dealer
                  ? (calculatorValues.dealer as unknown as string)
                  : ''
              }
            />
          </div>
          <div className="results_container">
            <CalculationResultsItem
              setCalcType={setCalcType}
              calcType={calcType}
              calcVariants={Object.values(CALCULATION_TYPES)}
              createNewRequest={createNewRequest}
              calculationData={calculationResults}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
