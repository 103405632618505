import { useCallback, useEffect, useState } from 'react';
import { TModalSelectState } from '../types/state';
import {
  findDictByTitle,
  setCustomOption,
  validateNumberInputValue
} from '../services/helpers';
import { DictTypes } from '../constants';
import { TDict } from '../types/store';
import { useAppSelector } from '../store';
import { TCalculatorProductInfo, TProductInfo } from '../types/productInfo';
import dayjs from 'dayjs';
import { TAutoInsuranceInfo } from '@/types/avtoInsuranceInfo';

const useMarkModel = (
  productInfo: TProductInfo | TCalculatorProductInfo | TAutoInsuranceInfo
) => {
  const { dicts } = useAppSelector((state) => state.dict);

  const handleModalSelectChange = useCallback((value: string, type: string) => {
    if (type === 'issueYear') {
      value = validateNumberInputValue(value, 4);
      setModalSelectState((prevState) => ({ ...prevState, [type]: value }));
    } else {
      setModalSelectState((prevState) => ({ ...prevState, [type]: value }));
    }
  }, []);

  const [modalSelectState, setModalSelectState] = useState<TModalSelectState>({
    markAuto: productInfo?.markModelYear?.brand?.toString() || null,
    modelAuto: productInfo?.markModelYear?.model?.toString() || null,
    issueYear:
      productInfo?.markModelYear?.year?.toString() ||
      dayjs(new Date()).year().toString()
  });

  const [possibleMarks, setPossibleMarks] = useState<
    { value: string; label: string }[]
  >([]);

  const [possibleModels, setPossibleModels] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    setModalSelectState((prevState) => ({ ...prevState, markAuto: null }));
    if (productInfo.dealer) {
      const dealerArray = findDictByTitle(DictTypes.Dealers, dicts as TDict[]);
      const dealer = dealerArray.content.find(
        (content) => content.code === parseInt(productInfo.dealer as string)
      );
      const possibleMarksHandler = [
        ...findDictByTitle(DictTypes.Brands, dicts as TDict[]).content
      ].filter((brand) => {
        if (dealer?.name.includes('Hongqi')) {
          return (
            brand.uuid === dealer?.sold_brands ||
            brand.name.toLowerCase() === 'faw'
          );
        } else {
          if (dealer?.sold_brands) {
            return brand.uuid === dealer?.sold_brands;
          } else {
            return brand;
          }
        }
      });

      const optionsHandler = setCustomOption(possibleMarksHandler);
      const brandsDict = findDictByTitle(DictTypes.Brands, dicts);
      const selectedDealerTitle = findDictByTitle(
        DictTypes.Dealers,
        dicts
      ).content?.find(
        (dealer) => dealer.code === Number(productInfo.dealer)
      )?.name;
      const brandValue = brandsDict.content.find((brand) =>
        selectedDealerTitle?.includes(brand.name)
      );
      //
      // if (dealer?.name.includes('Hongqi')) {
      // } else {
      if (brandValue) {
        setModalSelectState((prevState) => ({
          ...prevState,
          markAuto: brandValue.code.toString()
        }));
        // }
      }

      setPossibleMarks(optionsHandler);
    }
  }, [productInfo]);

  useEffect(() => {
    // setModalSelectState((prevState) => ({ ...prevState, modelAuto: null }));
    if (modalSelectState.markAuto) {
      const dealerArray = findDictByTitle(DictTypes.Dealers, dicts as TDict[]);
      const dealer = dealerArray.content.find(
        (content) => content.code === parseInt(productInfo.dealer as string)
      );
      const brandsArray = findDictByTitle(DictTypes.Brands, dicts as TDict[]);
      const brandUuid = brandsArray.content.find(
        (content) =>
          content.code === parseInt(modalSelectState.markAuto as string)
      )?.uuid;
      let possibleModelsHandler = [
        ...findDictByTitle(DictTypes.Models, dicts as TDict[]).content
      ]
        .filter((model) => {
          return model?.brand_uuid === brandUuid;
        })
        .filter((m) => {
          if (productInfo.autoType == 3) {
            return m.is_commercial;
          } else {
            return m;
          }
        });

      const optionsHandler = setCustomOption(possibleModelsHandler);
      setPossibleModels(optionsHandler);
    }
  }, [modalSelectState.markAuto, productInfo.autoType]);

  useEffect(() => {
    if (modalSelectState.modelAuto) {
      const modelsArray = findDictByTitle(DictTypes.Models, dicts as TDict[]);
      const modelUid = modelsArray.content.find(
        (content) => content.name === modalSelectState.modelAuto
      )?.uuid;
    }
  }, [modalSelectState.modelAuto]);

  return {
    handleModalSelectChange,
    possibleModels,
    modalSelectState,
    possibleMarks
  };
};

export { useMarkModel };
