import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAdminAllClients } from '@/store/admin/adminClientsSlice';
import Column from 'antd/es/table/Column';
import { TClientServerResponse } from '@/types/clients';
import SearchInput from '@/components/Shared/Input/SearchInput';

export const AdminClients: FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading, clients, total } = useAppSelector(
    (state) => state.adminClients
  );
  const [clientsData, setClientsData] = useState<TClientServerResponse[]>([]);
  const [iin, setIin] = useState<string | number | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [searchParam, setSearchParam] = useState<string>('');
  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({
    limit: 10,
    page: 1
  });

  useEffect(() => {
    dispatch(
      getAdminAllClients({
        limit: paginationData.limit,
        page: paginationData.page,
        param: searchParam // ? searchParam : iin ? (iin as string) : undefined
      })
    );
  }, [paginationData, searchParam]);

  useEffect(() => {
    setClientsData(clients);
  }, [clients]);
  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          value={name ? name : iin ? iin : ''}
          onChange={(e) => {
            const value = e.target.value;
            if (!isNaN(Number(value))) {
              setIin(value.slice(0, 12));
              setName(null);
            } else {
              setName(value);
              setIin(null);
            }
          }}
          onSearch={(value) => {
            setSearchParam(value);
            // setName(null);
            // setIin(null);
          }}
        />
      </div>
      <Table
        rowKey="uuid"
        size="small"
        dataSource={clientsData}
        loading={isLoading}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        <Column key="client_iin" title="ИИН" dataIndex="client_iin" />
        <Column
          key="uuid"
          title="ФИО"
          dataIndex="first_name"
          render={(_, record: TClientServerResponse) => {
            return `${record.first_name} ${record.last_name} ${record.middle_name}`;
          }}
        />
        <Column
          key="gender_uuid"
          title="Пол"
          dataIndex="gender_uuid"
          render={(_, record: TClientServerResponse) => {
            return record.gender ? record.gender.name : 'Нет Данных';
          }}
        />
        <Column
          key="resident_status_uuid"
          title="Резидент"
          dataIndex="resident_status_uuid"
          render={(_, record: TClientServerResponse) => {
            return record.resident_status
              ? record.resident_status.name
              : 'Нет данных';
          }}
        />
      </Table>
    </>
  );
};
