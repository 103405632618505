import {
  DictTypes,
  pathAltynLogo,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  shinhanPathLogo,
  warningIcon
} from '../constants';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { TAdditionalInfo } from '../types/additionalInfo';
import { TProductInfo } from '../types/productInfo';
import { TPersonInfo } from '../types/personInfo';
import { TUploadFiles } from '../types/uploadFiles';
import { TDict } from '../types/store';
import { TDataSourceResponses } from '../types/dataSource';
import { TClientRequest } from '../types/clients';
import { checkObjectValues } from './validators/checkObjectValues';

const widgetHeaderHeight = 0;
const getContentHeight = (): number => window.innerHeight - 64 - 10;

export const getFullPageTableHeight = () => {
  return getContentHeight() - widgetHeaderHeight - 300;
};

export const getPageTitle = (location: string) => {
  switch (location) {
    case '/':
      return 'Главная';
    case '/autocred':
      return 'Автокредитование';
    case '/new-insurance':
      return 'Новая заявка';
    case '/newApp':
      return 'Новая заявка';
    case '/insurance':
      return 'Страхование';
    case '/pos-cred':
      return 'POS-кредитование';
    case '/settlement':
      return 'Урегулирование';
    case '/leasing':
      return 'Лизинг';
    case '/clients':
      return 'Клиенты';
    case '/reports':
      return 'Отчеты';
    case '/calculator':
      return 'Предварительный расчет';
    default:
      return '';
  }
};

export const countFields = (
  obj: TProductInfo | TPersonInfo | TAdditionalInfo | TUploadFiles
) => {
  const allFields = Object.keys(obj).filter(
    (key) => key !== 'spouseInfo' && key !== 'incomeCert' && key !== 'otherDocs'
  );
  const nonEmptyFields = allFields.filter((field) => {
    //@ts-ignore
    const value = obj[field];
    if (field === 'markModelYear') {
      if (checkObjectValues(value)) return true;
    }
    // else if (field === 'address') {
    
    //   if (checkObjectValues(obj)) return true;
    // }
    else if (field === 'spouseInfo') {
      // if (checkObjectValues(value)) return true;
      return false;
    } else if (field === 'isLiveRegistred') {
      return false;
    } else if (field === 'incomeCert') {
      return false;
    } else if (field !== 'stocksBonds') {
      return (
        value !== '' &&
        value !== undefined &&
        value !== null &&
        value !== false &&
        value !== true
      );
    }
  });
  return {
    totalFields: allFields.length,
    nonEmptyFields: nonEmptyFields.length
  };
};

export const validateNumberInputValue = (
  value: string,
  maxLength?: number,
  format?: boolean
) => {
  let filteredValue =
    value === '0' ? '0' : value?.toString()?.replace(/[^0-9]/g, '');
  if (maxLength) {
    filteredValue = filteredValue?.slice(0, maxLength);
  }
  if (format) {
    filteredValue = filteredValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return filteredValue;
};

export const validateStringInputLength = (
  inputValue: string,
  maxLength: number
): string => {
  return inputValue.slice(0, maxLength);
};

export const findUniqueValuesOfArray = (
  value: any,
  index: number,
  array: any[]
) => {
  return array.indexOf(value) === index;
};

export const getImgPath = (bankName: string): string => {
  switch (bankName) {
    case 'halyk':
      return pathHalykLogo;
    case 'forte':
      return pathForteLogo;
    case 'euras':
    case 'eurasian':
    case 'eu_bank':
      return pathEurasLogo;
    case 'bereke':
      return pathBerekeLogo;
    case 'bcc':
      return pathBckLogo;
    case 'freedom':
      return pathFreedomlogo;
    case 'shinhan':
      return shinhanPathLogo;
    case 'altyn':
      return pathAltynLogo;
    default:
      return warningIcon;
  }
};

export const getFormattedBankName = (bankName: string) => {
  switch (bankName) {
    case 'bereke':
      return 'Береке Банк';
    case 'halyk':
      return 'Halyk Банк';
    case 'eu_bank':
      return 'Евразийский Банк';
    case 'bcc':
      return 'Банк Центр Кредит';
    case 'sapa':
      return 'SAPA';
    case 'shinhan':
      return 'Shinhan Банк';
    case 'kmf':
      return 'KMF';
    case 'forte':
      return 'Forte Банк';
    case 'ffb':
      return 'Freedom Finance Банк';
    case 'altyn':
      return 'Алтын банк';
    default:
      return 'Банк';
  }
};

export const setDictionaryOptions = (
  dictSnakeName: DictTypes,
  dicts: TDict[] | null
) => {
  const dictToFind = dicts?.find((dict) => dict.dictType === dictSnakeName);

  const optionsArray = dictToFind?.content.map((option) => {
    return {
      value: option.code,
      label: option.name,
      product_type_uuid: option?.product_type_uuid || null,
      is_active: option?.is_active !== undefined ? option?.is_active : true
    };
  });

  if (dictSnakeName === DictTypes.Dealers) {
    return optionsArray
      ?.sort((a, b) => {
        if (a.label.includes('Chevrolet') && !b.label.includes('Chevrolet')) {
          return 1;
        } else if (
          !a.label.includes('Chevrolet') &&
          b.label.includes('Chevrolet')
        ) {
          return -1;
        } else if (a.label.includes('Almaty') && !b.label.includes('Almaty')) {
          return -1;
        } else if (!a.label.includes('Almaty') && b.label.includes('Almaty')) {
          return 1;
        } else if (a.label.includes('Astana') && !b.label.includes('Astana')) {
          return -1;
        } else if (!a.label.includes('Astana') && b.label.includes('Astana')) {
          return 1;
        } else {
          return 0;
        }
      })
      ?.filter((data) => data.is_active);
  } else if (dictSnakeName === DictTypes.AutoTypes) {
    return optionsArray?.sort((a, b) => {
      if (!a.label.includes('Новое Авто') && b.label.includes('Новое Авто')) {
        return 1;
      } else if (
        a.label.includes('Новое Авто') &&
        !b.label.includes('Новое Авто')
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  } else if (dictSnakeName === DictTypes.EngineTypes) {
    return optionsArray?.sort((a, b) => {
      if (a.label.includes('Бензин')) {
        return -1;
      } else if (b.label.includes('Бензин')) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  } else if (dictSnakeName === DictTypes.DocumentTypes) {
    return optionsArray?.sort((a, b) => {
      if (a.label.includes('Удостоверение личности')) {
        return -1;
      } else if (b.label.includes('Удостоверение личности')) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  } else if (dictSnakeName === DictTypes.SocialStatuses) {
    return optionsArray?.sort((a, b) => {
      if (a.label.includes('Стандартный профиль')) {
        return -1;
      } else if (b.label.includes('Стандартный профиль')) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  } else if (dictSnakeName === DictTypes.Products) {
    const productTypesDict = findDictByTitle(DictTypes.ProductsTypes, dicts);

    const insuranceProduct = productTypesDict?.content?.find(
      (data) => data.name === 'Страховка'
    );
    return optionsArray?.filter((option) => {
      return option.product_type_uuid === insuranceProduct?.uuid;
    });
  } else {
    return optionsArray;
  }
};

export const setCustomOption = (
  content: {
    code: number;
    name: string;
    uuid: string;
    brand_uuid?: string;
  }[]
) => {
  const optionsArray = content.map((data) => {
    return {
      value: data.code.toString(),
      label: data.name
    };
  });
  return optionsArray;
};

export const findDictByTitle = (
  title: DictTypes,
  dicts: TDict[] | null
): TDict => {
  const dictToFind = dicts?.find((dict) => dict.dictType === title);
  return dictToFind as TDict;
};

export const findDocType = (
  code: number,
  dockTypesDictionaries: TDict
): string => {
  const contentToFind = dockTypesDictionaries?.content?.find(
    (content) => content?.code === code
  );
  return contentToFind?.name as string;
};

export const generateRandomFourDigitNumber = () => {
  const randomNumber = Math.floor(Math.random() * 9000) + 1000;
  return randomNumber;
};

//

//const { brand, model, year } = autoInsuranceInfo.markModelYear || {};

export const getLastRequestDate = (clintRequests: TClientRequest[]): any => {
  if (clintRequests?.length > 0) {
    return clintRequests[clintRequests.length - 1]?.create_date || '';
  }
};

export const generateRandomAlphanumeric = () => {
  const characters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const randomString = [];

  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString.push(characters.charAt(randomIndex));
  }

  return randomString.join('');
};

export const generateRandomSixDiginCode = () => {
  const randomCode = Math.floor(100000 + Math.random() * 900000);
  return randomCode.toString();
};

export const isValidInput = (value: string) => {
  const regex = /^[a-zA-Zа-яА-ЯЁёҚқҒғӘәіІҮүҰұһҺъьӨөҢң\- ]*$/;
  return regex.test(value) || value === '';
};

export const findFinancedOfferDate = (responses: TDataSourceResponses[]) => {
  const filteredArray = [...responses].filter(
    (response) => response.status === 'financed'
  );
  if (filteredArray.length > 0 && filteredArray[0].funded_date) {
    return dayjs(filteredArray[0].funded_date!).format('DD.MM.YYYY');
  } else {
    return '-';
  }
};

export const getPositionLeft = (key: string): string => {
  switch (key) {
    case 'cascoInsurance':
      return '-122px';
    case 'technicalCertificate':
      return '-120px';
    case 'noTaxDebt':
      return '-71px';
    case 'declarationOfConsen':
      return '-62px ';
    case 'certRegisterZalog':
      return '52px';
    case 'applicationAmountLoan':
      return '52px';
    case 'applicationPayLoan':
      return '52px';
    case 'Identification':
      return '-110px';
    case 'spouseID':
      return '-80px';
    case 'receiptPV':
      return '-140px';
    default:
      return '';
  }
};

export const getBerekePositionLeft = (key: string): string => {
  switch (key) {
    case 'bankLoanAgreement':
      return '22px';
    case 'paymentOrder':
      return '7px';
    case 'memoToDBZ':
      return '-14px';
    case 'solution':
      return '-30px ';
    case 'consentToData':
      return '52px';
    case 'letterDVD':
      return '-15px';
    case 'paymentOrderInsurance':
      return '50px';
    case 'loanApplicationForm':
      return '53px';
    case 'applicationAccidentInsurance':
      return '53px';
    case 'agreementAccidentInsuranc':
      return '53px';
    case 'agreementOpenAccount':
      return '53px';
    default:
      return '';
  }
};

export const findLabelByOptionCode = (
  code: number,
  options: DefaultOptionType[]
): string => {
  const labelToFind = options?.find((option) => option.value === code);
  return labelToFind?.label as string;
};
