import {
  TProductDictionary,
  TStockProductDictionaryResponse
} from '@/types/store';

export const stockbondsProductsDictionaryMapper = (
  serverResponse: TStockProductDictionaryResponse[]
): TProductDictionary[] => {

  return serverResponse.map((res) => {
    const mappedValue: TProductDictionary = {
      uuid: res?.promo_product?.uuid || '',
      insurance_uuid: res?.promo_product?.insurance_uuid || null,
      bank_name: res?.promo_product?.bank?.name || '',
      bank: res?.promo_product?.bank_uuid || '',
      name: res?.promo_product?.name || '',
      code: res?.promo_product?.code || '',
      productType: res?.promo_product?.product_type_uuid || '',
      description: res?.promo_product?.promo_body || '',
      loan_period: res?.promo_product?.loan_period || null,
      loan_amount: res?.promo_product?.loan_amount || null,
      is_active: res?.promo_product?.is_active || false,
      product_code: res?.promo_product?.product_code || null,
      partner_code: res?.promo_product?.partner_code || null,
      enabled_by_default: false,
      grace: res?.promo_product?.grace_period
        ? Number(res?.promo_product?.grace_period)
        : null
    };
    return mappedValue;
  });
};
