import '@/styles/ClientMainPage.scss';
import { useEffect, useState } from 'react';
import { DatePicker, Tabs, TabsProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import TopFCTable from './TopFCTable/TopFCTable';
import FinancedData from './FinancedData/FinacedData';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getAllRequestsForCharts,
  getRequests,
  getTopFC,
  getUserFinanced
} from '@/store/request.slice';
import { findUniqueValuesOfArray } from '@/services';
import { BanksColors } from '@/constants';
import VerticalChartBar from '@/components/Shared/Charts/VerticalChartBar';

const { RangePicker } = DatePicker;

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

const FORMAT = 'DD.MM.YYYY';

const ClientMainPage = () => {
  const dispatch = useAppDispatch();
  const { requests, requestsForCharts, topFC, financedRequestsForCharts } =
    useAppSelector((state) => state.request);
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { activeDealersOption } = useAppSelector((state) => state.dict);

  const [selectedDealers, setSelectedDealers] = useState<string>('allDealers');
  const [datesValues, setDatesValues] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: dayjs(new Date()).startOf('month'),
    endDate: dayjs(new Date()).startOf('month').add(1, 'month')
  });
  const [datesValuesForFinanced, setDatesValuesForFinanced] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: dayjs(new Date()).startOf('month'),
    endDate: dayjs(new Date()).startOf('month').add(1, 'month')
  });
  const [topFCDate, setTopFCDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [dataSetsArray, setDataSetsArray] = useState<{
    labels: string[];
    datasets: {
      label: string;
      borderRadius?: number;
      bgColor: string;
      data: number[];
    }[];
  }>({
    labels: [],
    datasets: []
  });
  const [overallAmount, setOverallAmount] = useState<number>(0);

  const [doughnutChartData, setDoughnutChartData] = useState<{
    labels: string[];
    data: number[];
    backgroundColors: string[];
  }>({
    labels: [],
    data: [],
    backgroundColors: []
  });

  useEffect(() => {
    dispatch(getRequests());
    dispatch(
      getUserFinanced({
        dealer_uuid: '-1',
        date_from: '2023-01-01',
        date_to: '2024-05-01'
      })
    );
    dispatch(getAllRequestsForCharts());
  }, []);

  useEffect(() => {
    if (userInfo?.dealer) {
      const uniqueDealersArray = [...(requestsForCharts || [])]
        .filter((req) => {
          const createDate = dayjs(req.create_date);
          const isCreationAcceptable =
            createDate.isAfter(datesValues.startDate) ||
            createDate.format(FORMAT) ===
              datesValues?.startDate?.format(FORMAT);

          const isEndDateAcceptable =
            createDate.isBefore(datesValues.endDate) ||
            createDate.format(FORMAT) ===
              datesValues?.startDate?.format(FORMAT);
          return isCreationAcceptable && isEndDateAcceptable;
        })
        .map((req) => req.dealer)

        .filter(findUniqueValuesOfArray);
      //В будущем будет массив
      setDataSetsArray({
        labels: uniqueDealersArray,
        datasets: [
          {
            label: 'Все заявки',
            borderRadius: 4,
            data: userInfo?.dealer
              ? uniqueDealersArray.map((dealer) => {
                  const filteredRequests = requestsForCharts?.filter((req) => {
                    const createDate = dayjs(req.create_date);

                    const isDealerEqual = req.dealer === dealer;

                    const isCreationAcceptable =
                      createDate.isAfter(datesValues.startDate) ||
                      createDate.format(FORMAT) ===
                        datesValues?.startDate?.format(FORMAT);

                    const isEndDateAcceptable =
                      createDate.isBefore(datesValues.endDate) ||
                      createDate.format(FORMAT) ===
                        datesValues?.startDate?.format(FORMAT);
                    return (
                      isDealerEqual &&
                      isCreationAcceptable &&
                      isEndDateAcceptable
                    );
                  });
                  return filteredRequests?.length || 0;
                })
              : [0],
            bgColor: '#2DD4BF'
          },
          {
            label: 'Мои заявки',
            borderRadius: 4,
            data: userInfo?.dealer
              ? uniqueDealersArray.map((dealer) => {
                  const filteredRequests = requests?.filter((req) => {
                    const createdAtDate = dayjs(req.created_at.date);

                    const isDealerEqual = req.dealer.name === dealer;

                    const isCreationAcceptable =
                      createdAtDate.isAfter(dayjs(datesValues.startDate)) ||
                      createdAtDate.format(FORMAT) ===
                        datesValues?.startDate?.format(FORMAT);

                    const isEndDateAcceptable =
                      createdAtDate.isBefore(dayjs(datesValues.endDate)) ||
                      createdAtDate.format(FORMAT) ===
                        datesValues?.startDate?.format(FORMAT);

                    return (
                      isDealerEqual &&
                      isCreationAcceptable &&
                      isEndDateAcceptable
                    );
                  });
                  return filteredRequests?.length || 0;
                })
              : [0],
            bgColor: '#2F78EE'
          }
        ]
      });
    }
  }, [requests, requestsForCharts, datesValues]);

  useEffect(() => {
    topFCDate && dispatch(getTopFC(topFCDate));
  }, [topFCDate]);

  useEffect(() => {
    let overallAmount = 0;
    const chartDataHandler: {
      labels: string[];
      data: number[];
      backgroundColors: string[];
    } = {
      labels: [],
      data: [],
      backgroundColors: []
    };
    financedRequestsForCharts?.forEach((data) => {
      chartDataHandler.data.push(data.totalcount);
      chartDataHandler.labels.push(data.bank_name);
      switch (data.bank_name) {
        case 'bcc':
          chartDataHandler.backgroundColors.push(BanksColors.BccBank);
          break;

        default:
          break;
      }
      overallAmount += data.totalcount;
    });
    setDoughnutChartData(chartDataHandler);
    setOverallAmount(overallAmount);
  }, [financedRequestsForCharts]);

  useEffect(() => {
    dispatch(
      getUserFinanced({
        dealer_uuid: selectedDealers !== 'allDealers' ? selectedDealers : '-1',
        date_from: datesValuesForFinanced.startDate?.format('YYYY-MM-DD') || '',
        date_to: datesValuesForFinanced.endDate?.format('YYYY-MM-DD') || ''
      })
    );
  }, [selectedDealers, datesValuesForFinanced]);

  const handleRangePickerChange = (value: RangeValue<Dayjs>) => {
    if (value == undefined) {
      setDatesValues({
        startDate: null,
        endDate: null
      });
      return;
    }

    if (value[0] == undefined || value[1] == undefined) {
      return;
    }

    const start = dayjs(value[0]);
    const end = dayjs(value[1]);

    setDatesValues({
      startDate: start,
      endDate: end
    });
  };

  const handleFinancedRangePickerChange = (value: RangeValue<Dayjs>) => {
    if (value == undefined) {
      setDatesValuesForFinanced({
        startDate: null,
        endDate: null
      });
      return;
    }

    if (value[0] == undefined || value[1] == undefined) {
      return;
    }

    const start = dayjs(value[0]);
    const end = dayjs(value[1]);

    setDatesValuesForFinanced({
      startDate: start,
      endDate: end
    });
  };

  const onChangeDate = (value: Dayjs | null, dateString: string | string[]) => {
    setTopFCDate(value);
  };

  const tabsItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Динамика количества заявок',
      children: (
        <div className="VerticalChart_root">
          <div className="Request_data_block">
            <div>
              <h2>Динамика количества заявок</h2>
              <div>
                <p className="Mine_requests">Все заявки</p>
                <p className="Other_requests">Заявки созданные мной</p>
              </div>
            </div>
            <RangePicker
              placeholder={['Начало', 'Конец']}
              value={[datesValues.startDate, datesValues.endDate]}
              format={FORMAT}
              onChange={handleRangePickerChange}
            />
          </div>
          <div>
            <VerticalChartBar
              labels={dataSetsArray.labels}
              datasets={dataSetsArray.datasets}
            />
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: 'Профинансировано',
      children: (
        <div className="FC_data_top_info">
          <FinancedData
            selectDealerValue={selectedDealers}
            datesValue={[
              datesValuesForFinanced.startDate,
              datesValuesForFinanced.endDate
            ]}
            handleRangePickerChange={handleFinancedRangePickerChange}
            setSelectedDealer={setSelectedDealers}
            overallAmount={overallAmount}
            bankFinancedRequests={financedRequestsForCharts || []}
            dealersOptions={
              [
                {
                  value: 'allDealers',
                  label: 'Все дилеры'
                }
              ].concat(activeDealersOption || []) || [
                {
                  value: 'allDealers',
                  label: 'Все дилеры'
                }
              ]
            }
            doughnutChartData={doughnutChartData}
          />
        </div>
      )
    },
    {
      key: '3',
      label: 'ТОП-10 ФК',
      children: (
        <div className="FC_data_top_info">
          <TopFCTable
            date={topFCDate}
            onChangeDate={onChangeDate}
            fcDataSource={topFC || []}
          />
        </div>
      )
    }
  ];

  return (
    <div className="ClientMainPage">
      <div className="content">
        <Tabs defaultActiveKey="1" items={tabsItems} />
      </div>
    </div>
  );
};

export default ClientMainPage;
