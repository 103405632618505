import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { ButtonAuth } from '../Shared/Button/ButtonAuth';
import CustomCheckboxMui from '../Shared/Checkbox/CustomCheckboxMui';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import styled from 'styled-components';
import {
  pathCloseXIcon,
  pathLogoAllurRed,
  pathLogoBg,
  warningIcon
} from '@/constants';
import { CustomModal } from '../Shared/Modal/CustomModal';
import '@fontsource/ibm-plex-sans';
import { Button, notification, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  forgotePassword,
  login,
  resetLoginError,
  setResetEmail,
  toggleLoginError
} from '@/store/auth.slice';
import '@/styles/Auth.scss';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  email: number;
  password: string;
  example: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '40%',
  transform: 'translate(-50%, -50%)',
  width: '480px',
  fontFamily: 'IBM Plex Sans'
};

const StyledInput = styled.div`
  span {
    position: absolute;
    left: 435px;
    top: 10%;
    padding: 9px 8px;
    fill: black;
    transition: 0.3s;
    outline: none;
  }

  input:focus + span {
    fill: dodgerBlue;
    outline: none;
  }

  &.inputWithIcon {
    position: relative;
    outline: none;
  }
`;

export const LoginPage = () => {
  const { loginError, isLoading, resetEmail } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState<boolean>(false);
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [resetPasModal, setResetPasModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const handleCheckboxChangeRememberMe = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setChecked(event.target.checked);
  const handleLogin = async (email: string, password: string) => {
    dispatch(login({ email, password, isRemember: checked }));
  };
  const onSubmit = (data: IFormInput) => alert(JSON.stringify(data));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    dispatch(resetLoginError());
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    dispatch(resetLoginError());
  };

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const resestPass = () => {
    if (email !== '') {
      dispatch(setResetEmail(email));
      setResetPasModal(true);
    } else {
      notification.warning({
        message: 'Пожалуйста введите email.'
      });
    }
  };

  const resetPasswordHandler = () => {
    setResetPasModal(false);
    dispatch(forgotePassword(resetEmail || ''));
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen size="large" />
      <CustomModal
        isModalOpen={resetPasModal}
        handleOk={() => setResetPasModal(false)}
        handleCancel={() => setResetPasModal(false)}
        title={'Сброс пароля'}
        editClass={'resetPasModal'}
        footer={[
          <Button
            style={{ backgroundColor: '#111827' }}
            key="submit"
            type="primary"
            onClick={resetPasswordHandler}
          >
            Ок
          </Button>
        ]}
      >
        <div>
          <h2>
            Мы выслали вам электронные письма с инструкциями по сбросу пароля.
          </h2>
        </div>
      </CustomModal>
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '90%',
            width: '100px',
            justifyContent: 'center',
            margin: '0 auto',
            alignItems: 'center'
          }}
        >
          <img src={pathLogoAllurRed} alt="Allur Finance" width={100} />
          <br />
        </div>
        <Typography
          id="header"
          variant="h6"
          component="h2"
          align="center"
          fontWeight="700"
          fontSize="24px"
          fontFamily="IBM Plex Sans"
        >
          <strong>Вход в систему</strong>
        </Typography>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <br></br>
          <input
            type="email"
            {...register('email', { required: true })}
            style={{
              padding: '16px',
              border: '1px solid #E9EAEC',
              borderRadius: '10px',
              width: '100%',
              marginBottom: '20px'
            }}
            value={email}
            onChange={handleChangeEmail}
            placeholder="Введите email"
            id="email"
            name="email"
          />
          {errors?.email?.type === 'required' && (
            <p>Обязательно к заполнению</p>
          )}
          <div>
            <StyledInput className={'inputWithIcon'}>
              <input
                type={type}
                autoComplete="on"
                {...register('password', { required: true })}
                style={{
                  padding: '16px',
                  border: '1px solid #E9EAEC',
                  borderRadius: '10px',
                  width: '100%'
                }}
                value={password}
                onChange={handleChange}
                placeholder="Введите пароль"
                id="passwd"
                name="password"
              />
              <span
                className="flex justify-around items-center"
                onClick={handleToggle}
              >
                <Icon className="absolute mr-10" icon={icon} size={25} />
              </span>
            </StyledInput>
          </div>
          {errors?.password?.type === 'required' && (
            <p>Обязательно к заполнению</p>
          )}
          <div style={{ marginBottom: '20px' }}>
            <FormControlLabel
              style={{ margin: 0 }}
              control={
                <CustomCheckboxMui
                  checked={checked}
                  onChange={handleCheckboxChangeRememberMe}
                />
              }
              label={
                <span style={{ fontSize: '12px', marginLeft: '15px' }}>
                  Запомнить меня
                </span>
              }
            />
          </div>
          {loginError ? (
            <div className="auth_error">
              <div>
                <img src={warningIcon} alt="warn" />
                <p>Неверный email или пароль</p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleLoginError());
                }}
              >
                <img src={pathCloseXIcon} alt="close" />
              </button>
            </div>
          ) : (
            ''
          )}
          <ButtonAuth
            title={'Войти'}
            disabled={email && password ? false : true}
            onClick={() => handleLogin(email, password)}
          />
        </form>
        <Typography
          id="header"
          align="center"
          fontWeight="500"
          fontSize="14px"
          marginTop="30px"
          fontFamily="IBM Plex Sans"
        >
          <span style={{ color: '#A0AEC0', fontWeight: 'normal' }}>
            Возникли проблемы со входом?
          </span>{' '}
          <span
            onClick={resestPass}
            style={{
              cursor: 'pointer',
              color: '#111827',
              textDecoration: 'none',
              fontFamily: 'IBM Plex Sans'
            }}
          >
            Сбросить пароль
          </span>
        </Typography>
      </Box>
      <img
        style={{ position: 'absolute', right: 0 }}
        src={pathLogoBg}
        alt="Allur auth"
        width="25%"
        height="100%"
      />
      <div style={{ position: 'absolute', bottom: '40px', left: '37%' }}>
        <Typography
          id="header"
          align="center"
          fontWeight="500"
          fontSize="14px"
          fontFamily="IBM Plex Sans"
          color="#A0AEC0"
        >
          © 2023 Allur Finance
        </Typography>
      </div>
    </>
  );
};
