import React, { useEffect, useState } from 'react';
import '@/styles/ForteBank.scss';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import { Button, Spin } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { TFileAttached } from '@/types/financing';
import { checkArrayValues } from '@/services/validators/checkArrayValues';

export type TForteBankProps = {
  handleSendDocs: (uploadedFiles: any) => any;
  isOfferFinanced: boolean;
  requestLoading: boolean;
  attachedFiles: TFileAttached[];
  updateForteStatus: () => void;
  isForteStatusLoading: boolean;
  infoTS: boolean;
  pollingActive: boolean;
};

export const ForteBank = ({
  infoTS,
  isForteStatusLoading,
  updateForteStatus,
  attachedFiles,
  handleSendDocs,
  requestLoading,
  isOfferFinanced,
  pollingActive
}: TForteBankProps) => {
  const [uploadFiles, setUploadFiles] = useState({});
  const [files, setFiles] = useState<TFileAttached[]>([]);
  const [isSendAvailable, setIsSendAvailable] = useState<boolean>(false);
  const [forteSpouseAgreementSignedFile, setForteSpouseAgreementSignedFile] =
    useState<null | string>(null);
  const [forteNoSpouseAgreement, setForteNoSpouseAgreement] = useState<
    null | string
  >(null);
  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({
      ...prevState,
      [type]: { name: file?.name, base64: fileBase64 }
    }));
  };

  useEffect(() => {
    const isValid = Object.values(uploadFiles).length
      ? checkArrayValues(Object.values(uploadFiles))
      : false;
    setIsSendAvailable(isValid);
  }, [uploadFiles]);

  useEffect(() => {
    const filteredArr = attachedFiles
      .filter((value, index, self) => {
        return self.findIndex((item) => item.type === value.type) === index;
      })
      .filter(
        (file) =>
          file.type === 'forteSpouseAgreement' ||
          file.type === 'forteCommitmentLetter'
      );
    setFiles(filteredArr);
    if (attachedFiles.length > 0) {
      const signedAgreement = attachedFiles.find(
        (file) => file.type === 'forteSpouseAgreementSigned'
      );
      signedAgreement &&
        setForteSpouseAgreementSignedFile(signedAgreement.uuid);

      const noSpouseAgreement = attachedFiles.find(
        (file) => file.type === 'forteNoSpouseAgreement'
      );
      noSpouseAgreement && setForteNoSpouseAgreement(noSpouseAgreement.uuid);
    }
  }, [attachedFiles]);

  return (
    <div className="forteBank">
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            loading={isForteStatusLoading}
            onClick={updateForteStatus}
            icon={<ReloadOutlined />}
            disabled={pollingActive}
          >
            {pollingActive ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            ) : (
              'Обновить статус'
            )}
          </Button>
        </div>
      <div className="cols">
        <div
          // className="col"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '20px'
          }}
        >
          {files &&
            files.map((file) => (
              <div style={{ width: '100%' }} key={file.type}>
                <FileDownload
                  prevStateTitle={
                    file.type === 'forteCommitmentLetter'
                      ? 'Гарантийное письмо'
                      : file.type === 'forteSpouseAgreement'
                      ? 'Согласие супруга(и)' // / Отсутствие супруга(и)'
                      : ''
                  }
                  fileLink={file.uuid}
                />
              </div>
            ))}
        </div>
      </div>
      {forteSpouseAgreementSignedFile && (
        <FileDownload
          prevStateTitle={
            'Согласие супруга(и) / Отсутствие супруга(и)  (подписанный)'
          }
          fileLink={forteSpouseAgreementSignedFile}
        />
      )}
      {forteNoSpouseAgreement && (
        <FileDownload
          prevStateTitle={'Отсутствие супруга(и)'}
          fileLink={forteNoSpouseAgreement}
        />
      )}
      <FileUpload
        fileFormat="application/pdf"
        positionLeft={'50px'}
        marginLeft={'35px'}
        isLg={true}
        onFileChange={handleFileChange}
        title={'Согласие супруга(и) / Отсутствие супруга(и) '}
        type="forteSpouseAgreementSigned"
        preventActions={false}
      />

      {!isOfferFinanced ? (
        infoTS ? (
          <button
            className="sendBtn"
            onClick={() => handleSendDocs(uploadFiles)}
            disabled={!isSendAvailable}
          >
            {!requestLoading ? (
              'Отправить документы'
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            )}
          </button>
        ) : null
      ) : null}
    </div>
  );
};
