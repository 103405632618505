import React, { FC, useState } from 'react';
import { TSalePoint } from '@/types/salePoint.type';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { setShowModalCreateOrEditSalePoint } from '@/store/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '@/store';
import CreateOrEditSalePointModal from '@/components/AdminPanel/Modals/CreateOrEditSalePointModal';

export const SalePoints: FC = () => {
  const { showModalCreateOrEditSalePoint } = useAppSelector(
    (state) => state.admin
  );
  const dispatch = useAppDispatch();
  const [pointForEdit, setPointForEdit] = useState<TSalePoint | null>(null);
  const [pointsData, setPointsData] = useState<TSalePoint[]>([
    {
      uuid: '1',
      name: 'test 1',
      is_active: true,
      address_fact: 'Almaty',
      city_uuid: '02fc27a1-2625-4d52-96c0-56b84cdbcb0e',
      city: {
        uuid: '111',
        code: '1',
        name: 'almaty'
      },
      dealer_uuid: '123',
      address_jur: 'almaty'
    },
    {
      uuid: '2',
      name: 'test 2',
      is_active: false,
      address_fact: 'Astana',
      city_uuid: '8bf62f3e-612e-4587-b1e8-096186d6a236',
      city: {
        uuid: '111',
        code: '1',
        name: 'Astana'
      },
      dealer_uuid: '123',
      address_jur: 'Astana'
    }
  ]);

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput style={{ width: '50%', marginRight: '10px' }} />
        {showModalCreateOrEditSalePoint && (
          <CreateOrEditSalePointModal
            setShow={() => dispatch(setShowModalCreateOrEditSalePoint(false))}
            editMode={!!pointForEdit}
            salePointItem={pointForEdit}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setPointForEdit(null);
            dispatch(setShowModalCreateOrEditSalePoint(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowKey="uuid"
        size="small"
        dataSource={pointsData}
        // loading={true}
      >
        <Column key="name" title="Наименование" dataIndex="name" />
        <Column
          key="city_uuid"
          title="Город"
          dataIndex="city_uuid"
          render={(_, record: TSalePoint) => {
            return record.city ? record.city.name : ' No data';
          }}
        />
        <Column
          key="dealer_uuid"
          title="Дилер"
          dataIndex="dealer_uuid"
          render={(_, record: TSalePoint) => {
            return record.dealer ? record.dealer.name : 'No data';
          }}
        />
        <Column
          key="is_active"
          title="Стутус"
          dataIndex="is_active"
          render={(_, record: TSalePoint) => {
            return record.is_active ? (
              <span style={{ color: 'green' }}>Активно</span>
            ) : (
              <span style={{ color: 'red' }}>Не активно</span>
            );
          }}
        />
        <Column
          key="actions"
          title="Действия"
          render={(_, record: TSalePoint) => (
            <>
              <Typography.Link key="edit">
                <EditOutlined
                  onClick={() => {
                    setPointForEdit(record);
                    dispatch(setShowModalCreateOrEditSalePoint(true));
                  }}
                />
              </Typography.Link>
              <Popconfirm
                key="removeConf"
                title={record.is_active ? 'Deactivate' : 'Activate'}
                // onConfirm={() => {
                //   delete record.brand;
                //   delete record.city;
                //   dispatch(
                //     updateDealer({
                //       ...record,
                //       is_active: !record.is_active
                //     })
                //   );
                // }}
              >
                <Tooltip title={record.is_active ? 'Deactivate' : 'Activate'}>
                  <Typography.Link key="remove" style={{ marginLeft: '20px' }}>
                    {record.is_active ? (
                      <CloseCircleOutlined style={{ color: 'red' }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: 'green' }} />
                    )}
                  </Typography.Link>
                </Tooltip>
              </Popconfirm>
            </>
          )}
        />
      </Table>
    </>
  );
};
