import { TLeasingCreationDto } from '@/types/leasing';

export const leasingValidator = (
  leasingCreationDto: TLeasingCreationDto
): boolean => {
  let isValid = true;
  Object.keys(leasingCreationDto).forEach((key) => {
    //@ts-ignore
    if (!leasingCreationDto[key] && key !== 'status') {
      isValid = false;
    }
  });
  return isValid;
};
