import { TSpecialCondition } from '../types/offers';
// import { pathBerekeLogo, pathBckLogo, pathFreedomlogo, pathKmfLogo, pathHalykLogo, pathEurasLogo, pathForteLogo } from '../constants';

// export const offers: TBankOffers[] = [
//     {
//         id: 1,
//         bank: "halyk",
//         name: "Halyk Bank",
//         icon: pathHalykLogo,
//         statusCode: "1",
//         statusType: "approved",
//         monthlyPayment: "126 787",
//         rate: "22",
//         term: "84",
//         loanAmount: "10 000 000",
//         initialFee: "5 000 000",
//         product: "KIA Стандарт",
//         reason: "",
//         alternative: [
//             {
//                 id: 1,
//                 bank: "halyk",
//                 name: "Halyk Bank",
//                 icon: pathHalykLogo,
//                 monthlyPayment: "111 111",
//                 rate: "11",
//                 term: "10",
//                 loanAmount: "10 000 000",
//                 initialFee: "1 000 000",
//                 product: "Chevrolet React",
//                 statusCode: "1",
//                 statusType: "accepted_alternative",
//                 reason: ""
//             },
//             {
//                 id: 2,
//                 bank: "halyk",
//                 name: "Halyk Bank",
//                 icon: pathHalykLogo,
//                 monthlyPayment: "222 222",
//                 rate: "22",
//                 term: "20",
//                 loanAmount: "20 000 000",
//                 initialFee: "2 000 000",
//                 product: "Lada Premium",
//                 statusCode: "1",
//                 statusType: "approved",
//                 reason: ""
//             },
//             {
//                 id: 3,
//                 bank: "halyk",
//                 name: "Halyk Bank",
//                 icon: pathHalykLogo,
//                 monthlyPayment: "333 333",
//                 rate: "33",
//                 term: "30",
//                 loanAmount: "30 000 000",
//                 initialFee: "3 000 000",
//                 product: "JAC Donadls",
//                 statusCode: "1",
//                 statusType: "approved",
//                 reason: ""
//             },
//         ],
//     },
//     {
//         id: 2,
//         bank: "freedom",
//         name: "Freedom Finance",
//         icon: pathFreedomlogo,
//         statusCode: "4",
//         statusType: "declined",
//         monthlyPayment: "",
//         rate: "",
//         term: "",
//         loanAmount: "",
//         initialFee: "",
//         product: "",
//         reason: "ИИН несоответствует чему-то",
//     },
//     {
//         id: 3,
//         bank: "bcc",
//         name: "Банк Центр Кредит",
//         statusCode: "2",
//         statusType: "approved",
//         icon: pathBckLogo,
//         monthlyPayment: "130 457",
//         rate: "26.5",
//         term: "84",
//         loanAmount: "13 990 000",
//         initialFee: "1 500 000",
//         product: "KIA Style",
//         reason: "",
//     },
//     {
//         id: 4,
//         bank: "bereke",
//         name: "Bereke Bank",
//         icon: pathBerekeLogo,
//         statusCode: "2",
//         statusType: "waiting_bank_response",
//         monthlyPayment: "",
//         rate: "",
//         term: "",
//         loanAmount: "",
//         initialFee: "",
//         product: "",
//         reason: "",
//     },
//     {
//         id: 5,
//         bank: "eurasian",
//         name: "Евразийский Банк",
//         icon: pathEurasLogo,
//         statusCode: "1",
//         statusType: "approved",
//         monthlyPayment: "190 787",
//         rate: "27.1",
//         term: "84",
//         loanAmount: "18 000 000",
//         initialFee: "6 000 000",
//         product: "Euraisan Auto",
//         reason: "",
//     },
//     {
//         id: 6,
//         bank: "kmf",
//         name: "KMF",
//         icon: pathKmfLogo,
//         statusCode: "3",
//         statusType: "declined",
//         monthlyPayment: "",
//         rate: "",
//         term: "",
//         loanAmount: "",
//         initialFee: "",
//         product: "",
//         reason: "Отказано банком из-за высокой кредитной активности",
//     },
//     {
//         id: 7,
//         bank: "forte",
//         name: "Forte Bank",
//         icon: pathForteLogo,
//         statusCode: "2",
//         statusType: "waiting_bank_response",
//         monthlyPayment: "",
//         rate: "",
//         term: "",
//         loanAmount: "",
//         initialFee: "",
//         product: "",
//         reason: "",
//     },
// ];

export const specialConditions: TSpecialCondition[] = [
  {
    contract_id: 'R123124123-123',
    bank: 'Kaspi Bank',
    monthlyLoanPayment: 130000,
    overallLoanAmount: 4310000
  },
  {
    contract_id: '1418291239123123',
    bank: 'Jusan Bank',
    monthlyLoanPayment: 43321,
    overallLoanAmount: 590400
  }
];
