import React, { useEffect, useRef, useState } from 'react';
import '@/styles/PhotoUpload.scss';
import { CustomModal } from '../Modal/CustomModal';
import { Button } from 'antd';
import Webcam from 'react-webcam';
import { useAppSelector } from '@/store';
import { pathCameraIcon, pathPhotoIcon } from '@/constants';
import { downloadFilesApi } from '@/integrations/index.api';
import { calculateFileSizeInMB } from '@/services/helpers/calculateFileSizeInMB';

export type TPhotoUploadProps = {
  onFileChange: (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => void;
  title: string;
  type: string;
  file: any;
  urlImg: string;
};

export const PhotoUpload = ({
  onFileChange,
  title,
  type,
  file,
  urlImg
}: TPhotoUploadProps) => {
  const { orderApp } = useAppSelector((state) => state.newOrder);

  const inputRef = useRef<HTMLInputElement>(null);
  const webCamRef = useRef<Webcam>(null);
  const [camOn, setCamOn] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(file);
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string | null>(file?.base64 || '');
  const [showUploadTypeModal, setShowUploadTypeModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (!showUploadTypeModal) {
      stopCam();
    } else {
      setCamOn(true);
    }
  }, [showUploadTypeModal]);
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const base64String = await convertFileToBase64(file);
      setSelectedFile(file);
      onFileChange(file, base64String, type);
      setImageUrl(base64String);
      setShowUploadTypeModal(false);
    }
  };

  const handleTakeScreenShow = async () => {
    const imageSrc = webCamRef.current?.getScreenshot();
    if (imageSrc) {
      const base64Response = await fetch(`${imageSrc}`);
      const blob = await base64Response.blob();
      const imageFile = new File([blob], 'new.jpeg');
      setSelectedFile(imageFile);
      onFileChange(imageFile, imageSrc.split(',')[1], type);
      setImageUrl(imageSrc.split(',')[1]);
      stopCam();
    }
  };

  const stopCam = () => {
    setCamOn(false);
    setTimeout(() => {
      setShowUploadTypeModal(false);
    }, 0);
  };

  const convertFileToBase64 = async (file: File): Promise<string> => {
    try {
      const base64String = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
      return base64String.split(',')[1];
    } catch (error) {
      throw error;
    }
  };

  const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedFile(null);
    setImageUrl(null);
    onFileChange(null, null, type);
    if (inputRef.current) inputRef.current.value = '';
  };

  const handleFileUploadClick = () => {
    if (orderApp?.status !== 'draft' && orderApp !== null) return;
    setShowUploadTypeModal(true);
  };

  const bufferToBase64 = (buffer: any) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    const fetchBase64 = async () => {
      try {
        const base64 = await downloadFilesApi.downloadBankFile(
          file?.uuid || ''
        );
        setUploadedImage(`data:image/webp;base64,${base64?.base64 || ''}`);
      } catch (e: any) {
        const { message } = e;
        if (message?.includes('Try this: ')) {
          const fileLink = message?.split('Try this: ')[1];
          if (fileLink) {
            const response = await fetch(fileLink);
            if (response.ok) {
              const arrayBuffer = await response.arrayBuffer();
              const base64 = bufferToBase64(arrayBuffer);
              setUploadedImage(`data:image/webp;base64,${base64 || ''}`);
            }
          }
        }
      }
    };
    fetchBase64();
    // const;
  }, []);

  return (
    <>
      <CustomModal
        title="Выберите способ загрузки"
        handleCancel={stopCam}
        handleOk={() => setShowUploadTypeModal(false)}
        editClass="UploadType_modal"
        isModalOpen={showUploadTypeModal}
        footer={[
          <Button key="cancel" onClick={stopCam}>
            Отмена
          </Button>,
          <Button
            key="upload"
            onClick={() => {
              stopCam();
              if (inputRef.current) inputRef.current.click();
            }}
          >
            Загрузить файл
          </Button>
        ]}
      >
        <div className="screen_root">
          {camOn ? (
            <Webcam
              screenshotFormat={'image/jpeg'}
              ref={webCamRef}
              width="100%"
              frameBorder={'10px'}
              audio={false}
              style={{ borderRadius: '10px 10px 0 0' }}
            />
          ) : selectedFile ? (
            <img src={`data:image/webp;base64,${imageUrl}`} alt="you photo" />
          ) : null}
          <Button onClick={handleTakeScreenShow}>
            <img src={pathPhotoIcon} alt="take_screnshot" />
          </Button>
        </div>
      </CustomModal>
      <div className="photoUpload" onClick={handleFileUploadClick}>
        <label className="customPlaceHolder">{title}</label>
        <input type="file" onChange={handleFileChange} ref={inputRef} />
        {orderApp?.status === 'draft' || orderApp === null ? (
          selectedFile ? (
            <div className="selected">
              {imageUrl ? (
                <img
                  src={`data:image/webp;base64,${imageUrl}`}
                  alt="uploaded_photo"
                  className="uploadedPhoto"
                />
              ) : (
                <div className="fileName">
                  <span>{`${calculateFileSizeInMB(
                    selectedFile.size
                  )} МБ`}</span>
                </div>
              )}
              <button onClick={handleRemoveFile}>
                <img src={pathCameraIcon} alt="camera_icon" />
                Переснять
              </button>
            </div>
          ) : (
            <div className="choose">
              <div className="photo_tmp">
                <img
                  onClick={() => setCamOn(true)}
                  src={pathPhotoIcon}
                  alt="photo"
                />
                <p>Нажмите на иконку камеры, чтобы сделать фотографию</p>
              </div>
            </div>
          )
        ) : (
          <div className="selected">
            <img
              src={
                uploadedImage
                // !uploadedImage?.includes('http')
                //   ? `data:image/webp;base64,${uploadedImage}`
                //   : ''
              }
              alt="uploaded_photo"
              className="uploadedPhoto"
            />
          </div>
        )}
      </div>
    </>
  );
};
