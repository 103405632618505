import Search from 'antd/es/input/Search';
import { SearchProps } from 'antd/lib/input';
import { FC } from 'react';

type TSearchInputProps = SearchProps;

const SearchInput: FC<TSearchInputProps> = ({
  value,
  onSearch,
  onChange,
  ...rest
}) => {
  return (
    <Search value={value} onSearch={onSearch} onChange={onChange} {...rest} />
  );
};

export default SearchInput;
