import { AxiosInstance } from 'axios';
import { TBank, TBankDto } from '@/types/bank.type';

export class BanksApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllBanks(): Promise<TBank[]> {
    const res = await this.api.get('admin/banks/getAll');
    return res.data;
  }

  public async createAdminBank(data: TBankDto) {
    const res = await this.api.post(`admin/banks/create`, data);
    return res;
  }

  public async updateAdminBank(data: TBank) {
    const res = await this.api.patch(`admin/banks/${data.uuid}/update`, data);
    return res;
  }
}
