import { TLeasingTableData, TServerLeasingsResponse } from '@/types/leasing';
import { formatPhoneNumber } from '@/services/formatter';

export const leasingsResponseMapper = (
  serverLeasingsResponse: TServerLeasingsResponse[]
): TLeasingTableData[] => {
  return serverLeasingsResponse
    .map((leasing) => {
      const formattedValue: TLeasingTableData = {
        uuid: leasing?.uuid || '',
        user: {
          userUuid: leasing?.user?.uuid || '',
          userName: `${leasing?.user?.last_name || ''} ${
            leasing?.user?.first_name || ''
          } ${leasing?.user?.middle_name || ''} `
        },
        updateDate: leasing?.update_date || '',
        createDate: leasing?.create_date || '',
        identificationDocument: leasing?.iin || '',
        birthDate: leasing?.create_date || '',
        livingPlace: leasing?.actual_address || '',
        citizenship: leasing?.registration_country?.code?.toString() || '',
        documentIssueDate: leasing?.document_issue_date || '',
        issuingAuthority:
          leasing?.place_of_registration?.code?.toString() || '',
        personalPhoneNumber: formatPhoneNumber(
          leasing?.contact_phone_number || ''
        ),
        registrationAddress: leasing?.legal_address || '',
        status: leasing?.status?.code || ''
      };
      return formattedValue;
    })
    .sort((a, b) => {
      return b?.updateDate?.localeCompare(a?.updateDate);
    });
};
