import { ColumnsType } from 'antd/es/table';
import RenderBankLogo from '../components/App/AutoCred/UI/RenderBankLogo';
import { TDataSource } from '../types/dataSource';
//@ts-ignore
import dayjs from 'dayjs';
//@ts-ignore
import React, { FC } from 'react';
import TableDate from '../components/Shared/TableData/TableDate';
import CellInner from '../components/App/AutoCred/UI/CellInner';
import StatusTooltip from '../components/App/AutoCred/UI/StatusTooltip';

const statusStyle = {
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '25px',
  height: '25px',
  borderRadius: '50%'
};

const tooltipTitleStyle = {
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '160%'
};

export const columns: ColumnsType<TDataSource> = [
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    width: 240,
    sorter: (a, b) => a.client.fullName.localeCompare(b.client.fullName),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              background: '#F1F2F4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{ fontSize: '10px', fontWeight: '600', color: '#687588' }}
            >
              {record?.client?.fullName[0] || ''}
            </p>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <CellInner
              title={record?.client?.fullName || ''}
              content={record?.client?.telNumber || ''}
            />
          </div>
        </div>
      );
    }
  },
  {
    title: 'Цена авто',
    dataIndex: 'loan',
    key: 'loan',
    width: 200,
    sorter: (a, b) => a.loan.amount.localeCompare(b.loan.amount),
    render: (text, record) => {
      return record.loan?.amount ? (
        <>
          <div>
            <CellInner
              title={`${record?.loan?.amount} ₸` || ''}
              content={record?.loan?.auto || ''}
            />
          </div>
        </>
      ) : (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
      );
    }
  },
  {
    title: 'Тип заявки',
    dataIndex: 'orderType',
    key: 'orderType',
    width: 170,
    sorter: (a, b) => a.orderType.autoType.localeCompare(b.orderType.autoType),
    render: (text, record) => {
      return record.status.isDraft ? (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
      ) : (
        <>
          <div>
            <CellInner
              title={record?.orderType?.autoType || ''}
              content={record?.dealer.name.replace('(SAP)', '')}
            />
          </div>
        </>
      );
    }
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 170,
    sorter: (a, b) =>
      new Date(a.created_at.date).getTime() -
      new Date(b.created_at.date).getTime(),
    render: (text, record) => {
      return (
        <div>
          <CellInner
            title={dayjs(record.created_at.date).format('DD.MM.YYYY, HH:mm')}
            content={record?.created_at?.author}
          />
        </div>
      );
    }
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    width: 192,
    filters: [
      { text: 'Профинансировано', value: 'financed' },
      { text: 'Одобрено', value: 'approved' },
      { text: 'Ожидают ответа от банка', value: 'waiting' },
      { text: 'Ожидание финансирования', value: 'unverified' },
      { text: 'Отказано', value: 'declined' },
      { text: 'Черновик', value: 'draft' },
    ],
    onFilter: (value, record) => {
      switch (value) {
        case 'financed':
          return record.status.isFinanced;
        case 'selectedBank':
          return record.status.isSelected;
        case 'approved':
          return record.status.approved !== '0';
        case 'waiting':
          return record.status.expectations !== '0';
        case 'accepted_alternative':
          return record.status.approvedAlternatives !== '0';
        case 'declined':
          return record.status.denied !== '0';
        case 'error':
          return record.status.errors !== '0';
        case 'draft':
          return record.status.isDraft;
        case 'unverified':
          return record.status.isUnverified;
        default:
          return false;
      }
    },
    render: (text, record) => {
      return record.status.isFinanced ? (
        <>
          <StatusTooltip
            key={'financed'}
            textColor={'#0CAF60'}
            title={'Профинансировано'}
            titleChildren={
              <>
                <RenderBankLogo selectedBank={record.status.selectedBank} />
                <p
                  style={{
                    color: '#CBD5E0',
                    ...tooltipTitleStyle
                  }}
                >
                  от{' '}
                  {dayjs(record.loan?.financed_date || null).format(
                    'DD.MM.YYYY, HH:mm'
                  )}
                </p>
              </>
            }
          >
            {' '}
            <div
              style={{
                padding: '4px 16px',
                borderRadius: '8px',
                background: '#0CAF60',
                width: '90px'
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: 'white'
                }}
              >
                FINANCED
              </p>
            </div>
          </StatusTooltip>
        </>
      ) : record.status.isSelected ? (
        <>
          <StatusTooltip
            key={'selectedBank'}
            title="Выбран банк"
            titleChildren={
              <>
                <div
                  style={{
                    borderRadius: '50%',
                    padding: '5px',
                    background: '#FFF',
                    width: '27px',
                    height: '27px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '8px'
                  }}
                >
                  <RenderBankLogo selectedBank={record.status.selectedBank} />
                </div>
                <p
                  style={{
                    color: '#CBD5E0',
                    ...tooltipTitleStyle
                  }}
                >
                  {record?.status?.selectedBank === 'eu_bank'
                    ? 'Евразийский Банк'
                    : record?.status?.selectedBank === 'halyk'
                    ? 'Halyk Bank'
                    : record.status.selectedBank === 'bcc'
                    ? 'Банк ЦентрКредит'
                    : record.status.selectedBank === 'shinhan'
                    ? 'Shinhan Банк'
                    : record.status.selectedBank === 'ffb'
                    ? 'Freedom Finance Банк'
                    : record.status.selectedBank === 'forte'
                    ? 'Forte Банк'
                    : ''}
                </p>
              </>
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RenderBankLogo selectedBank={record.status.selectedBank} />
              <div>
                <h2
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#111827'
                  }}
                >
                  {record?.status?.selectedBank === 'eu_bank'
                    ? 'Евразийский Банк'
                    : record?.status?.selectedBank === 'halyk'
                    ? 'Halyk Bank'
                    : record.status.selectedBank === 'bcc'
                    ? 'Банк Центр Кредит'
                    : record.status.selectedBank === 'shinhan'
                    ? 'Shinhan Банк'
                    : record.status.selectedBank === 'ffb'
                    ? 'Freedom Finance Банк'
                    : record.status.selectedBank === 'forte'
                    ? 'Forte Банк'
                    : record.status.selectedBank === 'altyn'
                    ? 'Алтын банк'
                    : ''}
                </h2>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    color: '#687588'
                  }}
                >
                  Банк выбран
                </p>
              </div>
            </div>
          </StatusTooltip>
        </>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StatusTooltip
            isFlex={true}
            textColor="#0CAF60"
            title={'Одобрено предложение'}
            titleChildren={record.responses
              .filter(
                (rec) =>
                  rec.status === 'approved' || rec.status === 'pre_approved'
              )
              .map((res, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '8px',
                      alignItems: 'center'
                    }}
                    key={`${res.status} ${index}`}
                  >
                    <RenderBankLogo selectedBank={res.bank} />
                    <p
                      style={{
                        color: '#CBD5E0',
                        ...tooltipTitleStyle
                      }}
                    >
                      от{' '}
                      {dayjs(res.responseDate || null).format(
                        'DD.MM.YYYY, HH:mm'
                      )}
                    </p>
                  </div>
                );
              })}
          >
            <div
              style={
                record.status.approved === '0'
                  ? { display: 'none' }
                  : {
                      ...statusStyle,
                      background: '#0CAF60'
                    }
              }
            >
              <p
                style={{ fontSize: '10px', fontWeight: '600', color: 'white' }}
              >
                {record?.status?.approved || ''}
              </p>
            </div>
          </StatusTooltip>
          <StatusTooltip
            key="waiting"
            isFlex={true}
            textColor="#FFD023"
            title={'Ожидают ответа'}
            titleChildren={
              <>
                <TableDate date={record.created_at.date} key="waiting-date" />
                {record.responses
                  .filter(
                    (rec) =>
                      rec.status === 'waiting_bank_response' ||
                      rec.status === 'waiting_of_financing' ||
                      rec.status === 'on_verification'
                  )
                  .map((res, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '8px',
                          alignItems: 'center'
                        }}
                        key={`${res.status} ${index}`}
                      >
                        <RenderBankLogo
                          selectedBank={res.bank}
                          key="waiting-logo"
                        />
                        <p
                          key="content"
                          style={{
                            color: '#CBD5E0',
                            ...tooltipTitleStyle
                          }}
                        >
                          от{' '}
                          {dayjs(
                            res.responseDate || res.createDate || null
                          ).format('DD.MM.YYYY, HH:mm')}
                        </p>
                      </div>
                    );
                  })}
              </>
            }
          >
            <div
              key="waiting-content"
              style={
                record.status.expectations === '0'
                  ? { display: 'none' }
                  : {
                      ...statusStyle,
                      background: '#FFD023'
                    }
              }
            >
              <p
                key="waiting-cont"
                style={{
                  fontSize: '10px',
                  fontWeight: '600',
                  color: '#111827'
                }}
              >
                {record?.status?.expectations || ''}
              </p>
            </div>
          </StatusTooltip>
          <StatusTooltip
            title="Одобрена альтернатива"
            isFlex={true}
            textColor="#2F78EE"
            titleChildren={record.responses
              .filter((rec) => rec.status === 'accepted_alternative')
              .map((res, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '8px',
                      alignItems: 'center'
                    }}
                    key={`${res.status} ${index}`}
                  >
                    <RenderBankLogo selectedBank={res.bank} />
                    <p
                      style={{
                        color: '#CBD5E0',
                        ...tooltipTitleStyle
                      }}
                    >
                      от{' '}
                      {dayjs(res.responseDate || null).format(
                        'DD.MM.YYYY, HH:mm'
                      )}
                    </p>
                  </div>
                );
              })}
          >
            <div
              style={
                record.status.approvedAlternatives === '0'
                  ? { display: 'none' }
                  : {
                      ...statusStyle,
                      background: '#2F78EE'
                    }
              }
            >
              <p
                style={{
                  fontSize: '10px',
                  fontWeight: '600',
                  color: 'white'
                }}
              >
                {record?.status?.approvedAlternatives || ''}
              </p>
            </div>
          </StatusTooltip>
          <StatusTooltip
            textColor="#E03137"
            isFlex={true}
            title="Отклонено"
            titleChildren={record.responses
              .filter((rec) => rec.status === 'declined')
              .map((res, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '8px',
                      alignItems: 'center'
                    }}
                    key={`${res.status} ${index}`}
                  >
                    <RenderBankLogo selectedBank={res.bank} />
                    <p
                      style={{
                        color: '#CBD5E0',
                        ...tooltipTitleStyle
                      }}
                    >
                      от{' '}
                      {dayjs(res.responseDate || null).format(
                        'DD.MM.YYYY, HH:mm'
                      )}
                    </p>
                  </div>
                );
              })}
          >
            <div
              style={
                record.status.denied === '0'
                  ? { display: 'none' }
                  : {
                      ...statusStyle,
                      background: '#E03137'
                    }
              }
            >
              <p
                style={{ fontSize: '10px', fontWeight: '600', color: 'white' }}
              >
                {record?.status?.denied}
              </p>
            </div>
          </StatusTooltip>
          <StatusTooltip
            isFlex={true}
            textColor="#A8A8A8"
            title="Ошибка банка"
            titleChildren={record.responses
              .filter((rec) => rec.status === 'error')
              .map((res, index) => {
                return (
                  <div
                    key={`${res.status} ${index}`}
                    style={{
                      display: 'flex',
                      marginTop: '8px',
                      alignItems: 'center'
                    }}
                  >
                    <RenderBankLogo selectedBank={res.bank} />
                    <p
                      style={{
                        color: '#CBD5E0',
                        ...tooltipTitleStyle
                      }}
                    >
                      от{' '}
                      {dayjs(res.responseDate || null).format(
                        'DD.MM.YYYY, HH:mm'
                      )}
                    </p>
                  </div>
                );
              })}
          >
            <div
              style={
                record.status.errors === '0'
                  ? { display: 'none' }
                  : {
                      ...statusStyle,
                      background: '#a8a8a8'
                    }
              }
            >
              <p
                style={{ fontSize: '10px', fontWeight: '600', color: 'white' }}
              >
                {record?.status?.errors}
              </p>
            </div>
          </StatusTooltip>
          <StatusTooltip title={'В процессе оформления'}>
            <div
              style={
                !record.status.isDraft
                  ? { display: 'none' }
                  : {
                      padding: '4px 16px',
                      borderRadius: '8px',
                      background: 'rgb(17 24 39)',
                      width: '90px'
                    }
              }
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: 'white'
                }}
              >
                ЧЕРНОВИК
              </p>
            </div>
          </StatusTooltip>
          <StatusTooltip title={'В процессе валидации ОТП'}>
            <div
              style={
                !record.status.isUnverified
                  ? { display: 'none' }
                  : {
                      padding: '4px 16px',
                      borderRadius: '8px',
                      background: 'rgb(2,61,61)',
                      width: '120px'
                    }
              }
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '500',
                  color: 'white'
                }}
              >
                ВАЛИДАЦИЯ ОТП
              </p>
            </div>
          </StatusTooltip>
          <div
            style={
              record.responsesAmount !== 0 ||
              record.status.isUnverified ||
              record.status.isDraft
                ? { display: 'none' }
                : {
                    ...statusStyle,
                    background: '#FFD023'
                  }
            }
          >
            <p
              style={{
                fontSize: '10px',
                fontWeight: '600',
                color: 'rgb(17 24 39)'
              }}
            >
              {/*Ультра харкод 4*/}4
            </p>
          </div>
        </div>
      );
    }
  }
];
