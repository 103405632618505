import { Button, Collapse } from 'antd';
import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { infoIcon, printerLogo, REGISTRATION_DUTY_COST } from '@/constants';
import { validateNumberInputValue } from '@/services';

export type TCalculationAnuetetResltsProps = {
  calculationData: {
    rate: number;
    kaskoPerYear: number;
    loanAmount: number;
    totalLoanAmount: number;
    monthlyPaymentAnnuity: number;
    loanOverpaymentAnnuity: number;
  } | null;
};

const { Panel } = Collapse;
const CalculationAnuetetResltItem = ({
  calculationData
}: TCalculationAnuetetResltsProps) => {
  return (
    <Collapse
      defaultActiveKey={1}
      expandIconPosition="right"
      expandIcon={({ isActive }) =>
        isActive ? (
          <MinusOutlined
            style={{
              width: '20px',
              height: '20px'
            }}
          />
        ) : (
          <PlusOutlined
            style={{
              width: '20px',
              height: '20px'
            }}
          />
        )
      }
    >
      <Panel
        key={1}
        header={
          <div className="Collapse_header">
            <div className="image_wrapper">
              <img src={infoIcon} alt="bank_logo" />
            </div>
            <p>Средний расчет кредита</p>
          </div>
        }
      >
        <div className="main_info">
          <div>
            <h2>Ежемесячный платеж</h2>
            <p>
              {validateNumberInputValue(
                calculationData?.monthlyPaymentAnnuity?.toString() || '',
                10,
                true
              ) || ''}{' '}
              ₸
            </p>
          </div>
          <div>
            <h2>Ставка</h2>
            <p>
              <span>{calculationData?.rate || ''}%</span>
            </p>
          </div>
        </div>
        <div className="other_info_container">
          <div>
            <p>Стоимость КАСКО/год</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                calculationData?.kaskoPerYear?.toString() || '',
                10,
                true
              ) || ''}{' '}
              ₸
            </h2>
          </div>
          <div>
            <p>Сумма займа</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                calculationData?.loanAmount?.toString() || '',
                10,
                true
              ) || ''}{' '}
              ₸
            </h2>
          </div>
          <div>
            <p>Сумма переплаты с учетом КАСКО</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                calculationData?.loanOverpaymentAnnuity?.toString() || '',
                10,
                true
              ) || ''}{' '}
              ₸
            </h2>
          </div>
          <div>
            <p>Стоимость регистрации ТС</p>
            <h2>
              {' '}
              {validateNumberInputValue(
                REGISTRATION_DUTY_COST.toString(),
                10,
                true
              )}{' '}
              ₸
            </h2>
          </div>
        </div>
        <Button>
          <img src={printerLogo} alt="print" />
          Распечатать
        </Button>
      </Panel>
    </Collapse>
  );
};

export default CalculationAnuetetResltItem;
