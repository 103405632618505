import React, { useEffect, useState } from 'react';
import '@/styles/EurasBank.scss';
import { Button, Collapse, Spin } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { TFileAttached } from '@/types/financing';
import { TDocumentsOnModeration } from '@/types/uploadFiles';
import { useAppSelector } from '@/store';
import { CustomSwitch } from '@/components/Shared/Switch/CustomSwitch';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import {
  eblankOfferFinancedItems,
  financingItems
} from '@/data/financing.data';
import { checkArrayValues } from '@/services/validators/checkArrayValues';

export type TEurasBankProps = {
  // changeEurasValidStatus: (isValid: boolean) => void;
  infoTS: boolean;
  requestLoading: boolean;
  handleSendDocs: (uploadedFiles: any) => void;
  attachedFiles: TFileAttached[];
  isPledgeRegister: boolean;
  setPledgeRegister: React.Dispatch<React.SetStateAction<boolean>>;
  documentsOnModeration: TDocumentsOnModeration[];
  isOfferFinanced: boolean;
  isPledgeMissing: boolean;
  updateEblankStatus: () => void;
};
export const EurasBank = ({
  infoTS,
  // changeEurasValidStatus,
  documentsOnModeration,
  requestLoading,
  attachedFiles,
  isPledgeMissing,
  handleSendDocs,
  isPledgeRegister,
  setPledgeRegister,
  isOfferFinanced,
  updateEblankStatus
}: TEurasBankProps) => {
  const {
    newOrder: { orderApp }
  } = useAppSelector((state) => state);
  const [files, setFiles] = useState<{
    firstStepFiles: TFileAttached[];
    secondStepFiles: TFileAttached[];
    thirdStepFiles: TFileAttached[];
    financedStepFile: TFileAttached[];
  }>({
    firstStepFiles: [],
    secondStepFiles: [],
    thirdStepFiles: [],
    financedStepFile: []
  });
  const [uploadFiles, setUploadFiles] = useState({});
  // const [isPledgeRegister, setPledgeRegister] = useState<boolean>(false);
  const [attachedFilesLinks, setAttachedFilesLinks] = useState<{
    [key: string]: string;
  }>({
    LGRP: '#',
    THSG: '#',
    LNBA: '#',
    DGZL: '#',
    LNGA: '#',
    DOPU: '#'
  });

  const [isSendAvailable, setIsSendAvailable] = useState<boolean>(false);

  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({
      ...prevState,
      [type]: { name: file?.name, base64: fileBase64 }
    }));
  };

  useEffect(() => {
    const isValid = Object.values(uploadFiles).length
      ? checkArrayValues(Object.values(uploadFiles))
      : false;
    setIsSendAvailable(isValid);
  }, [uploadFiles]);

  useEffect(() => {
    const attachedFilesLinksHandler: { [key: string]: string } = {};
    attachedFiles.forEach((file) => {
      if (file.type) {
        attachedFilesLinksHandler[file.type] = file.link;
      }
    });
    setAttachedFilesLinks(attachedFilesLinksHandler);
    const firstStepFilesHandler: TFileAttached[] = [];
    const secondStepFilesHandler: TFileAttached[] = [];
    const thirdStepFilesHandler: TFileAttached[] = [];
    const financedStepFilesHandler: TFileAttached[] = [];
    attachedFiles.forEach((file) => {
      switch (file.type) {
        case 'THSG':
        case 'LNGA':
          firstStepFilesHandler.push(file);
          break;
        case 'LGRP':
        case 'DOPU':
          secondStepFilesHandler.push(file);
          break;
        case 'LNBA':
        case 'DGZL':
          thirdStepFilesHandler.push(file);
          break;
        case 'LGRP':
        case 'INSW':
          financedStepFilesHandler.push(file);
          break;
      }
    });
    setFiles({
      firstStepFiles: firstStepFilesHandler,
      secondStepFiles: secondStepFilesHandler,
      thirdStepFiles: thirdStepFilesHandler,
      financedStepFile: financedStepFilesHandler
    });
  }, [attachedFiles]);

  return (
    <div className="eurasBank">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <CustomSwitch
          value={isPledgeRegister}
          label={'pledgeRegister'}
          fieldsReadOnly={true}
          type={'eurasBank'}
          labelTitle={'Регистрация залога'}
          onchangeCheckedHandler={() => setPledgeRegister(!isPledgeRegister)}
        />
        <Button onClick={updateEblankStatus}>
          <ReloadOutlined />
          Обновить статус
        </Button>
      </div>
      <div style={{ textAlign: 'left' }}>
        {documentsOnModeration.length > 0 ? (
          <>
            <h2>Документы для переотправки:</h2>
            {documentsOnModeration.map((doc) => (
              <div key={doc.documentType}>
                {doc?.documentDescription || 'Все документы'}
              </div>
            ))}
          </>
        ) : null}
      </div>
      {files.firstStepFiles.map(
        (file, index) =>
          index < 2 && (
            <>
              <FileDownload
                key={file.type}
                prevStateTitle={
                  file.type === 'THSG'
                    ? 'Трехстороннее соглашение'
                    : file.type === 'LNGA'
                    ? 'Анкета'
                    : ''
                }
                fileLink={file.uuid}
              />
            </>
          )
      )}

      {infoTS && (
        <>
          {files.secondStepFiles.map(
            (file, index) =>
              index < 2 && (
                <>
                  <FileDownload
                    key={file.type}
                    prevStateTitle={
                      file.type === 'LGRP'
                        ? 'График погашения'
                        : file.type === 'DOPU'
                        ? 'Дополнительные услуги'
                        : ''
                    }
                    fileLink={file.uuid}
                  />
                </>
              )
          )}
          <div className="cols">
            <div className="col">
              {files.thirdStepFiles.map(
                (file, index) =>
                  index < 2 && (
                    <>
                      <FileDownload
                        key={file.type}
                        prevStateTitle={
                          file.type === 'LNBA'
                            ? 'Договор банковского займа'
                            : file.type === 'DGZL'
                            ? 'Договор залога'
                            : file.type === 'DOPU'
                            ? 'Дополнительные услуги'
                            : file.type === 'LGRP'
                            ? 'График погашения'
                            : ''
                        }
                        fileLink={file.uuid}
                      />
                    </>
                  )
              )}
            </div>
            <div className="col">
              <FileUpload
                customeStyles={{ margin: '15px 0' }}
                positionLeft={'55px'}
                marginLeft={'40px'}
                selectedmargin={'38px'}
                onFileChange={handleFileChange}
                title={'Договор залога (подписан)'}
                type={'SDGZ'}
                preventActions={false}
              />
              <FileUpload
                customeStyles={{ margin: '15px 0' }}
                positionLeft={'55px'}
                marginLeft={'40px'}
                selectedmargin={'38px'}
                onFileChange={handleFileChange}
                title={'Договор банковского займа (подписан)'}
                type={'SLNB'}
                preventActions={false}
              />
            </div>
          </div>
          {/*Показивать инпуты в зависимости от степа ебланка офера ыЫыЫыыЫЫЫыы*/}
          {isPledgeMissing
            ? eblankOfferFinancedItems.map((item) => {
                return (
                  <div className="Euras_files_upload_root" key={item.key}>
                    <p className="field_required">Обязательно к заполнению</p>
                    <FileUpload
                      customeStyles={{ margin: '15px 0' }}
                      positionLeft={'55px'}
                      isLg={true}
                      marginLeft={'39px'}
                      selectedmargin={'5.5%'}
                      //@ts-ignore
                      file={uploadFiles[item.key]}
                      onFileChange={handleFileChange}
                      title={item.label}
                      type={item.key}
                      preventActions={false}
                    />
                  </div>
                );
              })
            : !isOfferFinanced
            ? financingItems.map((item) => {
                let isFieldRequired = true;

                switch (item.label) {
                  case 'Заявление согласия (внесудебка супруга)':
                  case 'УДВ супруги':
                    //Код холоста - 2 сууууууукааааааааааааааааа бляяяяяяяяяя заебал тупой ебланк
                    const isLoneFagot =
                      orderApp?.personalInfo?.familyStatus === 2;
                    if (isLoneFagot) isFieldRequired = false;
                    break;
                  case 'Страхование КАСКО (подписан)':
                    const isEblankKaskoSelected = !!(
                      orderApp?.productInfo?.additionalProducts &&
                      orderApp?.productInfo?.additionalProducts?.filter(
                        (prod) =>
                          prod.checked && prod.label === 'Каско СК "Евразия"'
                      ).length > 0
                    );
                    if (!isEblankKaskoSelected) isFieldRequired = false;
                    break;
                  case 'Квитанция ПВ':
                  case 'Удостоверение личности':
                  case 'Отсутствие налоговой задолженности':
                  case 'Тех. паспорт':
                  case 'Заявление о включении суммы страховой премии в сумму получаемого банковского займа':
                  case 'Свидетельство о государственной регистрации залогового движимого имущества':
                    if (isPledgeRegister) isFieldRequired = false;
                    break;

                  case 'Заявление на предоставление займа на оплату стоимости услуги и включение стоимости услуги в сумму запрашиваемого банковского займа':
                    isFieldRequired = false;
                    break;
                }

                return (
                  <div className="Euras_files_upload_root" key={item.key}>
                    {isFieldRequired ? (
                      <p className="field_required">Обязательно к заполнению</p>
                    ) : (
                      <p className="field_not_required">
                        Необязательно к заполнению
                      </p>
                    )}
                    <FileUpload
                      customeStyles={{ margin: '15px 0' }}
                      positionLeft={'55px'}
                      isLg={true}
                      marginLeft={'39px'}
                      selectedmargin={'5.5%'}
                      onFileChange={handleFileChange}
                      title={item.label}
                      type={item.key}
                      preventActions={false}
                    />
                  </div>
                );
              })
            : files.financedStepFile.map(
                (file, index) =>
                  index < 2 && (
                    <>
                      <FileDownload
                        key={file.type}
                        prevStateTitle={
                          file.type === 'INSW'
                            ? 'Страхование от потери работ'
                            : file.type === 'LGRP'
                            ? 'График погашения'
                            : ''
                        }
                        fileLink={file.uuid}
                      />
                    </>
                  )
              )}
          <Collapse
            items={[
              {
                key: '1',
                label: (
                  <p style={{ textAlign: 'start' }}>Прикрепленные файлы</p>
                ),
                children: (
                  <>
                    {attachedFiles?.length ? (
                      attachedFiles
                        .filter((item, index, self) => {
                          const isFirstOccurrence =
                            index ===
                            self.findIndex(
                              (t) =>
                                t.name === item.name && t.type === item.type
                            );
                          const isValidType =
                            item.type !== 'otherDoc' &&
                            item.type !== 'clientPhoto' &&
                            item.type !== 'docPhoto' &&
                            item.type !== 'PaymentOrderHalyk' &&
                            item.type !== 'incomeCert';

                          return isFirstOccurrence && isValidType;
                        })
                        .map((data) => {
                          return (
                            <FileDownload
                              prevStateTitle={
                                data.type === 'THSG'
                                  ? 'Трехстороннее соглашение'
                                  : data.type === 'LNGA'
                                  ? 'Анкета'
                                  : data.type === 'LGRP'
                                  ? 'График погашения'
                                  : data.type === 'DOPU'
                                  ? 'Дополнительные услуги'
                                  : data.type === 'LNBA'
                                  ? 'Договор банковского займа'
                                  : data.type === 'DGZL'
                                  ? 'Договор залога'
                                  : data.type === 'DOPU'
                                  ? 'Дополнительные услуги'
                                  : data.type === 'LGRP'
                                  ? 'График погашения'
                                  : data.type === 'SDGZ'
                                  ? 'Договор залога (подписан)'
                                  : data.type === 'SLNB'
                                  ? 'Договор банковского займа (подписан)'
                                  : data.type === 'sog'
                                  ? 'Согласие на обработку персональных данных'
                                  : data.type === 'TEHP'
                                  ? 'Тех. Паспорт'
                                  : data.type === 'DSCR'
                                  ? 'Справка об отсутсвии (наличии) задолжностей'
                                  : data.type === 'EM080'
                                  ? 'Заявление о включении страхования'
                                  : data.type === 'IDCD'
                                  ? 'УДВ'
                                  : data.type === 'KVPV'
                                  ? 'Фискальный чек'
                                  : data.type === 'VNES'
                                  ? 'Согласие на предоставление в залог'
                                  : data.type === 'EM079'
                                  ? 'Заявление о включении страхования (подписанная)'
                                  : data.type === 'AREST'
                                  ? 'Резервирование авто'
                                  : data.type
                              }
                              fileLink={data.uuid}
                              key={data.uuid}
                            />
                          );
                        })
                    ) : (
                      <p>Нету прикрепленных файлов</p>
                    )}
                  </>
                )
              }
            ]}
          />

          <button
            className="sendBtn"
            onClick={() => handleSendDocs(uploadFiles)}
            disabled={!isSendAvailable}
          >
            {!requestLoading ? (
              'Отправить документы'
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            )}
          </button>

          {/*{showSendButton ? (*/}
          {/*  <button*/}
          {/*    className="sendBtn"*/}
          {/*    onClick={() => handleSendDocs(uploadFiles)}*/}
          {/*    disabled={!isSendAvailable}*/}
          {/*  >*/}
          {/*    {!requestLoading ? (*/}
          {/*      'Отправить документы'*/}
          {/*    ) : (*/}
          {/*      <Spin*/}
          {/*        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </button>*/}
          {/*) : null}*/}
        </>
      )}
    </div>
  );
};
