import { TCreateLitroDto } from '@/types/litro.type';

export const validateLitroInfo = (data: TCreateLitroDto) => {
  if (
    !data.car_year ||
    !data.model_uuid ||
    !data.brand_uuid ||
    !data.car_year ||
    !data.period_code ||
    !data.payment_type_code ||
    data.vin.length < 17 ||
    (data.car_registration && data.car_registration.length < 8)
  ) {
    return true;
  } else {
    return false;
  }
};
