import React, { FC, useEffect, useState } from 'react';
import { setShowRolesActionModal } from '@/store/admin/admin.slice';
import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { TRoleAction } from '@/types/roleAction.type';
import {
  createRolesAction,
  getAllPositions,
  updateRolesAction
} from '@/store/admin/adminUsers.slice';

type TRolesActionProps = {
  editMode: boolean;
  rolesActionItem: TRoleAction | null;
};
export const CreateOrEditRolesActionModal: FC<TRolesActionProps> = ({
  editMode,
  rolesActionItem
}) => {
  const dispatch = useAppDispatch();
  const { showRolesActionModal } = useAppSelector((state) => state.admin);
  const { positions } = useAppSelector((state) => state.adminUsers);
  const [positionsOption, setPositionsOption] = useState<
    { value: string; label: string }[]
  >([]);

  const [rolesAction, setRolesAction] = useState<TRoleAction>(
    {} as TRoleAction
  );
  const [deleteList, setDeleteList] = useState<string[]>([]);

  useEffect(() => {
    if (editMode && rolesActionItem) {
      setRolesAction(() => ({ ...rolesActionItem }));
      if (rolesActionItem.Position_Roles_Actions) {
        const data = rolesActionItem.Position_Roles_Actions.map(
          (item) => item.position_uuid
        );

        setDeleteList(data);
        setRolesAction((prevState) => ({
          ...prevState,
          Positions_UUID: data
        }));
      }
    }
    dispatch(getAllPositions());
    const positionsData = positions.map((position) => ({
      value: position.uuid!,
      label: position.name
    }));
    setPositionsOption(positionsData);
  }, []);

  useEffect(() => {}, [deleteList]);

  const onOk = () => {
    if (editMode) {
      const filteredList = deleteList.filter(
        (item) => !rolesAction.Positions_UUID!.includes(item)
      );
      dispatch(
        updateRolesAction({
          ...rolesAction,
          Delete_Position_Roles_Action: deleteList
        })
      );
      return dispatch(setShowRolesActionModal(false));
    }
    dispatch(createRolesAction(rolesAction));
    return dispatch(setShowRolesActionModal(false));
  };

  return (
    <Modal
      className="modal"
      open={showRolesActionModal}
      onCancel={() => dispatch(setShowRolesActionModal(false))}
      onOk={onOk}
    >
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Row>
              <Col span={18}>
                <Form.Item label="Наименование" required>
                  <Input
                    value={rolesAction.name}
                    onChange={(e) =>
                      setRolesAction((prevState) => ({
                        ...prevState,
                        name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Код" required>
                  <InputNumber
                    controls={false}
                    value={rolesAction.code}
                    onChange={(e) =>
                      setRolesAction((prevState) => ({
                        ...prevState,
                        code: e!
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={16} offset={2}>
                <Form.Item label="Описание">
                  <Input.TextArea
                    value={rolesAction.description}
                    onChange={(e) =>
                      setRolesAction((prevState) => ({
                        ...prevState,
                        description: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Позиции">
                  <Select
                    mode="multiple"
                    value={rolesAction.Positions_UUID}
                    maxTagCount={1}
                    options={positionsOption}
                    onChange={(value) =>
                      setRolesAction((prevState) => ({
                        ...prevState,
                        Positions_UUID: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
