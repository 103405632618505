import { Avatar, Badge, Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import NotificationsSideBar from '../Notifications/NotificationsSideBar';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getActiveDealers,
  getOptimizedDictionaries,
  getProductInsurance,
  getProductsDictionary
} from '@/store/dict.slice';
import { useAppDispatch, useAppSelector } from '@/store';
import { decodeJWT } from '@/services/jwtDecoder';
import { getPageTitle } from '@/services';
import { setNewOrderToInitial } from '@/store/newOrder.slice';
import { onToggleShowNotifications } from '@/store/notifications.slice';
import { pathBellIcon } from '@/constants';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pageTitle } = useAppSelector((state) => state.newOrder);
  const { lastNotificationsChecked } = useAppSelector(
    (state) => state.notifications
  );
  const dealer = useAppSelector((state) => state.userInfo.dealer);
  const [user, setUser] = useState<{
    lastName: string;
    firstName: string;
    actions: number[];
  }>({
    firstName: '',
    lastName: '',
    actions: []
  });

  const items: MenuProps['items'] = [
    {
      key: 'personal',
      label: <p>Личный кабинет</p>
    },
    ...(user.actions.includes(0)
      ? [
          {
            key: 'adminPage',
            label: (
              <p>
                {/*<a style={{ color: 'rgba(0, 0, 0, 0.88)' }} href="https://google.com">*/}
                Панель администратора
                {/*</a>*/}
              </p>
            )
          }
        ]
      : []),
    {
      key: 'logout',
      label: <p>Выйти</p>
    }
  ];

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const data = decodeJWT(accessToken || '');
      setUser((prevState) => ({
        ...prevState,
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        actions: data?.actions || []
      }));
      dispatch(getOptimizedDictionaries());
      dispatch(getProductsDictionary());
      dispatch(getActiveDealers());
      dispatch(getProductInsurance());
    }
  }, []);

  const onClickItems: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userSearchType');
      localStorage.removeItem('isRemember');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('emailAllur');
      localStorage.removeItem('paginationInfo');
      localStorage.removeItem('dealer');
      localStorage.removeItem('isAuthenticated');
      window.location.reload();
      navigate('/');
    } else {
      navigate(`/${key}`);
    }
  };

  return (
    <header>
      <div
        className="title"
        style={location.pathname === '/newApp' ? { marginLeft: '45px' } : {}}
      >
        <h2>{getPageTitle(location.pathname)}</h2>

        {/*Стрелка назад*/}
        {location.pathname === '/newApp' ||
        location.pathname === '/clientRequestsDetails' ||
        location.pathname === '/new-insurance' ||
        location.pathname === '/calculator' ? (
          <p
            onClick={() => {
              dispatch(setNewOrderToInitial());
              navigate(-1);
            }}
          >
            Назад
          </p>
        ) : null}

        {/*  город в котором работает?*/}
        {location.pathname === '/' && <h2 className="city_info">г. Алматы</h2>}
      </div>
      <div className="pagination">
        <p style={{ marginRight: '10px' }}>{dealer?.label}</p>
        <div className="lang">RU</div>
        <Badge dot={!lastNotificationsChecked} offset={[-10, 4]}>
          <button
            className="bell_btn"
            onClick={() => dispatch(onToggleShowNotifications())}
          >
            <img src={pathBellIcon} alt="bell_icon" />
          </button>
        </Badge>
        <Dropdown
          menu={{ items, onClick: onClickItems }}
          placement="bottomRight"
          arrow
        >
          <Space style={{ cursor: 'pointer' }}>
            <Avatar style={{ backgroundColor: 'black' }}>
              {user.firstName[0]?.toUpperCase()}
              {user.lastName[0]?.toUpperCase()}
            </Avatar>{' '}
            <p className="user_name">
              {user.firstName} {user.lastName}
            </p>
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
      <NotificationsSideBar />
    </header>
  );
};
