import { TAutoInsuranceInfo } from '@/types/avtoInsuranceInfo';
import { TNewInsuranceDto } from '@/types/newInsurance';
import { TPersonInsuranceInfo } from '@/types/personInfo';


export const validatePersonalInsuranceInfo = (
  data: TPersonInsuranceInfo
): boolean => {
  if (
    data.firstName &&
    data.lastName &&
    data.adress &&
    data.placeBirth &&
    data.numberdocument &&
    data.dateDocument &&
    data.validity
  ) {
    return true;
  }

  return false;
};

export const validateAutoInsuranceInfo = (
  data: TAutoInsuranceInfo
): boolean => {
  if (
    data.priseAvto &&
    data.tehNum &&
    data.dateOfTechPasspord &&
    data.numberGos &&
    data.vinCode
  ) {
    return true;
  }

  return false;
};
