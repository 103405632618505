import { TSelectItem } from '../types/selectsItem';
import { fileDict } from './files.dicts';

export const eblankOfferFinancedItems: TSelectItem[] = [
  {
    key: 'AREST',
    label: fileDict.AREST,
    editable: false,
    type: 'fileUpload'
  }
];
export const financingItems: TSelectItem[] = [
  // {
  //   key: 'KASKO',
  //   label: fileDict.KASKO,
  //   editable: false,
  //   type: 'fileUpload'
  // },
  {
    key: 'TEHP',
    label: fileDict.TEHP,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'DSCR',
    label: fileDict.DSCR,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'VNES',
    label: fileDict.VNES,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'EM079',
    label: fileDict.EM079,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'EM080',
    label: fileDict.EM080,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'IDCD',
    label: fileDict.IDCD,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'doc47',
    label: fileDict.doc47,
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'KVPV',
    label: fileDict.KVPV,
    editable: false,
    type: 'fileUpload'
  }
];

export const berekeItems: TSelectItem[] = [
  {
    key: 'bankLoanAgreement',
    label: 'Договор банковского займа',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'paymentOrder',
    label: 'Платежное поручение',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'memoToDBZ',
    label: 'Памятка к ДБЗ',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'solution',
    label: 'Решение',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'consentToData',
    label: 'Согласие на сбор и обработку данных',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'letterDVD',
    label: 'Письмо в ДВД',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'paymentOrderInsurance',
    label: 'Платежное поручение (Страхование)',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'loanApplicationForm',
    label: 'Анкета-заявление на получение кредита',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'applicationAccidentInsurance',
    label: 'Заявление на страхование от несчастных случаев заемщика',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'agreementAccidentInsuranc',
    label: 'Договор страхования заемщика от несчастных случаев',
    editable: false,
    type: 'fileUpload'
  },
  {
    key: 'agreementOpenAccount',
    label: 'Договор на открытие и обслуживание текущего счета',
    editable: false,
    type: 'fileUpload'
  }
];
