import { AxiosInstance } from 'axios';
import { TClientServerResponse } from '@/types/clients';

export class ClientsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllClients(
    limit?: number,
    page?: number,
    param?: string
  ): Promise<{
    total: number;
    clients: TClientServerResponse[];
    count: number;
    page_count: number;
  }> {
    let path = `admin/clients/getClients/${param ? param : ''}?`;
    // if (param) {
    //   path = path + `&clientParam=${param}`;
    // }
    if (limit) {
      path = path + `&limit=${limit}`;
    }
    if (page) {
      path = path + `&page=${page}`;
    }
    const res = await this.api.get(path);
    return res.data;
  }

  public async getAdminClientByIin(
    iin: string
  ): Promise<TClientServerResponse> {
    const res = await this.api.get(`admin/clients/${iin}/findByIin`);
    return res.data;
  }
}
