import '@/styles/CustomCheckbox.scss';

export type TCustomCheckbox = {
  checked: boolean;
  children?: JSX.Element;
  editClass?: string;
  fieldsReadOnly?: boolean;
  onChange: (data?: any) => void;
};

const CustomCheckbox = ({
  checked,
  onChange,
  children,
  editClass = ''
}: TCustomCheckbox) => {
  return (
    <label className={`custom_checkbox_root ${editClass}`}>
      <input onChange={onChange} type="checkbox" checked={checked} />
      <span className="checkmark"></span>
      {children}
    </label>
  );
};

export default CustomCheckbox;
