import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BankMainPrintItem } from './PrintBanksItem/BankMainPrintItem';
import { TBankForPrint } from '@/types/offers';
import { useAppSelector } from '@/store';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';

export type TPrintModalProps = {
  showPrintModal: boolean;
  isPrintLoading: boolean;
  handlePrint: (dataToPrint: TBankForPrint[]) => void;
  onCancel: () => void;
};

export const PrintModal = ({
  showPrintModal,
  onCancel,
  isPrintLoading,
  handlePrint
}: TPrintModalProps) => {
  const { banksOffers } = useAppSelector((state) => state.offerStep);
  // const {
  //   offerStep: { banksOffers }
  // } = useAppSelector((state) => state);
  const [banksForPrints, setBanksForPrints] = useState<TBankForPrint[]>([]);

  const changeBankPrint = (bankId: string) => {
    const arrayHandler = [...banksForPrints];
    const index = arrayHandler.findIndex(
      (bankToPrint) => bankToPrint.bank.id === bankId
    );
    arrayHandler[index].doPrint = !arrayHandler[index].doPrint;
    setBanksForPrints(arrayHandler);
  };

  const changeSubPrint = (bunkId: string) => console.log(bunkId);

  useEffect(() => {
    const arrayHandler = banksOffers
      ? [...banksOffers].map((bank) => ({ bank: bank, doPrint: false }))
      : [];
    setBanksForPrints(arrayHandler);
  }, []);

  return (
    <CustomModal
      isModalOpen={showPrintModal}
      handleOk={onCancel}
      handleCancel={onCancel}
      title="Распечатать предложения"
      editClass="print_modal"
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          className="confirm_offer"
          key="submit"
          type="primary"
          onClick={() => {
            handlePrint(banksForPrints);
          }}
          disabled={isPrintLoading}
        >
          {!isPrintLoading ? (
            'Подтвердить'
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
        </Button>
      ]}
    >
      <div>
        <p className="print_modal_instruction">
          Выберите предложения от банков, чтобы распечатать клиенту
        </p>
        {banksForPrints.map((dataToPrint) => {
          return (
            <BankMainPrintItem
              onChangePrint={() => {
                changeBankPrint(dataToPrint.bank.id);
              }}
              key={dataToPrint.bank.id}
              printChecked={dataToPrint.doPrint}
              bankInfo={dataToPrint.bank}
              onChangeSubPrint={changeSubPrint}
            />
          );
        })}
      </div>
    </CustomModal>
  );
};
