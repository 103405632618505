export const fileDict = {
  LNBA: 'Договор банковского займа',
  KASKO: 'Страхование КАСКО (подписан)',
  LNGA: 'Анкета',
  THSG: 'Трех стороннее соглашение',
  TEHP: 'Тех. паспорт',
  DSCR: 'Отсутствие налоговой задолженности',
  AREST:
    'Свидетельство о государственной регистрации залогового движимого имущества',
  ANKET: 'Заявление на страхование',
  DOPU: 'Дополнительные услуги',
  KVPV: 'Квитанция ПВ',
  IDCD: 'Удостоверение личности',
  LGRP: 'График погашения',
  NOK: 'NOK',
  INSW: 'Страхование от потери работ',
  EM079:
    'Заявление о включении суммы страховой премии в сумму получаемого банковского займа',
  DECISION: 'Решение',
  DGZL: 'Договор залога',
  VNES: 'Заявление согласия (внесудебка супруга)',
  NOMARI: 'Отсутствие супруга',
  SLNB: 'Договор банковского займа (подписан)',
  SDGZ: 'Договор залога (подписан)',
  MARI: 'Согласие супруга',
  EM080:
    'Заявление на предоставление займа на оплату стоимости услуги и включение стоимости услуги в сумму запрашиваемого банковского займа',
  doc47: 'УДВ супруги',
  POLES: 'Договор страхования',
  LETER_DVD: 'Письмо в ДВД',
  AGREEMENT: 'Согласие на сбор и обработку данных',
  AGREEMENT_OTP: 'Согласие на сбор и обработку данных (АвтоОнлайн)',
  ACCOUNT_CREATE: 'Договор на открытие и обслуживание текущего счета',
  LETTER_AUTOPARTNER: 'Письмо в Автосалон',
  QUESTIONARY: 'Анкета - заявление на получение кредита',
  DECISION_REF: 'Решение (Авто-рефинансирование)',
  DBZ_REF: 'Договор банковского займа (Рефинансирование)',
  QUESTIONARY_REF: 'Анкета - Заявление на получение кредита(рефинансирование)',
  PLEDGE_CONTRACT_REF: 'Договор Залога (Рефинансирование)',
  QUESTIONARY_FROM_CLIENT: 'Анкета клиента по текущему счету',
  INSURANCE_AUTO_REF:
    'Заявление-согласие на страхование авто транспорта за счет средств Банка',
  PAYMENT_ORDER: 'Платежное поручение',
  MEMO_DBZ: 'Памятка к ДБЗ',
  INSURANCE_CONTRACT: 'Договор страхования заемщика от несчастных случаев',
  INSURANCE_PAYMENT: 'Платежное поручение (Страхование)',
  INSURANCE_APP: 'Заявление на страхование от несчастных случаев заемщика'
};
