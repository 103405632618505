import { FC } from 'react';
import Column from 'antd/es/table/Column';
import { TDealersBanks } from '@/types/dealer.type';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { Divider, Input, Table, Typography } from 'antd';
import '@/styles/AdminModal.scss';

type TBanksTable = {
  dealersBanks: TDealersBanks[];
  setDealersBanks: (arg: TDealersBanks[]) => void;
};
export const DealersBanksTable: FC<TBanksTable> = ({
  dealersBanks,
  setDealersBanks
}) => {
  return (
    <>
      <Divider>
        <Typography.Title level={4}>Банки</Typography.Title>
      </Divider>
      <Table
        bordered={true}
        key="table"
        pagination={false}
        rowKey="bank_uuid"
        dataSource={dealersBanks}
        size="small"
      >
        <Column
          key="bank_uuid"
          title="Наименование"
          dataIndex="Dealers_Banks"
          render={(_, record: TDealersBanks) => {
            return record.name ? record.name : record.bank_uuid;
          }}
        />
        <Column
          className="admin-modal-table-cell"
          key="is_active"
          title="Статус"
          dataIndex="is_active"
          render={(_, record: TDealersBanks) => (
            <Checkbox
              key="record.is_active"
              checked={record.is_active}
              onChange={() => {
                const updatedDealersBanks = dealersBanks.map((bank) => {
                  if (bank.bank_uuid === record.bank_uuid) {
                    return {
                      ...bank,
                      is_active: !record.is_active
                    };
                  }
                  return bank;
                });
                setDealersBanks(updatedDealersBanks);
              }}
            />
          )}
        />
        <Column
          key="custom_timeout"
          title="custom_timeout"
          dataIndex="custom_timeout"
          render={(_, record: TDealersBanks) => {
            return (
              <Input
                key="record.custom_timeout"
                value={record.custom_timeout}
                onChange={(e) => {
                  const updatedDealersBanks = dealersBanks.map((bank) => {
                    if (bank.bank_uuid === record.bank_uuid) {
                      return {
                        ...bank,
                        custom_timeout: e.target.value
                      };
                    }
                    return bank;
                  });
                  setDealersBanks(updatedDealersBanks);
                  // setDealer((prevState) => ({
                  //   ...prevState,
                  //   Dealers_Banks: updatedDealersBanks
                  // }));
                }}
                // disabled={!record.is_active}
              />
            );
          }}
        />
        <Column
          className="admin-modal-table-cell"
          key="is_priority_timeout"
          title="Приоритет"
          dataIndex="is_priority_timeout"
          render={(_, record: TDealersBanks) => (
            <Checkbox
              key="record.is_priority_timeout"
              checked={record.is_priority_timeout}
              onChange={() => {
                const updatedDealersBanks = dealersBanks.map((bank) => {
                  if (bank.bank_uuid === record.bank_uuid) {
                    return {
                      ...bank,
                      is_priority_timeout: !record.is_priority_timeout
                    };
                  }
                  return bank;
                });
                setDealersBanks(updatedDealersBanks);
                // setDealer((prevState) => ({
                //   ...prevState,
                //   Dealers_Banks: updatedDealersBanks
                // }));
              }}
            />
          )}
        />
      </Table>
    </>
  );
};
