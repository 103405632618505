import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { TIsFocused, TModalTSValues } from '@/types/financing';
import { useAppSelector } from '@/store';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { modalTSItems } from '@/data/modalTS.data';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { setDictionaryOptions } from '@/services';
import { DictTypes } from '@/constants';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';

export type TModalTSProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  okHandler: () => void;
  values: TModalTSValues;
  setValues: React.Dispatch<React.SetStateAction<TModalTSValues>>;
};

export const ModalTS = ({
  show,
  setShow,
  okHandler,
  setValues,
  values
}: TModalTSProps) => {
  const { dicts } = useAppSelector((state) => state.dict);

  const [isFocused, setIsFocused] = useState<TIsFocused>({
    licensePlate: false,
    vin: false,
    bodyNum: false,
    paymantDate: false,
    registerCompany: false,
    engineCapacity: false,
    carColor: false,
    registerDate: false,
    registerNum: false
  });

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    switch (label) {
      case 'vin':
      case 'bodyNum':
        val = val.slice(0, 17);
        break;
      case 'licensePlate':
        val = val.slice(0, 8);
        break;
      case 'registerNum':
        val = val.slice(0, 10);
        break;
    }
    const hasCyrillic = /[а-яА-ЯЁё]/.test(val);
    if (label === 'carColor') {
      setValues((prevState) => ({
        ...prevState,
        [label]: val
      }));
    } else if (!hasCyrillic) {
      setValues((prevState) => ({
        ...prevState,
        [label]: val.toUpperCase()
      }));

      setIsFocused((prevState) => ({
        ...prevState,
        [label]: val !== '' ? true : false
      }));
      setValues((prevState) => ({
        ...prevState,
        [label]: val.toUpperCase()
      }));
    }
  };

  const onChangeDatePickerHandler = (val: any, label: string, type: string) => {
    setIsFocused((prevState) => ({ ...prevState, [label]: val }));
    setValues((prevState) => ({ ...prevState, [label]: val }));
  };

  const onChangeSelectHandler = (val: string, label: string, type: string) => {
    setIsFocused((prevState) => ({
      ...prevState,
      [label]: val !== '' ? true : false
    }));
    setValues((prevState) => ({ ...prevState, [label]: val }));
  };

  return (
    <CustomModal
      isModalOpen={show}
      handleOk={okHandler}
      handleCancel={() => setShow(false)}
      title={'Данные ТС'}
      editClass={'modalTS'}
      footer={[
        <Button key="back" onClick={() => setShow(false)}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" onClick={okHandler}>
          Добавить
        </Button>
      ]}
    >
      <div className="inputs">
        {modalTSItems.map((item, index) => {
          switch (item.type) {
            case 'input':
              return (
                <CustomInput
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={
                    item.key === 'vin'
                      ? values[item.key].slice(0, 17)
                      : item.key === 'licensePlate'
                      ? values[item.key].slice(0, 8)
                      : item.key === 'registerNum'
                      ? values[item.key].slice(0, 10)
                      : item.key === 'bodyNum'
                      ? values[item.key].slice(0, 17)
                      : values[item.key]
                  }
                  label={item.key}
                  type={'modalTS'}
                  htmlType={item.key === 'engineCapacity' ? 'number' : 'text'}
                  labelTitle={item.label}
                  onChangeInputHandler={onChangeInputHandler}
                />
              );
            case 'select':
              return (
                <CustomSelect
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={values[item.key]}
                  option={setDictionaryOptions(
                    item.snakeCaseValues as DictTypes,
                    dicts
                  )}
                  label={item.key}
                  type={'modalTS'}
                  labelTitle={item.label}
                  onChangeSelectHandler={onChangeSelectHandler}
                  keyType='autocred'
                />
              );
            case 'datepicker':
              return (
                <CustomDatePicker
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={
                    values[item.key] ? dayjs(values[item.key] as Date) : null
                  }
                  // value={null}
                  label={item.key}
                  type={'modalTS'}
                  labelTitle={item.label}
                  onChangeDatePickerHandler={onChangeDatePickerHandler}
                  keyType='autocred'
                />
              );
            default:
              return <React.Fragment key={item.key} />;
          }
        })}
      </div>
    </CustomModal>
  );
};
