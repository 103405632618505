import { TBankOffers } from '@/types/offers';
import { formatBackBankOfferResponse } from '../formatter/formatBackBankOfferResponse';

export const bankResponsesMapper = (bankResponses: TBankOffers[]) => {
  const formattedBankResponses = bankResponses.map((response) => {
    return formatBackBankOfferResponse(response);
  });

  const waitingFinancingResponse = formattedBankResponses.find(
    (response) => response.isOffered
  );

  if (waitingFinancingResponse) {
    formattedBankResponses.forEach((response, index) => {
      if (response.id !== waitingFinancingResponse.id) {
        const newResponse: TBankOffers = {
          ...response,
          statusType: 'error',
          reason: 'Есть уже принятое предложение'
        };
        formattedBankResponses[index] = newResponse;
      }
    });
  }
  return formattedBankResponses;
};
