import { TNewInsuranceServer } from '@/types/insuranseServer';

export const insuranceMapperItem = (insurance: TNewInsuranceServer): any => {

  const a = {
    requestId: insurance?.uuid || '',
 

    productInsuranceInfo: {
       status: insurance?.status?.code || '',
      dateEndpolis: insurance?.end_date || '',
      productTypeDate: insurance?.installment_periods?.code?.toString() || '',
      productType: insurance?.product_type?.code || '',
      paymentType: insurance?.installment_types?.code?.toString() || '',
      dateForm: insurance?.create_date || '',
      payment: insurance?.payment_types?.code?.toString() || '',
      datepolis: insurance?.contract_date || null
    },

    personalInsuranceInfo: {
      adress: insurance?.address || null,
      issuebdy:
        insurance?.issuing_organisation_authority?.code?.toString() || null,
      resident: insurance?.resident_status?.code?.toString() || null,
      email: insurance?.email || null,
      numberdocument: insurance?.doc_num || null,
      dateDocument: insurance?.document_registration_date || null,
      placeBirth: insurance?.birth_place || null,
      validity: insurance?.document_valid_date || null,
      jobAddress: insurance?.client?.address || null,
      dateBirth: insurance?.client?.birth_date || null,
      sex: insurance?.gender?.code?.toString() || null,
      docType: insurance?.document_type?.code?.toString() || null,
      clientIin: insurance?.client?.client_iin || '',
      clientPhone: insurance?.client?.phone_number || '',

      firstName: insurance?.client?.first_name || '',
      lastName: insurance?.client?.last_name || '',
      middleName: insurance?.client?.middle_name || ''
    },

    create_date: insurance?.create_date || '',
    // status: {
    //   code: insurance?.status?.code || ''
    // },

    autoInsuranceInfo: {
      dealer: insurance?.dealer?.code?.toString() || null,
      priseAvto: insurance?.vehicle_cost || null,
      tehNum: insurance?.tech_pass_num || null,
      dateOfTechPasspord: insurance?.vehicle_passport_issue_date || null,
      numberGos: insurance?.vehicle_body_number || null,
      vinCode: insurance?.vin_code || null,
      markModelYear: {
        brand: insurance?.brand?.code?.toString() || '',
        model: insurance?.model?.code?.toString() || '',
        year: insurance?.year || null
      }
    },

    insurance: {
      insuranseSumm: insurance?.insurance_summ || null,
      insuransePremium: insurance?.insurance_prem || null
    }
    // clientInsuranceInfo: {
    //   innInsurance: insurance?.client?.client_iin || '', //иин клиента
    //   numberInsurance: insurance?.client?.phone_number|| '' //номер клиента
    // }
  };

  return a 
};
