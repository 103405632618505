import Column from 'antd/es/table/Column';
import { Button, Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowModalCreateSimpleDict } from '@/store/admin/admin.slice';
import { TDictContent, TSimpleDict } from '@/types/dicts';
import SearchInput from '@/components/Shared/Input/SearchInput';
import CreateOrEditSimpleDictModal from '@/components/AdminPanel/Modals/CreateOrEditSimpleDictModal';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';

const Dictionaries = () => {
  const dispatch = useAppDispatch();
  const { showModalCreateSimpleDict } = useAppSelector((state) => state.admin);
  const { dicts } = useAppSelector((state) => state.dict);
  const params = useParams();
  const [dictionariesContent, setDictionariesContent] = useState<
    TDictContent[]
  >([]);
  const [dictForEditing, setDictForEditing] = useState<TSimpleDict | null>(
    null
  );
  useEffect(() => {
    if (params.dictType) {
      const dictToFind = findDictByTitle(params.dictType as DictTypes, dicts);
      dictToFind && setDictionariesContent(dictToFind.content);
    }
  }, [params, dicts]);

  return (
    <div>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput style={{ width: '50%', marginRight: '10px' }} />
        {showModalCreateSimpleDict && (
          <CreateOrEditSimpleDictModal
            setShow={() => dispatch(setShowModalCreateSimpleDict(false))}
            editMode={!!dictForEditing}
            dictItem={dictForEditing}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setDictForEditing(null);
            dispatch(setShowModalCreateSimpleDict(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowClassName="admin-dealers-table-row"
        rowKey={'uuid'}
        size={'small'}
        dataSource={dictionariesContent}
        onRow={(record: TDictContent) => ({
          onClick: () => {
            setDictForEditing(record as TSimpleDict);
            dispatch(setShowModalCreateSimpleDict(true));
          }
        })}
      >
        {/*<Column key="uuid" title="ID" dataIndex="uuid" />*/}
        <Column key="code" title="Код" dataIndex="code" />
        <Column key="name" title="Наименование" dataIndex="name" />
        {/*<Column*/}
        {/*  key="actions"*/}
        {/*  title="Действия"*/}
        {/*  render={(_: TSimpleDict, record: TSimpleDict) => (*/}
        {/*    <>*/}
        {/*      <Typography.Link key="edit">*/}
        {/*        <EditOutlined*/}
        {/*          onClick={() => {*/}
        {/*            setDictForEditing(record);*/}
        {/*            dispatch(setShowModalCreateSimpleDict(true));*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      </Typography.Link>*/}
        {/*      <Popconfirm*/}
        {/*        key="removeConf"*/}
        {/*        title="Удалить запись?"*/}

        {/*        // onConfirm={() => deleteItem(record)}*/}
        {/*      >*/}
        {/*        <Typography.Link key="remove" style={{ marginLeft: '20px' }}>*/}
        {/*          <DeleteOutlined />*/}
        {/*        </Typography.Link>*/}
        {/*      </Popconfirm>*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*/>*/}
      </Table>
    </div>
  );
};

export default Dictionaries;
