import { columnsInsurance } from '@/data/insurance.data'
import { getFullPageTableHeight } from '@/services'
import { dispatch, useAppSelector } from '@/store'
import {
  filterInsurance,
  getOneInsuranceReq,
  onSetInsurancesStoreToInitial
} from '@/store/orderInsurance.slice'
import '@/styles/Insurance.scss'
import { TNewInsuranceResponse } from '@/types/insuranceMapper'
import { Input, Select, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InsuranceHeader from './InsuranceHeader'

export default function Insurance() {
  const { Search } = Input;

  const [selectedUser, setSelectedUser] = useState<string>('all');
  const [selectedUserBasel, setSelectedUserBasel] = useState<string>('-1');
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<string[]>(
    []
  );
  const navigate = useNavigate();
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [filteredArray, setFilteredArray] = useState<TNewInsuranceResponse[]>(
    []
  );
  // const [searchValue, setSearchValue] = useState<string>('');
  const [telNumIin, setTelNumIin] = useState<string>('');

  const [userSearchType, setUserSearchType] = useState<string>('');
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [paginationData, setPaginationData] = useState<{
    currentPage: number;
    itemsPerPage: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10
  });
  const [isTableDataLoading, setIsTableLoading] = useState<boolean>(false);
  const { userInfo, dealer: userDealer } = useAppSelector(
    (state) => state.userInfo
  );
  const { insurances } = useAppSelector((state) => state.orderInsurance);

  // const getRequestsPerPage = async () => {
  //   setIsTableLoading(true);
  //   try {
  //     const formattedNameSearchValue = cleanNameValue(searchValue);
  //     const formattedPhoneSearchValue = cleanPhoneNumber(searchValue);
  //     let searchType: 'iinTelNum' | 'empty' = 'empty';
  //     if (formattedNameSearchValue === '' && formattedPhoneSearchValue === '') {
  //       searchType = 'empty';
  //     } else if (
  //       formattedNameSearchValue !== '' &&
  //       formattedPhoneSearchValue === ''
  //     ) {
  //     } else {
  //       searchType = 'iinTelNum';
  //     }

  //const fetchFilteredInsurance = async () => {

  //       try {

  //         const data: any = await apiConnector.getFilterInsurance({ telNumIin, userSearchType });
  //         const dataSource: TNewInsuranceResponse[] = insuranceMapperResp(data);
  //         setFilteredArray(dataSource);
  //       } catch (e: any) {
  //         console.error('Error fetching filtered insurance:', e);
  //         throw new Error(e);
  //       } finally {
  //         setIsTableLoading(false);
  //       }
  //  };

  // function getRequestsPerPage() {
  //   throw new Error('Function not implemented.');
  // }
  // useEffect(() => {
  //   fetchFilteredInsurance();
  // }, [
  //   paginationData,
  //   selectedUser,
  //   selectedCities,
  //   selectedStatuses,
  //   selectedPaymentTypes
  // ]);
  useEffect(() => {
    if (selectedUser === 'all') {
      dispatch(filterInsurance({ telNumIin, userSearchType }));
    } else {
      dispatch(
        filterInsurance({ telNumIin: telNumIin, userSearchType: selectedUser })
      );
    }
  }, [selectedUser]);

  useEffect(() => {
    dispatch(onSetInsurancesStoreToInitial());
    dispatch(filterInsurance({ telNumIin, userSearchType }));
    // dispatch(getAllInsuranseReq({ any: [] }));
  }, []);

  return (
    <>
      <div className="Insurance">
        <InsuranceHeader />
        <div className="table_editor_insurance">
          <Search
            style={{ width: '50%' }}
            size="large"
            placeholder="Поиск по ИИН / Номер тел."
            value={telNumIin}
            onChange={(e) => setTelNumIin(e.target.value)}
            onSearch={() =>
              dispatch(filterInsurance({ telNumIin, userSearchType }))
            }
          />
          {/* <Select
            showSearch
            optionFilterProp={'label'}
            style={{ width: '22%' }}
            size="large"
            placeholder="Basel"
            value={selectedUserBasel}
            onChange={setSelectedUserBasel}
            options={
              [
                { value: userInfo?.uuid, label: 'Каско' },
                { value: '-1', label: 'Basel' }
              ] || []
            }
            maxTagCount={'responsive'}
          /> */}
          <Select
            showSearch
            optionFilterProp={'label'}
            style={{ width: '22%' }}
            size="large"
            placeholder="Все заявки"
            value={selectedUser}
            onChange={(value) => setSelectedUser(value)}
            options={
              [
                { value: userInfo?.uuid, label: 'Мои заявки' },
                { value: 'all', label: 'Все заявки' }
              ] || []
            }
            maxTagCount={'responsive'}
          />
        </div>
        <div className="table">
          <Table
            rowClassName="insurance-table-row"
            size="small"
            showSorterTooltip={true}
            columns={columnsInsurance}
            rowKey={(record) => record.uuid || ''}
            dataSource={insurances}
            pagination={{
              position: ['bottomLeft'],
              showSizeChanger: true
              // total: totalRequests,
              // pageSize: paginationData.itemsPerPage,
              // current: paginationData.currentPage,
              // onChange: (step: number, itemsPerPage: number) => {
              //   setPaginationData({ currentPage: step, itemsPerPage: itemsPerPage });
              // }
            }}
            scroll={{
              x: 'max-content',
              y: getFullPageTableHeight()
            }}
            loading={isTableDataLoading}
            onRow={(r) => {
              return {
                onClick: async () => {
                  await dispatch(getOneInsuranceReq(r.uuid));
                  navigate('/new-insurance');
                }
              };
            }}
          />
        </div>
      </div>
    </>
  );
}
