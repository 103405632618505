import React, { useEffect, useState } from 'react';

type TableDataProps = {
  date: string;
};

const TableDate: React.FC<TableDataProps> = ({ date }) => {
  const [elapsedTime, setElapsedTime] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const startTime = new Date(date); //.getTime();
    intervalId = setInterval(() => {
      const currentTime = new Date();
      const elapsedMilliseconds = currentTime.getTime() - startTime.getTime();
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const days = Math.floor(elapsedSeconds / 86400);
      const hours = Math.floor((elapsedSeconds % 86400) / 3600);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;

      setElapsedTime((prevElapsedTime) => {
        if (days > prevElapsedTime.days) {
          return { days, hours, minutes, seconds };
        } else {
          return { days: prevElapsedTime.days, hours, minutes, seconds };
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <p className="ext_time">
        {elapsedTime.days > 0
          ? `${elapsedTime.days} д`
          : `${elapsedTime.hours} ч ${elapsedTime.minutes} мин ${elapsedTime.seconds} сек`}
      </p>
    </>
  );
};

export default TableDate;
