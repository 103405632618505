import { AxiosInstance } from 'axios';
import { TModel } from '@/types/model.type';

export class ModelsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getModels(
    limit?: number,
    page?: number,
    param?: string,
    brandUuid?: string
  ): Promise<{
    total: number;
    data: TModel[];
  }> {
    let path = `admin/models/getModels/${param ? param : ''}?`;
    if (limit) path = path + `&limit=${limit}`;

    if (page) path = path + `&page=${page}`;

    if (brandUuid) path = path + `&brand_uuid=${brandUuid}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async createAdminModel(model: {
    name: string;
    code: string | number;
    brand_uuid: string;
    capacity: number | string | null;
    year: string | null;
  }) {
    const res = await this.api.post('admin/models/createModel', model);
    return res.data;
  }
  public async updateAdminModel(model: TModel) {
    const res = await this.api.patch(
      `admin/models/${model.uuid}/updateModel`,
      model
    );
    return res.data;
  }
}
