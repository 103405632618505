import {
  TClientCalculations,
  TClientCalculationServerResponse
} from '@/types/clients';
import { validateNumberInputValue } from '@/services';

export const clientCalculationsMapper = (
  serverResponse: TClientCalculationServerResponse[]
): TClientCalculations[] => {
  const formattedArray = serverResponse?.map((response) => {
    const formattedData: TClientCalculations = {
      uuid: response?.uuid || '',
      dealer: response?.dealer?.name || '',
      calculationDate: response?.calc_date || '',
      loanTerm: response?.loan_term?.name || '',
      carCost: validateNumberInputValue(
        response?.car_price?.toString() || '',
        10,
        true
      ),
      initFee: validateNumberInputValue(
        response?.init_fee?.toString(),
        10,
        true
      ),
      markModelYear: {
        mark: response?.model?.brand_uuid || '',
        model: response?.model?.name || '',
        year: response?.model?.year || ''
      }
    };
    return formattedData;
  });
  return formattedArray;
};
