import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TUser, TUserDto } from '@/types/user.type';
import { TSalePoint } from '@/types/salePoint.type';
import { TRoleAction } from '@/types/roleAction.type';
import { TPosition } from '@/types/position.type';
import {
  positionsApi,
  rolesActionsApi,
  salePointsApi,
  userApi
} from '@/integrations/index.api';

type TUserSliceProps = {
  users: TUser[];
  positions: TPosition[];
  sale_points: TSalePoint[];
  roles_actions: TRoleAction[];
  total: number;
  isLoading: boolean;
};

const initialState: TUserSliceProps = {
  users: [],
  positions: [],
  sale_points: [],
  roles_actions: [],
  total: 0,
  isLoading: false
};

export const getAllPositions = createAsyncThunk(
  'admin/users/getAllPositions',
  async () => {
    const res = await positionsApi.getAdminAllPositions();
    return res;
  }
);

export const createPosition = createAsyncThunk(
  'admin/user/createPosition',
  async (data: TPosition, { dispatch }) => {
    await positionsApi.createPosition(data);
    dispatch(getAllPositions());
  }
);
export const updatePosition = createAsyncThunk(
  'admin/user/updatePosition',
  async (data: TPosition, { dispatch }) => {
    await positionsApi.updatePosition(data);
    dispatch(getAllPositions());
  }
);

export const getSalePoints = createAsyncThunk(
  'admin/users/getSalePoints',
  async () => {
    const res = await salePointsApi.getAdminSalePoints();
 
    return res;
  }
);

export const getRolesActions = createAsyncThunk(
  'admin/users/getRolesActions',
  async () => {
    const res = await rolesActionsApi.getAllRolesActions();
    return res;
  }
);

export const createRolesAction = createAsyncThunk(
  '/admin/users/creteRolesActions',
  async (data: TRoleAction, { dispatch }) => {
    await rolesActionsApi.createRolesAction(data);
    return dispatch(getRolesActions());
  }
);

export const updateRolesAction = createAsyncThunk(
  'admin/users/updateRolesAction',
  async (data: TRoleAction, { dispatch }) => {
    await rolesActionsApi.updateRolesAction(data);
    return dispatch(getRolesActions());
  }
);

export const getAdminAllUsers = createAsyncThunk(
  'admin/users/getAll',
  async ({
    limit,
    page,
    param
  }: {
    limit: number;
    page: number;
    param?: string;
  }) => {
    const res = await userApi.getAdminAllUsers(limit, page, param);
    return res;
  }
);

export const createUser = createAsyncThunk(
  'admin/users/create',
  async (data: TUserDto) => {
    const res = await userApi.createAdminUser(data);
    return res;
  }
);

export const updateUser = createAsyncThunk(
  'admin/users/update',
  async (data: TUser) => {
    const res = await userApi.updateAdminUser(data);
    return res;
  }
);

export const adminUsersSlice = createSlice({
  name: 'admin/users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminAllUsers.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getSalePoints.fulfilled, (state, action) => {
        state.sale_points = action.payload;
      })
      .addCase(getRolesActions.fulfilled, (state, action) => {
        state.roles_actions = action.payload;
      })
      .addCase(getAllPositions.fulfilled, (state, action) => {
        state.positions = action.payload.positions;
      });
  }
});
