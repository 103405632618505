import React from 'react';

export type TPersonSpouseProps = {
  fullName: string;
  iin: string;
  onclick: () => void;
};
export const PersonSpouse = ({
  fullName,
  iin,
  onclick
}: TPersonSpouseProps) => {
  return (
    <div className="personSpouse">
      <div className="title">
        <h2>{`Данные супруга(и)`}</h2>
        <p onClick={onclick}>Изменить</p>
      </div>

      <div className="spouse_info">
        <h2>{fullName}</h2>
        <p>ИИН: {iin}</p>
      </div>
    </div>
  );
};
