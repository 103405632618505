import { FC } from 'react';
import {
  pathAltynLogo,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo,
  shinhanPathLogo
} from '@/constants';

type TImgProps = {
  selectedBank: string;
};
const RenderBankLogo: FC<TImgProps> = ({ selectedBank }) => {
  return (
    <div
      style={{
        borderRadius: '50%',
        padding: '5px',
        background: '#FFF',
        width: '27px',
        height: '27px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px'
      }}
    >
      <img
        src={
          selectedBank === 'Freedom Finance'
            ? pathFreedomlogo
            : selectedBank === 'Евразийский Банк' || selectedBank === 'eu_bank'
            ? pathEurasLogo
            : selectedBank === 'KMF'
            ? pathKmfLogo
            : selectedBank === 'Halyk Bank' || selectedBank === 'halyk'
            ? pathHalykLogo
            : selectedBank === 'Банк ЦентрКредит' || selectedBank === 'bcc'
            ? pathBckLogo
            : selectedBank === 'Береке Банк' || selectedBank === 'bereke'
            ? pathBerekeLogo
            : selectedBank === 'Forte Bank'
            ? pathForteLogo
            : selectedBank === 'Shinhan Банк' || selectedBank === 'shinhan'
            ? shinhanPathLogo
            : selectedBank === 'Freedom Finance Bank' || selectedBank === 'ffb'
            ? pathFreedomlogo
            : selectedBank === 'Forte Банк' || selectedBank === 'forte'
            ? pathForteLogo
            : selectedBank === 'altyn' || selectedBank === 'Алтын Банк'
            ? pathAltynLogo
            : ''
        }
        alt="bank-logo"
        style={{
          objectFit: 'contain',
          width: '20px',
          height: '20px'
        }}
      />
    </div>
  );
};

export default RenderBankLogo;
