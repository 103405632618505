import React from 'react';
import { getFormattedBankName } from '@/services';
import { darkInfoCircleLogo } from '@/constants';
import { TBankOffers } from '@/types/offers';

export type TBankDeclinedProps = {
  bankOffer: TBankOffers;
};

export const BankDeclinedItem = ({ bankOffer }: TBankDeclinedProps) => {
  return (
    <div className="BankItem declined">
      <div className="BankItem_header">
        <div className="BankLogo_root">
          <div className="BankItem_logo_wrapper">
            <img src={bankOffer.icon} alt="logo_bank" />
          </div>
          <p className="Bank_title"> {getFormattedBankName(bankOffer.name)}</p>
        </div>
        {bankOffer.extId ? <p className="ext_uuid">{bankOffer.extId}</p> : null}
        <p className="BankItem_status">
          {bankOffer.statusType === 'declined' ? 'Отказано' : null}
          {bankOffer.statusType === 'error' ? 'Ошибка банка' : null}
          {bankOffer.statusType === 'cancelled' ? 'Заявка отменена' : null}
        </p>
      </div>
      <div className="BankInfo_root">
        <div className="BankDeclined_content">
          <img src={darkInfoCircleLogo} alt="info" />
          <p
            style={{
              wordBreak: 'break-all',
              maxWidth: '95%',
              textAlign: 'left'
            }}
          >
            {bankOffer?.reason}
          </p>
        </div>
      </div>

      {/*<div className="BankInfo_root">*/}
      {/*    <div className="BankMain_info">*/}
      {/*        <div className="BankMain_info_raw">*/}
      {/*            <div className="BankMain_info_block">*/}
      {/*                <p>Ежемесячный платеж</p>*/}
      {/*                <h4>{bankOffer.monthlyPayment} ₸</h4>*/}
      {/*            </div>*/}
      {/*            <div className="BankMain_info_block">*/}
      {/*                <p>Процентная ставка</p>*/}
      {/*                <h4><span>{bankOffer.rate}%</span></h4>*/}
      {/*            </div>*/}
      {/*            <div className="BankMain_info_block">*/}
      {/*                <p>Срок</p>*/}
      {/*                <h4>{bankOffer.term} месяца</h4>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*        <div className="BankMain_info_raw">*/}
      {/*            <div className="BankMain_info_block">*/}
      {/*                <p className="BankInfo_subhead">Сумма займа</p>*/}
      {/*                <h5>{bankOffer.loanAmount} ₸</h5>*/}
      {/*            </div>*/}
      {/*            <div className="BankMain_info_block">*/}
      {/*                <p className="BankInfo_subhead">Первоначальный взнос</p>*/}
      {/*                <h5>{bankOffer.initialFee} ₸</h5>*/}
      {/*            </div>*/}
      {/*            <div className="BankMain_info_block">*/}
      {/*                <p className="BankInfo_subhead">Продукт</p>*/}
      {/*                <h5>{bankOffer.product}</h5>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="BankAdditional_info">*/}
      {/*        <h6 className="BankAdditional_header">Доп. продукты</h6>*/}
      {/*        <div className="BankAdditional_info_block">*/}
      {/*            <p>КАСКО 5 000 ₸</p>*/}
      {/*        </div>*/}
      {/*        <div className="BankAdditional_info_block">*/}
      {/*            <p>Cтрахование от НС 100 000 ₸</p>*/}
      {/*        </div>*/}
      {/*        <div className="BankAdditional_info_block">*/}
      {/*            <p>LITRO 5 000 ₸</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};
