import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { getRolesActions } from '@/store/admin/adminUsers.slice';
import { Button, Table } from 'antd';
import { setShowRolesActionModal } from '@/store/admin/admin.slice';
import Column from 'antd/lib/table/Column';
import { TRoleAction } from '@/types/roleAction.type';
import { CreateOrEditRolesActionModal } from '@/components/AdminPanel/Modals/CreateOrEditRolesActionModal';
import { PlusCircleOutlined } from '@ant-design/icons';

export const RolesActionsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { roles_actions } = useAppSelector((state) => state.adminUsers);
  const { showRolesActionModal } = useAppSelector((state) => state.admin);
  const [rolesActionForEdit, setRolesActionForEdit] =
    useState<TRoleAction | null>(null);

  useEffect(() => {
    dispatch(getRolesActions());
  }, []);

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        {/*<SearchInput*/}
        {/*  style={{ width: '50%', marginRight: '10px' }}*/}
        {/*  onSearch={(value) => {*/}
        {/*    dispatch(*/}
        {/*      getAllDealers({*/}
        {/*        param: value,*/}
        {/*        limit: 10,*/}
        {/*        page: 1*/}
        {/*      })*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
        {showRolesActionModal && (
          <CreateOrEditRolesActionModal
            editMode={!!rolesActionForEdit}
            rolesActionItem={rolesActionForEdit}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setRolesActionForEdit(null);
            dispatch(setShowRolesActionModal(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowClassName="admin-dealers-table-row"
        rowKey="uuid"
        dataSource={roles_actions}
        onRow={(record: TRoleAction) => ({
          onClick: () => {
            setRolesActionForEdit(record);
            dispatch(setShowRolesActionModal(true));
          }
        })}
      >
        <Column key="name" dataIndex="name" title="Наименование" />
        <Column key="code" dataIndex="code" title="Код" />
        {/*<Column*/}
        {/*  key="is_active"*/}
        {/*  dataIndex="is_active"*/}
        {/*  title="Статус"*/}
        {/*  render={(_, record: TRoleAction) => {*/}
        {/*    return record.is_active ? (*/}
        {/*      <p style={{ color: 'green' }}>Активно</p>*/}
        {/*    ) : (*/}
        {/*      <p style={{ color: 'red' }}>Не активно</p>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
        <Column
          key="description"
          dataIndex="description"
          title="Описание"
          render={(_, record: TRoleAction) => {
            return record.description ? record.description : '';
          }}
        />
      </Table>
    </>
  );
};
