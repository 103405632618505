import React from 'react';
import { darkInfoCircleLogo } from '@/constants';
import { TBankOffersAlternative } from '@/types/offers';

export type TBankAlternativeItemProps = {
  alternativeBank: TBankOffersAlternative;
  onSelectOffer: (bank: TBankOffersAlternative) => void;
  handleShowSpecialConditions: () => void;
};

export const BankAlternativeItem = ({
  alternativeBank,
  onSelectOffer,
  handleShowSpecialConditions
}: TBankAlternativeItemProps) => {
  return (
    <>
      <div className="BankItem approved BankItem_alternative">
        <div className="BankItem_header">
          <div className="BankLogo_root">
            <div className="BankItem_logo_wrapper">
              <img src={alternativeBank.icon} alt="logo_bank" />
            </div>
            <p className="Bank_title">{alternativeBank.name}</p>
          </div>
          <div className="BanksAlternative_buttons_root">
            <button
              className="BankAccept_show_alternative"
              onClick={handleShowSpecialConditions}
            >
              <img src={darkInfoCircleLogo} alt="info" />
              <p>Особые условия</p>
            </button>
            <button
              onClick={() => onSelectOffer(alternativeBank)}
              className="BankAccept_offer"
            >
              <p>Принять предложение</p>
            </button>
          </div>
        </div>

        <div className="BankInfo_root">
          <div className="BankMain_info">
            <div className="BankMain_info_raw">
              <div className="BankMain_info_block">
                <p>Ежемесячный платеж</p>
                <h4>{alternativeBank.monthlyPayment} ₸</h4>
              </div>
              <div className="BankMain_info_block">
                <p>Процентная ставка</p>
                <h4>
                  <span>{alternativeBank.rate}%</span>
                </h4>
              </div>
              <div className="BankMain_info_block">
                <p>Срок</p>
                <h4>{alternativeBank.term} месяца</h4>
              </div>
            </div>
            <div className="BankMain_info_raw">
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Сумма займа</p>
                <h5>{alternativeBank.loanAmount} ₸</h5>
              </div>
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Первоначальный взнос</p>
                <h5>{alternativeBank.initialFee} ₸</h5>
              </div>
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Продукт</p>
                <h5>{alternativeBank.product}</h5>
              </div>
            </div>
          </div>
          <div className="BankAdditional_info">
            <h6 className="BankAdditional_header">Доп. продукты</h6>
            <div className="BankAdditional_info_block">
              <p>КАСКО 5 000 ₸</p>
            </div>
            <div className="BankAdditional_info_block">
              <p>Cтрахование от НС 100 000 ₸</p>
            </div>
            <div className="BankAdditional_info_block">
              <p>LITRO 5 000 ₸</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
