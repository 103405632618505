import { useAppDispatch, useAppSelector } from '@/store';
import { useEffect, useState } from 'react';
import { setPageTitle } from '@/store/newOrder.slice';
import '@/styles/NewLeasing.scss';
import {
  leasingFormDataAddressInfo,
  leasingFormDataContactInfo,
  leasingFormDataDocumentInfo,
  leasingFormDataLicenseInfo,
  leasingFormDataOverallInfo
} from '@/data/leasingForm.data';
import { TLeasingCreationDto } from '@/types/leasing';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { setDictionaryOptions } from '@/services';
import { DictTypes } from '@/constants';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { Button, notification } from 'antd';
import dayjs from 'dayjs';
import { updateLeasing } from '@/store/leasings.slice';
import { leasingValidator } from '@/services/leasingValidator';
import { cleanPhoneNumber } from '@/services/formatter';

const NewLeasing = () => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const { selectedLeasing, targetLeasingUuid } = useAppSelector(
    (state) => state.leasings
  );
  const [resendLeasing, setResendLeasing] = useState<boolean>(false);
  const [isLeasingFormValid, setIsLeasingFormValid] = useState<boolean>(false);
  const [leasingCreateDto, setLeasingCreateDto] = useState<TLeasingCreationDto>(
    {
      actualAddress: '',
      clientFullName: '',
      additionalPhoneNumber: '',
      availabilityLicenses: '',
      status: '',
      bankRequisites: '',
      contactPhoneNumber: '',
      birthDate: '',
      docName: null,
      documentIssueDate: '',
      iin: '',
      documentNumber: '',
      documentValidEndDate: '',
      email: '',
      firstRegistrationDate: '',
      firstSupervisorFullName: '',
      legalAddress: '',
      licenseMainActivity: '',
      licenseNum: '',
      licenseOrganizationAuthority: '',
      livingAddress: '',
      mainActivity: null,
      okedOfMainAndSubActivity: '',
      placeOfStateRegistration: null,
      propertyType: null,
      registrationCountry: null,
      stateRegistrationDate: '',
      stateRegistrationNumber: '',
      workStartDate: ''
    }
  );

  const onChangeLeasingDto = (val: string, label: string, type: string) => {
    if (type === 'iin') {
      const isValidNumeric = /^\d+$/.test(val);
      if (!isValidNumeric || val.length > 12) return;
    } else if (type.includes('PhoneNumber')) {
      if (val.replace(/ /g, '').length > 12) return;
    } else if (type.includes('oked')) {
      const isValidNumeric = /^\d+$/.test(val);
      if (!isValidNumeric || val.length > 9) return;
    } else if (type.includes('Num')) {
      val = val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    }
    setLeasingCreateDto((prevState) => {
      return { ...prevState, [type]: val };
    });
  };

  const onCreateLeasing = async () => {
    try {
      const valueToSend = {
        ...leasingCreateDto,
        contactPhoneNumber: cleanPhoneNumber(
          leasingCreateDto.contactPhoneNumber
        ),
        additionalPhoneNumber: cleanPhoneNumber(
          leasingCreateDto.additionalPhoneNumber
        ),
        docName: Number(leasingCreateDto.docName),
        documentValidEndDate: new Date(
          leasingCreateDto.documentValidEndDate
        ).toISOString(),
        workStartDate: new Date(leasingCreateDto.workStartDate).toISOString(),
        documentIssueDate: new Date(
          leasingCreateDto.documentIssueDate
        ).toISOString(),
        firstRegistrationDate: new Date(
          leasingCreateDto.firstRegistrationDate
        ).toISOString(),
        stateRegistrationDate: new Date(
          leasingCreateDto.stateRegistrationDate
        ).toISOString(),
        birthDate: new Date(leasingCreateDto.birthDate).toISOString(),
        registrationCountry: Number(leasingCreateDto.registrationCountry),
        propertyType: Number(leasingCreateDto.propertyType),
        placeOfStateRegistration: Number(
          leasingCreateDto.placeOfStateRegistration
        ),
        mainActivity: Number(leasingCreateDto.mainActivity)
      };

      await dispatch(
        updateLeasing({
          leasingUuid: targetLeasingUuid,
          leasingDto: valueToSend
        })
      );

      notification.success({
        message: 'Лизинг добавлен',
        description: 'Лизинг был успешно добавлен',
        duration: 5
      });
    } catch (e: any) {
      notification.error({
        message: 'Ошибка создания лизинга',
        description: 'Произошла ошибка при создании лизинга',
        duration: 5
      });
    }
  };

  const onResendLeasing = () => {
    try {
      notification.success({
        message: 'Лизинг переотправлен',
        description: 'Лизинг был успешно переотправлен',
        duration: 5
      });
    } catch (e: any) {
      notification.error({
        message: 'Ошибка при переотправке лизинга',
        description: 'Прозошла при переотправке лизинга',
        duration: 5
      });
    }
  };

  useEffect(() => {
    dispatch(setPageTitle('Создание лизинга'));
  }, []);

  useEffect(() => {
    setLeasingCreateDto(selectedLeasing);
    if (
      selectedLeasing?.status === 'leasing_created' ||
      selectedLeasing?.status === 'leasing_financed'
    ) {
      setResendLeasing(true);
    }
  }, [selectedLeasing]);

  useEffect(() => {
    const isValid = leasingValidator(leasingCreateDto);
    setIsLeasingFormValid(isValid);
  }, [leasingCreateDto]);

  return (
    <div className="NewLeasing">
      <div className="container">
        <div className="content">
          <h1>Внесите данные лизинга</h1>

          <h1>Общая информация</h1>
          <div className="NewLeasing_form">
            {leasingFormDataOverallInfo.map((item) => {
              if (item.type === 'input') {
                return (
                  <div key={item.key}>
                    <CustomInput
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      fieldsReadOnly={resendLeasing}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'select') {
                return (
                  <div key={item.key}>
                    <CustomSelect
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeLeasingDto}
                      keyType="autocred"
                    />
                  </div>
                );
              } else if (item.type === 'datepicker') {
                return (
                  <div key={item.key}>
                    <CustomDatePicker
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={dayjs(leasingCreateDto[item.key])}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeDatePickerHandler={onChangeLeasingDto}
                      keyType="autocred"
                    />
                  </div>
                );
              } else if (item.type === 'phoneInput') {
                return (
                  <div key={item.key}>
                    <CustomPhoneInput
                      readOnly={resendLeasing}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      label={item.label}
                      type={item.key}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              }
            })}
          </div>

          <h1>Информация о документах</h1>
          <div className="NewLeasing_form">
            {leasingFormDataDocumentInfo.map((item) => {
              if (item.type === 'input') {
                return (
                  <div key={item.key}>
                    <CustomInput
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      fieldsReadOnly={resendLeasing}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'select') {
                return (
                  <div key={item.key}>
                    <CustomSelect
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'datepicker') {
                return (
                  <div key={item.key}>
                    <CustomDatePicker
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={dayjs(leasingCreateDto[item.key])}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeDatePickerHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'phoneInput') {
                return (
                  <div key={item.key}>
                    <CustomPhoneInput
                      readOnly={resendLeasing}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      label={item.label}
                      type={item.key}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              }
            })}
          </div>

          <h1>Информация о лицензии</h1>
          <div className="NewLeasing_form">
            {leasingFormDataLicenseInfo.map((item) => {
              if (item.type === 'input') {
                return (
                  <div key={item.key}>
                    <CustomInput
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      fieldsReadOnly={resendLeasing}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'select') {
                return (
                  <div key={item.key}>
                    <CustomSelect
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'datepicker') {
                return (
                  <div key={item.key}>
                    <CustomDatePicker
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={dayjs(leasingCreateDto[item.key])}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeDatePickerHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'phoneInput') {
                return (
                  <div key={item.key}>
                    <CustomPhoneInput
                      readOnly={resendLeasing}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      label={item.label}
                      type={item.key}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              }
            })}
          </div>

          <h1>Контактная информация</h1>
          <div className="NewLeasing_form">
            {leasingFormDataContactInfo.map((item) => {
              if (item.type === 'input') {
                return (
                  <div key={item.key}>
                    <CustomInput
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      fieldsReadOnly={resendLeasing}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'select') {
                return (
                  <div key={item.key}>
                    <CustomSelect
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'datepicker') {
                return (
                  <div key={item.key}>
                    <CustomDatePicker
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={dayjs(leasingCreateDto[item.key])}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeDatePickerHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'phoneInput') {
                return (
                  <div key={item.key}>
                    <CustomPhoneInput
                      readOnly={resendLeasing}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      label={item.label}
                      type={item.key}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              }
            })}
          </div>

          <h1>Адресная информация</h1>
          <div className="NewLeasing_form">
            {leasingFormDataAddressInfo.map((item) => {
              if (item.type === 'input') {
                return (
                  <div key={item.key}>
                    <CustomInput
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      fieldsReadOnly={resendLeasing}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'select') {
                return (
                  <div key={item.key}>
                    <CustomSelect
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'datepicker') {
                return (
                  <div key={item.key}>
                    <CustomDatePicker
                      keyType="personal"
                      fieldsReadOnly={resendLeasing}
                      isFocused={false}
                      //@ts-ignore
                      value={dayjs(leasingCreateDto[item.key])}
                      label={item.label}
                      type={item.key}
                      labelTitle={item.label}
                      onChangeDatePickerHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              } else if (item.type === 'phoneInput') {
                return (
                  <div key={item.key}>
                    <CustomPhoneInput
                      readOnly={resendLeasing}
                      //@ts-ignore
                      value={leasingCreateDto[item.key]}
                      label={item.label}
                      type={item.key}
                      onChangeInputHandler={onChangeLeasingDto}
                    />
                  </div>
                );
              }
            })}
          </div>
          {leasingCreateDto.status === 'leasing_draft' ? (
            <Button
              disabled={!isLeasingFormValid}
              style={{ width: '100%' }}
              onClick={() => onCreateLeasing()}
            >
              Отправить заявку на лизинг
            </Button>
          ) : leasingCreateDto.status === 'leasing_created' ? (
            <Button
              disabled={!isLeasingFormValid}
              style={{ width: '100%' }}
              onClick={() => onCreateLeasing()}
            >
              Отправить заявку повторно
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewLeasing;
