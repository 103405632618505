import React, { FC } from 'react';

type TCellInnerProps = {
  title: string;
  content: string;
};
const CellInner: FC<TCellInnerProps> = ({ title, content }) => {
  return (
    <>
      <h2 style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}>
        {title}
      </h2>
      <p style={{ fontSize: '12px', fontWeight: '400', color: '#687588' }}>
        {content}
      </p>
    </>
  );
};

export default CellInner;
