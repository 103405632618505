import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TFileAttached } from '@/types/financing';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import { checkArrayValues } from '@/services/validators/checkArrayValues';

export type THalykBankProps = {
  requestLoading: boolean;
  handleSendDocs: (uploadedFiles: any) => void;
  attachedFiles: TFileAttached[];
  // showSendButton: boolean;
  handleDownloadHalykGuarantee: () => void;
  isFileDownloading: boolean;
  isOfferFinaced: boolean;
};
export const HalykBank = ({
  attachedFiles,
  requestLoading,
  handleSendDocs,
  // showSendButton,
  handleDownloadHalykGuarantee,
  isFileDownloading,
  isOfferFinaced
}: THalykBankProps) => {
  const [uploadFiles, setUploadFiles] = useState({
    spouseConsent: null
  });

  // const [letterOfGuarantee, setLetterOfGuarantee] = useState<string>('');

  const [files, setFiles] = useState<TFileAttached[]>([]);

  const [spouseAgreement, setSpouseAgreement] = useState<string | null>(null);

  const [guaranteeHalyk, setGuarantyHalyk] = useState<string>('');

  const [isSendAvailable, setIsSendAvailable] = useState<boolean>(false);

  useEffect(() => {
    const filteredArr = attachedFiles
      .filter((value, index, self) => {
        return self.findIndex((item) => item.type === value.type) === index;
      })
      .filter(
        (file) =>
          file.type === 'guaranteeHalyk' || file.type === 'spouseConsent'
      );
    setFiles(filteredArr);
  }, [attachedFiles]);

  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({
      ...prevState,
      [type]: { name: file?.name, base64: fileBase64 }
    }));
  };

  useEffect(() => {
    const isValid = Object.values(uploadFiles).length
      ? checkArrayValues(Object.values(uploadFiles))
      : false;
    setIsSendAvailable(isValid);
  }, [uploadFiles]);

  useEffect(() => {
    if (attachedFiles.length > 0) {
      const spouseAgreement = attachedFiles.find(
        (file) => file.type === 'spouseConsent'
      );
      spouseAgreement && setSpouseAgreement(spouseAgreement.uuid);

      const guaranteeHalykHandler = attachedFiles.find(
        (file) => file.type === 'guaranteeHalyk'
      );
      setGuarantyHalyk(guaranteeHalykHandler?.uuid || '');
    }
  }, [attachedFiles]);

  return (
    <>
      {/* <div className="Halyk_guaranty_download">
        {isFileDownloading ? (
          <Spin />
        ) : (
          <>
            <div>
              <img src={pathFileText} alt="fileTextIcon" />
              <p>Гарантийное письмо</p>
            </div>
            <button onClick={() => handleDownloadHalykGuarantee()}>
              <img src={pathRedDownload} alt="download" />
            </button>
          </>
        )}
      </div> */}
      <FileDownload
        // oldDownloadMethod={true}
        prevStateTitle={'Гарантийное письмо'}
        fileLink={guaranteeHalyk}
      />
      {spouseAgreement && (
        <FileDownload
          // oldDownloadMethod={true}
          prevStateTitle={'Согласие супруга(и) / Отсутствие супруга(и) '}
          fileLink={spouseAgreement}
        />
      )}
      {/*{!spouseAgreement && (*/}
      {/*  <FileUpload*/}
      {/*    fileFormat="application/pdf"*/}
      {/*    positionLeft={'50px'}*/}
      {/*    marginLeft={'35px'}*/}
      {/*    isLg={true}*/}
      {/*    onFileChange={handleFileChange}*/}
      {/*    title={'Согласие супруга(и) / Отсутствие супруга(и) '}*/}
      {/*    type="spouseConsent"*/}
      {/*    preventActions={false}*/}
      {/*  />*/}
      {/*)}*/}

      {!isOfferFinaced ? (
        <>
          {' '}
          <FileUpload
            fileFormat="application/pdf"
            positionLeft={'50px'}
            marginLeft={'35px'}
            isLg={true}
            onFileChange={handleFileChange}
            title={
              !spouseAgreement
                ? 'Согласие супруга(и) / Отсутствие супруга(и)'
                : 'Переотправить Согласие супруга(и) / Отсутствие супруга(и)'
            }
            type="spouseConsent"
            preventActions={false}
          />
          <button
            className="sendBtn"
            onClick={() => handleSendDocs(uploadFiles)}
            disabled={!isSendAvailable}
          >
            {!requestLoading ? (
              'Отправить документы'
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            )}
          </button>
        </>
      ) : null}
    </>
  );
};
